import Header from "components/Header";
import Main from "components/Main";
import { Footer } from "components/Footer";
import { RegisterService } from "components/RegisterService";
import DashboardUser from "components/DashboardUser";

export function FormService() {
  return (
    <>
      <Header />
      <Main>
        <DashboardUser />
        <RegisterService />
      </Main>
      <Footer />
    </>
  );
}
