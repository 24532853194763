import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button as ButtonPrime } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Card } from "primereact/card";
import { parseISO, isAfter } from "date-fns";
import * as Yup from "yup";
import axios from "axios";
import { Form } from "@unform/web";

import DashboardUser from "components/DashboardUser";
import { Footer } from "components/Footer";
import Header from "components/Header";
import Main from "components/Main";
import { Input } from "components/Input";
import Button from "components/Button";
import { InputCalendar } from "components/InputCalendar";
import SelectDropdown from "components/SelectDropdown";
import { SimpleModal } from "components/ModalGroup/SimpleModal";

import * as S from "./styles";
import { convert } from "utils/dateConverter";

import warningImg from "images/arrowWarning.png";
import ImageInput from "components/ImageInput/imageInput";

interface DropdownSelectedItem {
  value: number;
  label: string;
}

interface TipoParceriaData {
  id: number;
  nome: string;
}

interface KeyValuePair {
  index?: number;
  key: string;
  value: string;
}

interface ILogo {
  data_url?: never;
}

export function EditarParceria() {
  const history = useHistory();
  const { state }: { state: any } = useLocation();
  const [isDisableInicio, setIsDisableInicio] = useState(false);
  const [isDisableTermino, setIsDisableTermino] = useState(false);
  const [nome, setNome] = useState(state.data.nome || "");
  const [tipoAutenticacao, setTipoAutenticacao] = useState(state.data.autenticacao?.tipoAutenticacao || null);
  const [token, setToken] = useState(state.data.autenticacao?.token || null);
  const [headerRecebeToken, setHeaderRecebeToken] = useState(state.data.autenticacao?.headerRecebeToken || null);
  const [url, setUrl] = useState(state.data.autenticacao?.url || null);
  const [metodo, setMetodo] = useState(state.data.autenticacao?.metodo || null);
  const [usuario, setUsuario] = useState(state.data.autenticacao?.usuario || null);
  const [senha, setSenha] = useState(state.data.autenticacao?.senha || null);
  const [propriedadeRetornaToken, setPropriedadeRetornaToken] = useState(state.data.autenticacao?.propriedadeRecebeToken || null);
  const [body, setBody] = useState(state.data.autenticacao?.body || null);

  const [inicio, setInicio] = useState(
    parseISO(
      convert({ dateString: state.data.vigenciaInicio, pattern: "yyyy-mm-dd" })
    )
  );
  const [termino, setTermino] = useState(
    parseISO(
      convert({ dateString: state.data.vigenciaFim, pattern: "yyyy-mm-dd" })
    )
  );
  const [tipoBeneficio, setTipoBeneficio] = useState(
    state.data?.tipoParceria.id || 0
  );
  const [tipoCadastro, setTipoCadastro] = useState(state.data?.tipo || 0);
  const [urlConsulta, setUrlConsulta] = useState(
    state.data.urlBaseExterna || ""
  );
  const [tiposParceria, setTiposParceria] = useState<TipoParceriaData[]>([]);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [headers, setHeaders] = useState<KeyValuePair[]>(
    state.data.headers || []
  );
  const [utilizarMascara, setUtilizarMascara] = useState(
    state.data.buscaComMascara ?? false
  );
  const [landingPageCadastro, setLandingPageCadastro] = React.useState(
    state.data.landingPageCadastro ?? false
  );
  const [exibirPainel, setExibirPainel] = React.useState(
    state.data.exibirPainel ?? false
  );
  const [counterHeader, setCounterHeader] = React.useState(0);
  const [isModalShow, setIsModalShow] = React.useState(false);
  const [seletedIndex, setSeletedIndex] = React.useState(-1);
  const [isLoadingInner, setIsLoadingInner] = useState(false);
  const [logo, setLogo] = useState<ILogo>();
  const [logoError, setLogoError] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [parceria, setParceria] = useState(state.data || {} as any);
  function handleGoBack() {
    history.push("/portaladmin/parcerias/detalhes?id=" + state.data.id, {
      data: state.data,
    });
  }
  const preventEnterEvent = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      return false;
    }
  };
  const adjustHeader = () => {
    headers.map((item) => {
      item.index = headers.findIndex((x) => x == item);
      setCounterHeader((prevent) => ++prevent);
    });

    setHeaders(headers);
  };
  useEffect(() => {
    adjustHeader();
    // Inicialize o estado do logo com os dados do logo existente
    setLogo(state.data.logo || []);
    if(state.data.autenticacao?.tipoAutenticacao !== "BASICA")
      setTipoAutenticacao("NENHUMA");
  }, []);

  useEffect(() => {
    if (!logo?.[0]?.data_url && hasChanges) {
      setExibirPainel(false);
      setParceria(prevParceria => ({ ...prevParceria, urlLogo: undefined }));
    }
  }, [logo, hasChanges]);
  useEffect(() => {
    window.addEventListener("keydown", preventEnterEvent);

    (async () => {
      const responseTipos = await axios.get("/beneficioData/GetAllTipo");

      if (responseTipos.data.result === "Success") {
        setTiposParceria(responseTipos.data.items);
      }
    })();

    return () => {
      window.removeEventListener("keydown", preventEnterEvent);
    };

  }, []);

  useEffect(() => {
    (async () => {
      if (tipoCadastro === 1) {
        setUrlConsulta("");
        if (headers?.length >= 1) setHeaders([]);
      }

      await validateForm({
        nome,
        inicio,
        termino,
        tipoBeneficio,
        tipoCadastro,
        urlConsulta,
        headers,
        utilizarMascara,
        landingPageCadastro,
        exibirPainel,
        logo,
        token, 
        headerRecebeToken,
        url,
        metodo, 
        usuario,
        senha, 
        propriedadeRetornaToken, 
      });
    })();
  }, [
    nome,
    inicio,
    termino,
    tipoBeneficio,
    tipoCadastro,
    urlConsulta,
    headers,
    utilizarMascara,
    landingPageCadastro,
    exibirPainel,
    logo,
    token, 
    headerRecebeToken,
    url,
    metodo, 
    usuario,
    senha, 
    propriedadeRetornaToken, 
  ]);

  async function validateForm({
    nome,
    inicio,
    termino,
    tipoBeneficio,
    tipoCadastro,
    urlConsulta,
    headers,
    utilizarMascara,
    landingPageCadastro,
    exibirPainel,
    logo,
    token, 
    headerRecebeToken,
    url,
    metodo, 
    usuario,
    senha, 
    propriedadeRetornaToken, 
  }: any) {
    setEnableSaveButton(false);

    if (isAfter(inicio, termino)) return;

    const schema = Yup.object().shape({
      nome: Yup.string().required(),
      vigenciaInicio: Yup.string().required(),
      vigenciaFim: Yup.string().required(),
      tipoBeneficio: Yup.number().required(),
      tipoCadastro: Yup.number().required(),
      urlBaseExterna: Yup.string().when("tipoCadastro", {
        is: (tipoCadastro: number) => tipoCadastro === 2,
        then: Yup.string().required(),
      }),
      utilizarMascara: Yup.bool().required(),
      landingPageCadastro: Yup.bool().required(),
      exibirPainel: Yup.bool(),
      headers: Yup.array().of(
        Yup.object().shape({
          key: Yup.string().required(),
          value: Yup.string().required(),
        })
      ),
      logo: Yup.string().when("exibirPainel", {
        is: true,
        then: Yup.string().required(),
      }),
      token: Yup.string().when(["tipoCadastro", "tipoAutenticacao"], {
        is: (tipoCadastro: number,tipoAutenticacao: string ) => tipoCadastro === 2 && tipoAutenticacao === "TOKEN",
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired().nullable()
      }),
      url: Yup.string().when(["tipoCadastro", "tipoAutenticacao"], {
        is: (tipoCadastro: number,tipoAutenticacao: string ) => tipoCadastro === 2 && tipoAutenticacao === "BASICA",
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired().nullable()
      }),
      metodo: Yup.string().when(["tipoCadastro", "tipoAutenticacao"], {
        is: (tipoCadastro: number,tipoAutenticacao: string ) => tipoCadastro === 2 && tipoAutenticacao === "BASICA",
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired().nullable()
      }),
      propriedadeRetornaToken: Yup.string().when(["tipoCadastro", "tipoAutenticacao"], {
        is: (tipoCadastro: number,tipoAutenticacao: string ) => tipoCadastro === 2 && tipoAutenticacao === "BASICA",
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired().nullable()
      }),
      headerRecebeToken: Yup.string().when("tipoCadastro", {
        is: (tipoCadastro: number) => tipoCadastro === 2 && tipoAutenticacao !== "NENHUMA",
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired().nullable()
      }),
      usuario: Yup.string().when(["tipoCadastro", "tipoAutenticacao"], {
        is: (tipoCadastro: number,tipoAutenticacao: string ) => tipoCadastro === 2 && tipoAutenticacao === "BASICA",
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired().nullable()
      }),
      senha: Yup.string().when(["tipoCadastro", "tipoAutenticacao"], {
        is: (tipoCadastro: number,tipoAutenticacao: string ) => tipoCadastro === 2 && tipoAutenticacao === "BASICA",
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired().nullable()
      }),
    });

    await schema.validate(
      {
        nome,
        vigenciaInicio: inicio,
        vigenciaFim: termino,
        tipoBeneficio,
        tipoCadastro,
        urlBaseExterna: urlConsulta,
        headers,
        utilizarMascara,
        landingPageCadastro,
        exibirPainel,
        logo: parceria.urlLogo ? parceria.urlLogo : logo?.[0]?.data_url,
        token: token, 
        headerRecebeToken,
        url,
        metodo, 
        usuario,
        senha, 
        propriedadeRetornaToken,
      },
      {
        abortEarly: false,
      }
    );    

    setEnableSaveButton(true);
  }

  async function handleUpdatePartnership() {
    const hash = localStorage.getItem("@portalAdmin:hash");
    let auxHeaders;
    if (headers) {
      auxHeaders = [...headers];
      auxHeaders = auxHeaders.map((item) => {
        delete item.index;
        return item;
      });
    }
    function verificarCadastroEdicao(parceria, hasChanges) {
      var substituirImagemExistente = parceria.urlLogo && hasChanges;
      var cadastrarNovaImagem = !parceria.urlLogo && hasChanges;
      if (substituirImagemExistente || cadastrarNovaImagem) {
        return logo?.[0]?.data_url;
      } else {
        return parceria.urlLogo;
      }
    }

    const updateAutenticacao = {
      id: state.data.autenticacao?.id,
      idParceria: state.data.autenticacao?.idParceria,
      tipoAutenticacao: tipoAutenticacao,
      url: url,
      token: token, 
      usuario: usuario,
      senha: senha,
      body: body,
      headerRecebeToken: headerRecebeToken,
      propriedadeRetornaToken: propriedadeRetornaToken,
      metodo: metodo,
    };

    const updatedPartnership = {
      nome,
      vigenciaInicio: inicio,
      vigenciaFim: termino,
      tipo: tipoCadastro,
      idTipoParceria: Number(tipoBeneficio),
      urlBaseExterna: urlConsulta,
      beneficios: state.data.servicosVinculados,
      ativo: state.data.status === "active" ? true : false,
      headers: auxHeaders,
      buscaComMascara: utilizarMascara,
      landingPageCadastro,
      exibirPainel: exibirPainel,
      logo: verificarCadastroEdicao(parceria, hasChanges),
      logoExtensao: "png",
      autenticacao: updateAutenticacao,
    };

    await axios
      .put(
        `/beneficioData/UpdateParceria/${state.data.id}`,
        updatedPartnership,
        {
          headers: {
            pm_hash: hash,
          },
        }
      )
      .then((_) => {
        history.push("/portaladmin/parcerias/detalhes?id=" + state.data.id, {
          data: {
            id: state.data.id,
          },
        });
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoadingInner(false);
        setIsModalShow(false);
      });
  }

  const removeHeader = (index: number) => {
    setHeaders((prevIndexes) => [
      ...prevIndexes.filter((item) => item.index !== index),
    ]);
    setCounterHeader((prevCounterHeader) => prevCounterHeader - 1);
  };

  const changeText = (index: number, chave: boolean, text: string) => {
    let newArr = [...headers];

    if (chave) newArr[index].key = text;
    else newArr[index].value = text;
    setHeaders(newArr);
  };

  async function limparCamposAutenticacao() {
    setUrl(null);
    setMetodo(null);
    setUsuario(null);
    setSenha(null);
    setPropriedadeRetornaToken(null);
    setBody(null);
    setHeaderRecebeToken(null);
  }



  return (
    <>
      <Header />
      <Main>
        <S.DashboardContainer>
          <DashboardUser />
        </S.DashboardContainer>
        <S.Content>
          <h2>EDIÇÃO DE PARCERIA</h2>
          <Card className="contentBody">
            <div className="form">
              <Form onSubmit={() => setIsModalShow(true)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>                
                <Card subTitle="Dados básicos" className="contentBody p-col-12" style={{border: "1px solid #dedede", borderRadius: "05px"}}>
                <div className="p-fluid p-formgrid p-grid">                                    
                  <div id="criadoEm" className="p-field p-col-2">
                      <label htmlFor="criadoEm">Criado em:</label>
                      <Input
                        name="criadoEm"
                        value={state.data.criadoPor?.data}
                        readOnly
                        disabled
                      />
                    </div>
                  <div id="criadoPor" className="p-field p-col-7">
                    <label htmlFor="criadoPor">Criado por:</label>
                    <Input
                      name="criadoPor"
                      value={state.data.criadoPor?.colaborador}
                      readOnly
                      disabled
                    />
                  </div>
                  <div id="matricula" className="p-field p-col-3">
                    <label htmlFor="matricula">Matrícula:</label>
                    <Input
                      name="matricula"
                      value={state.data.criadoPor?.codigoColaborador}
                      readOnly
                      disabled
                    />
                  </div>
                  <div id="nome" className="p-field p-col-6 required">
                    <label htmlFor="nome">Nome</label>
                    <Input
                      name="nome"
                      value={nome}
                      onChange={(event) => setNome(event.target.value)}
                      placeholder="Nome completo da parceria"
                    />
                  </div>
                  <div id="tipoBeneficio" className="p-field p-col-2 required">
                    <label htmlFor="matipoBeneficiotricula">Tipo</label>
                    <SelectDropdown
                      name="tipoBeneficio"
                      placeholder="Selecione"
                      newValue={{
                        value: tipoBeneficio,
                        label:
                          tiposParceria.find(
                            (tipo) => tipo.id === tipoBeneficio
                          )?.nome || "Selecione",
                      }}
                      options={tiposParceria?.map((tipo) => ({
                        value: tipo.id,
                        label: tipo.nome,
                      }))}
                      onChange={(event: any) => setTipoBeneficio(event.value)}
                    />
                  </div>
                  <div id="tipoCadastro" className="p-field p-col-4 required">
                    <label htmlFor="tipoCadastro">Tipo de cadastro</label>
                    <SelectDropdown
                      name="tipoCadastro"
                      placeholder="Selecione"
                      newValue={{
                        value: tipoCadastro,
                        label:
                          [
                            {
                              value: 1,
                              label: "Manual",
                            },
                            {
                              value: 2,
                              label: "Automático",
                            },
                          ].find((tipo) => tipo.value === tipoCadastro)
                            ?.label || "Selecione",
                      }}
                      options={[
                        {
                          value: 1,
                          label: "Manual",
                        },
                        {
                          value: 2,
                          label: "Automático",
                        },
                      ]}
                      onChange={(event: any) => setTipoCadastro(event.value)}
                    />
                  </div>
                  <div id="inicio" className="p-field p-col-3 required">
                    <label htmlFor="inicio">Início</label>
                    <InputCalendar
                      name="inicioInput"
                      isRange={false}
                      defaultDateSelected={new Date(inicio)}
                      SetDateValidation={(valid) => setIsDisableInicio(valid)}
                      handleDateChange={(event) => setInicio(event as Date)}
                    />
                  </div>
                  <div id="termino" className="p-field p-col-3 required">
                    <label htmlFor="termino">Término</label>
                    <InputCalendar
                      name="termino"
                      isRange={false}
                      defaultDateSelected={new Date(termino)}
                      SetDateValidation={(valid) => setIsDisableTermino(valid)}
                      handleDateChange={(event) => setTermino(event as Date)}
                    />
                  </div>
                  <div
                    id="utilizarMascara"
                    className="p-field p-col-3 required"
                  >
                    <label htmlFor="utilizarMascara">Utilizar Máscara?</label>
                    <div className="p-formgroup-inline">
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="utilizarMascaraSim"
                          name="utilizarMascara"
                          value={utilizarMascara}
                          onChange={(e) => setUtilizarMascara(!utilizarMascara)}
                          checked={utilizarMascara}
                          disabled={tipoCadastro !== 2}
                        />
                        <label htmlFor="utilizarMascaraSim">Sim</label>
                      </div>
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="utilizarMascaraNao"
                          name="utilizarMascara"
                          value={utilizarMascara}
                          onChange={(e) => setUtilizarMascara(!utilizarMascara)}
                          checked={!utilizarMascara}
                          disabled={tipoCadastro !== 2}
                        />
                        <label htmlFor="utilizarMascaraNao">Nao</label>
                      </div>
                    </div>
                  </div>
                  <div
                    id="landingPageCadastro"
                    className="p-field p-col-3 required"
                  >
                    <label htmlFor="landingPageCadastro">
                      Utilizar landing page de cadastro de clientes?
                    </label>
                    <div className="p-formgroup-inline">
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="landingPageCadastroSim"
                          name="landingPageCadastro"
                          value={landingPageCadastro}
                          onChange={(e) =>
                            setLandingPageCadastro(!landingPageCadastro)
                          }
                          checked={landingPageCadastro}
                        />
                        <label htmlFor="landingPageCadastroSim">Sim</label>
                      </div>
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="landingPageCadastroNao"
                          name="landingPageCadastro"
                          value={landingPageCadastro}
                          onChange={(e) =>
                            setLandingPageCadastro(!landingPageCadastro)
                          }
                          checked={!landingPageCadastro}
                        />
                        <label htmlFor="landingPageCadastroNao">Não</label>
                      </div>
                    </div>
                  </div>

                  <div id="urlConsulta" className="p-field p-col-8 required">
                    <label htmlFor="urlConsulta">
                      Insira a url de consulta
                    </label>
                    <Input
                      name="urlConsulta"
                      value={urlConsulta}
                      onChange={(event) => setUrlConsulta(event.target.value)}
                      placeholder="Insira a url de consulta"
                      disabled={tipoCadastro !== 2}
                    />
                  </div>
                  <div id="imageInputs" className="p-field p-col-6">
                    <ImageInput
                      labelText={"Insira a imagem do logo"}
                      multiple={false}
                      maxWidth={220}
                      maxHeight={80}
                      image={logo}
                      setImage={setLogo}
                      errorMessage={logoError}
                      existentImage={parceria?.urlLogo}
                      setHasChanges={setHasChanges}
                      readOnly={false}
                      isEditing={true}
                    />
                  </div>
                  <div
                    id="exibirPainel"
                    className="p-field p-col-3 required"
                  >
                    <label htmlFor="exibirPainel">
                      Exibir logo no painel?
                    </label>
                    <div className="p-formgroup-inline">
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="exibirPainelSim"
                          name="exibirPainel"
                          value={exibirPainel}
                          onChange={(e) =>
                            setExibirPainel(!exibirPainel)
                          }
                          checked={exibirPainel}
                        />
                        <label htmlFor="exibirPainelSim">Sim</label>
                      </div>
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="exibirPainelNao"
                          name="exibirPainel"
                          value={exibirPainel}
                          onChange={(e) =>
                            setExibirPainel(!exibirPainel)
                          }
                          checked={!exibirPainel}
                        />
                        <label htmlFor="exibirPainelNao">Não</label>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames({
                      "p-field p-col-12 p-m-0": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                    style={{ opacity: 0 }}
                  ></div>
                  {headers?.map((header: KeyValuePair) => {
                    if (header)
                      return (
                        <React.Fragment
                          key={header.index || header.key + "_" + header.value}
                        >
                          <div
                            id="headers"
                            className={classNames({
                              "p-field p-col-4 required": true,
                              "p-d-none": tipoCadastro !== 2,
                            })}
                          >
                            <label htmlFor={`chave${header.key}`}>Chave</label>
                            <InputText
                              name={`chave${header.key}`}
                              value={header.key}
                              onChange={(e) =>
                                changeText(
                                  header.index ?? -1,
                                  true,
                                  e.target.value
                                )
                              }
                              placeholder="Insira a chave da requisição"
                              disabled={tipoCadastro !== 2}
                            />
                          </div>
                          <div
                            className={classNames({
                              "p-field p-col required": true,
                              "p-d-none": tipoCadastro !== 2,
                            })}
                          >
                            <label htmlFor={`valor${header.value}`}>
                              Valor
                            </label>
                            <div className="p-formgroup-inline">
                              <div className="p-field flex-1 p-mb-0">
                                <InputText
                                  name={`valor${header.value}`}
                                  value={header.value}
                                  onChange={(e) =>
                                    changeText(
                                      header.index ?? -1,
                                      false,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Insira o valor da chave da requisição"
                                  disabled={tipoCadastro !== 2}
                                />
                              </div>
                              <ButtonPrime
                                type="button"
                                icon="pi pi-trash"
                                className="custom-btn-remove p-button-danger"
                                onClick={() => {
                                  setSeletedIndex(header.index ?? -1);
                                  setIsModalShow(true);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className={classNames({
                              "p-field p-col-12 p-m-0": true,
                              "p-d-none": tipoCadastro !== 2,
                            })}
                            style={{ opacity: 0 }}
                          ></div>
                        </React.Fragment>
                      );
                  })}
                  <div
                    className={classNames({
                      "p-field p-col-12 p-m-0": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                    style={{ opacity: 0 }}
                  ></div>
                  <div
                    className={classNames({
                      "p-field p-col-3": true,
                      "p-d-none": tipoCadastro !== 2,
                    })}
                  >
                    <ButtonPrime
                      type="button"
                      label="Adicionar Header"
                      icon="pi pi-check"
                      disabled={headers.length >= 3}
                      className="backgroud-color-default"
                      onClick={(_) => {
                        _.preventDefault();
                        setHeaders((values) => [
                          ...values,
                          { index: counterHeader, key: "", value: "" },
                        ]);
                        setCounterHeader(
                          (prevCounterHeader) => prevCounterHeader + 1
                        );
                      }}
                    />
                  </div>
                  {state.data.ultimaAlteracao &&
                    state.data.ultimaAlteracao.data && (
                      <>
                        <div
                          className={classNames({
                            "p-field p-col-12 p-m-0": true,
                            "p-d-none": tipoCadastro !== 2,
                          })}
                          style={{ opacity: 0 }}
                        ></div>
                        <div id="editadoEm" className="p-field p-col-2">
                          <label htmlFor="editadoEm">Editado em:</label>
                          <Input
                            name="editadoEm"
                            value={state.data.ultimaAlteracao.data}
                            readOnly
                            disabled
                          />
                        </div>
                        <div id="editadoPor" className="p-field p-col-7">
                          <label htmlFor="editadoPor">Editado por:</label>
                          <Input
                            name="editadoPor"
                            value={state.data.ultimaAlteracao.colaborador}
                            readOnly
                            disabled
                          />
                        </div>
                        <div id="matriculaEditor" className="p-field p-col-3">
                          <label htmlFor="matriculaEditor">Matrícula:</label>
                          <Input
                            name="matriculaEditor"
                            value={state.data.ultimaAlteracao.codigoColaborador}
                            readOnly
                            disabled
                          />
                        </div>
                      </>
                    )}
                </div>
                </Card>   
                <Card subTitle="Dados da autenticação" className="contentBody p-col-12" style={{border: "1px solid #dedede", borderRadius: "05px"}}>
                  <div id="tipoAutenticacao" className="p-field p-col-4 required">
                    <label htmlFor="tipoAutenticacao">Tipo de autenticacao</label>
                    <div className="p-formgroup">              
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="tipoAutenticacaoNenhuma"
                          name="tipoAutenticacao"
                          value={tipoAutenticacao}                 
                          checked={tipoAutenticacao === 'NENHUMA' ? true : false}
                          onChange={(e) => {
                            setTipoAutenticacao("NENHUMA");
                            limparCamposAutenticacao();
                          }}
                        />
                        <label htmlFor="tipoAutenticacaoNenhuma">Nenhuma</label>
                      </div>
                      {/* <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="tipoAutenticacaoChaveApi"
                          name="tipoAutenticacao"
                          value={state.data.autenticacao?.tipoAutenticacao === 'CHAVEAPI' ? true : false}                  
                          checked={state.data.autenticacao?.tipoAutenticacao === 'CHAVEAPI' ? true : false}
                        />
                        <label htmlFor="tipoAutenticacaoChaveApi">Chave da API</label>
                      </div> */}
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="tipoAutenticacaoBasic"
                          name="tipoAutenticacao"
                          value={tipoAutenticacao === 'BASICA' ? true : false}
                          checked={tipoAutenticacao === 'BASICA' ? true : false}
                          onChange={(e) => setTipoAutenticacao("BASICA")}
                        />
                        <label htmlFor="tipoAutenticacaoBasic">Autenticação básica</label>
                      </div>
                      {/* <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="tipoAutenticacaoToken"
                          name="tipoAutenticacao"
                          value={state.data.autenticacao?.tipoAutenticacao === 'TOKEN' ? true : false}
                          checked={state.data.autenticacao?.tipoAutenticacao === 'TOKEN' ? true : false}
                        />
                        <label htmlFor="tipoAutenticacaoToken">Token do portador</label>
                      </div> */}
                    </div>
                  </div>
                  {/* {state.data.autenticacao?.tipoAutenticacao === "TOKEN" && (
                    <div className="p-fluid p-formgrid p-grid">
                      <div id="token" className="p-field p-col-6 required">          
                        <label htmlFor="token">Token</label>
                        <Input
                          name="token"
                          value={token}
                          onChange={(event) => setToken(event.target.value)}
                          maxLength={40}
                          placeholder="Insira o token fornecido pelo parceiro"
                        />
                      </div>
                      <div id="headerRecebeTokenToken" className="p-field p-col-6 required">          
                        <label htmlFor="headerRecebeTokenToken">Header que receberá o token</label>
                        <Input
                          name="headerRecebeToken"
                          value={headerRecebeToken}
                          onChange={(event) => setHeaderRecebeToken(event.target.value)}
                          maxLength={40}
                          placeholder="Informe o Header que receberá o token"
                        />
                      </div>
                    </div>
                  )} */}
                  {tipoAutenticacao === "BASICA" && (
                    <div className="p-fluid p-formgrid p-grid">
                    <div id="urlBasica" className="p-field p-col-12 required">          
                      <label htmlFor="urlBasica">URL de autenticação</label>
                      <Input
                        name="urlBasica"
                        value={url}
                        onChange={(event) => setUrl(event.target.value)}
                        maxLength={80}
                        placeholder="Insira a url de autenticação"
                      />
                    </div>
                    <div id="metodo" className="p-field p-col-4 required">          
                      <label htmlFor="metodo">Método de requisição</label>                      
                      <SelectDropdown
                        name="metodo"
                        placeholder="Selecione"
                        newValue={{
                          value: metodo,
                          label:
                            [
                              {
                                value: 1,
                                label: "GET",
                              },
                              {
                                value: 2,
                                label: "POST",
                              },
                            ].find((metodoSelecionado) => metodoSelecionado.label === metodo)?.label ||
                            "Selecione",
                        }}
                        options={[
                          {
                            value: 1,
                            label: "GET",
                          },
                          {
                            value: 2,
                            label: "POST",
                          },
                        ]}
                        onChange={(event: any) => {
                          setMetodo(event.label);
                        }}
                      />
                    </div>
                    <div id="usuarioBasica" className="p-field p-col-4 required">          
                      <label htmlFor="usuarioBasica">Usuário</label>
                      <Input
                        name="usuarioBasica"
                        value={usuario}
                        onChange={(event) => setUsuario(event.target.value)}
                        maxLength={40}
                        placeholder="Insira a url de auteticação"
                      />
                    </div>
                    <div id="senhaBasica" className="p-field p-col-4 required">          
                      <label htmlFor="senhaBasica">Senha</label>
                      <Input
                        name="urlBasica"
                        value={senha}
                        onChange={(event) => setSenha(event.target.value)}
                        maxLength={40}
                        placeholder="Insira a senha"
                      />
                    </div>
                    <div id="propRetornaToken" className="p-field p-col-4 required">          
                      <label htmlFor="propRetornaToken">Propriedade do retorno que tratá o token</label>
                      <Input
                        name="propriedadeRetornaToken"
                        value={propriedadeRetornaToken}
                        onChange={(event) => setPropriedadeRetornaToken(event.target.value)}
                        maxLength={40}
                        placeholder="Informe o nome da propriedade"
                      />
                    </div>
                    <div id="headerRecebeToken" className="p-field p-col-4 required">          
                      <label htmlFor="headerRecebeToken">Header que receberá o token</label>
                      <Input
                        name="headerRecebeToken"
                        value={headerRecebeToken}
                        onChange={(event) => setHeaderRecebeToken(event.target.value)}
                        maxLength={40}
                        placeholder="Informe o Header que receberá o token"
                      />
                    </div>
                    <div id="bodyBasica" className="p-field p-col-12">          
                      <label htmlFor="bodyBasica">Body</label>
                      <S.TextArea
                        name="bodyBasica"
                        value={body}
                        onChange={(event) => setBody(event.target.value)}
                        maxLength={400}
                        placeholder="Insira o body"
                        rows={5}
                      />
                    </div>
                  </div>
                  )}
                </Card>
                <div className="contentFooter p-d-flex p-jc-end p-mt-5">
                  <Button inverted onClick={handleGoBack}>
                    Cancelar
                  </Button>
                  <Button
                    disabled={
                      isDisableInicio || isDisableTermino || !enableSaveButton
                    }
                    type="submit"
                  >
                    Salvar
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </S.Content>
      </Main>
      <Footer />

      <SimpleModal
        isOpen={isModalShow}
        onRequestClose={() => {
          if (seletedIndex > -1) setIsModalShow(false);
        }}
        confirm={() => {
          setIsLoadingInner(true);
          if (seletedIndex > -1) {
            removeHeader(seletedIndex);
            setIsModalShow(false);
            setSeletedIndex(-1);
            setIsLoadingInner(false);
          } else handleUpdatePartnership();
        }}
        onCancel={() => handleGoBack()}
        loading={isLoadingInner}
        icon={warningImg}
        title="Atenção"
        message={
          seletedIndex > -1
            ? "Deseja remover esta configuração?"
            : "Deseja salvar essas informações?"
        }
        showCancel
      />
    </>
  );
}
