import styled from "styled-components";

export const Container = styled.div`
  border: 1px dashed #AEC0D2;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .image-uploaded {
    text-align: center;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #404F61;
    margin-bottom: 10px;
    
    img {
      width: 301px;
      height: 177px;
      object-fit: cover;
      display: block;
      margin-bottom: 10px;
    }
  }

  span {
    font-family: 'Source Sans Pro';
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #7B8FA5;
  }

  .file-input-container {
    width: 153px;
    height: 48px;
    background: #0054A6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 9.69px;
    
    &:hover {
      cursor: pointer;
      filter: brightness(1.2);
    }

    label {
      color: #fff;
      margin-bottom: 0;
      cursor: pointer;

      &:after {
        content: '';
      }
    }
  }
`;