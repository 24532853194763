import styled from "styled-components";
import "react-calendar/dist/Calendar.css";
import {colors} from "tokens";

export const Container = styled.div`

  .unblockCalendarBtn {
    align-self: center;
    background: #0054a6;
    color: #fff;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .react-calendar {
    width: 325px;
    border: 1px solid ${colors.gray.blueLight};
    border-radius: 4px;

    .react-calendar__navigation {
      height: 60px;
      border-bottom: 1px solid ${colors.gray.blueLight};
      margin-bottom: 30.8px;

      .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        color: ${colors.gray.text};
      }
    }

    .react-calendar__month-view {
      padding: 0 16px 16px;
    }

    .react-calendar__month-view__weekdays__weekday {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 10px;
      color: ${colors.gray.blueLight};

      abbr {
        text-decoration: none;
        cursor: auto;
      }
    }

    .react-calendar__tile.react-calendar__month-view__days__day {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      color: #002a53;

      &:disabled {
        background: transparent;
        color: ${colors.gray.blue};
      }
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      color: ${colors.gray.blueLight};
    }

    .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
      background: ${colors.white};
      color: #002a53;
    }

    .react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day:enabled:hover {
      background: #E6E6E6;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__month-view__days__day {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      color: ${colors.white};
      background: ${colors.blue};
      border-radius: 4px;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day {
      border-radius: 4px 0 0 4px;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day,
    .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day {
      border-radius: 0 4px 4px 0;
    }

    .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day {
      background: ${colors.gray.light};
      border-radius: 0;
      color: #002a53;
    }
  }
`;
