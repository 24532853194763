import axios from "axios";

const axiosConfig = {
  validateStatus: function (status) {
    return status >= 200 && status < 503;
  },
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
};

export default axiosConfig;

export const Axios = axios.create();

Axios.interceptors.request.use(async (request) => {
  request.headers.common.Authorization = `PagueMenos`;

  return request;
});
