import styled from "styled-components";

import { colors } from "tokens";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 50px;
`;

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    background: ${colors.blue};
    height: 48px;
    color: ${colors.white};
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 4px;
    margin-top: 25px;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  margin-left: 20px;

  .gridHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 27px;
    padding-right: 10px;

    h2 {
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      font-size: 22px;
      color: ${colors.dark};
    }

    p {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 14px;
      margin: 0;
      margin-top: 8px;
      color: ${colors.gray.text};
    }

    div:last-child {
      margin-left: 19px;
      height: 40px;
      width: 304px;
      border: 1px solid ${colors.gray.blueLight};
      border-radius: 4px;

      display: flex;
      align-items: center;

      padding: 7px 8.11px;

      input {
        flex: 1;

        &::placeholder {
          color: ${colors.gray.blue};
        }
      }

      svg {
        margin: 8px 2.89px;
      }
    }
  }
`;
