import './userCard.scss'
import {useAuth} from "../../hooks/auth";

export default function UserCard() {
    const {username, cargo} = useAuth()

    return (
        <div className="userCard">
            <div className="userCard__userImg"/>
            <div className="userCard__userInfo">
                <span title={username} className="userCard__userInfo__userName">
                    {username}
                </span>
                <span className="userCard__userInfo__userEmail">

                </span>
                <span className="userCard__userInfo__description">
                    <span>{cargo}</span>
                </span>
            </div>
        </div>
    )
}
