import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { DropDownData } from "../types";
import Button from "components/Button";

interface IManageSelectStores {
  selectedItem: string[] | number[];
  itemListAux: DropDownData[];
  lazyLoading: boolean;
  idParceiro: number;
  placeholder: string;
  isRemoveStore?: boolean;
  handleClickCancel: (cancelAddNewItem: string[] | number[]) => void;
  handleClickLink: (updatedSelectedItem: string[] | number[]) => void;
  cannotUnlinkItemListId?: string[];
}

export const ManageSelectStores = ({
  selectedItem,
  itemListAux,
  handleClickCancel,
  handleClickLink,
  lazyLoading,
  idParceiro,
  placeholder,
  isRemoveStore = false,
  cannotUnlinkItemListId = [],
}: IManageSelectStores) => {
  const [initialSelectedItem] = useState<string[]>([]);
  const [selectedItemAux, setSelectedItemAux] = useState<string[]>([]);
  const [selectedItemAuxAux, setSelectedItemAuxAux] = useState<string[]>([]);
  const [selectedItemList, setSelectedItemList] = useState<string[]>([]);

  useEffect(() => {
    const tmp = selectedItem.map((o: any) => String(o));
    if (isRemoveStore) setSelectedItemAux(tmp);

    setSelectedItemAuxAux(tmp);
    setSelectedItemList(tmp);
  }, [selectedItem]);

  const getUnselectedList = () => {
    return itemListAux.filter(
      (item) => selectedItemList.indexOf(item.code) == -1
    );
  };

  const getSelectedList = () => {
    return itemListAux.filter(
      (item) =>
        selectedItemList.indexOf(item.code) != -1 &&
        cannotUnlinkItemListId.indexOf(item.code) == -1
    );
  };

  return (
    <div className="p-col p-d-flex p-flex-column p-jc-center p-ai-center">
      <MultiSelect
        emptyFilterMessage="Resultado não encontrado"
        optionLabel="name"
        optionValue="code"
        value={selectedItemAux}
        options={
          (isRemoveStore ? getSelectedList() : getUnselectedList()) || []
        }
        selectAll={true}
        onChange={(e) => setSelectedItemAux(e.value)}
        placeholder={placeholder}
        maxSelectedLabels={1}
        selectedItemsLabel="{0} item(ns) selecionado(s)"
        filter
        showClear={!idParceiro}
        className="multiselect-custom"
        disabled={lazyLoading}
      />
      <div className="p-d-flex p-flex-row p-mt-4">
        <Button
          className="p-mr-4"
          onClick={() => handleClickCancel(initialSelectedItem)}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            let result = isRemoveStore
              ? selectedItemAuxAux.filter((x) => selectedItemAux.includes(x))
              : [...selectedItemAuxAux, ...selectedItemAux];
            handleClickLink(result);
          }}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};
