import styled from 'styled-components';

import colors from 'tokens/colors';

export const Container = styled.div`
  min-height: 284px;

  h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: ${colors.dark}
  }

  span {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: ${colors.dark}
  }

  .classificacao {
    margin-top: 16px;
    width: 461px;
  }

  .dates {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    .dateStart {
      margin-right: 16px;
      width: 223px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 32px;
    }
`;

export const ButtonCancel = styled.button`
  width: 111px;
  height: 48px;
  margin-right: 16px;

  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #0054a6;
  text-align: center;

  background: #ffffff;
  border: 1px solid #0054a6;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const ButtonContinue = styled.button`
  width: 111px;
  height: 48px;

  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  text-align: center;

  background: #0054a6;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;

  :disabled {
    color: #404f61;
    background: #f2f2f2;
    cursor: not-allowed;
  }
`;
