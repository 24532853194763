import { useEffect, useState } from "react";
import { ListBox } from "primereact/listbox";
import { Stats } from "../Stats";
import { storeService } from "services/storeService";
import Button from "components/Button";
import { ManageSelectStores } from "../manageSelectStores";
import { DropDownData } from "../types";

const Three = (props: any) => {
  const [selectedStore, setSelectedStore] = useState<string[]>([]);
  const [selectedStoreAux, setSelectedStoreAux] = useState<string[]>([]);
  const [storeWithSchedulling, setStoreWithSchedulling] = useState<string[]>(
    []
  );
  const [lazyLoading, setLazyLoading] = useState(false);
  const [isStoreListShow, setIsStoreListShow] = useState(false);
  const [storeListAux, setStoreListAux] = useState<DropDownData[]>([]);
  const [selectStoreList, setSelectStoreList] = useState<DropDownData[]>([]);
  const [partnerListStores, setPartnerListStores] = useState<string[]>([]);
  const [isRemoveStore, setIsRemoveStore] = useState(false);

  useEffect(() => {
    setSelectedStore([]);
    setSelectedStoreAux([]);
  }, [props.state?.form?.email]);

  useEffect(() => {
    setLazyLoading(props.lazyLoading);
  }, [props.lazyLoading]);

  useEffect(() => {
    if (props.isActive) {
      handleStore(!!props.state?.infoPartner.idParceiro);
    }
  }, [props.isActive, props.state?.infoPartner]);

  const handleStore = (isEdit: boolean) => {
    props.setLazyLoading(true);
    storeService
      .getAllStore()
      .then((data) => {
        var itemsAux = data?.items?.map(
          (item: any) =>
            ({
              name: item.idLoja + " - " + item.nomeLoja + " / " + item.nomeSala,
              code: item.idLoja + "|" + item.idLojaSala,
            } as DropDownData)
        );

        setStoreListAux(itemsAux);
        if (isEdit) {
          getStoreWithActiveSchedulling();
          handlerStorePartner(props.state?.infoPartner);
        }
      })
      .catch((error) => {
        setStoreListAux([]);
      })
      .finally(() => props.setLazyLoading(false));
  };

  const getStoreWithActiveSchedulling = () => {
    props.setLazyLoading(true);
    storeService
      .getStoreWithActiveSchedulling(props.state?.infoPartner.idParceiro)
      .then((data) => {
        var itemsAux = data?.items?.map(
          (item: any) => item.idLoja + "|" + item.idLojaSala
        );
        setStoreWithSchedulling(itemsAux);
      })
      .finally(() => props.setLazyLoading(false));
  };

  const handlerStorePartner = (info: any) => {
    let result = info.lojas?.map(
      (item: any) => item.codigo + "|" + item.idLojaSala
    );
    setSelectedStore(result);
    setSelectedStoreAux(result);
    setPartnerListStores(result);
  };

  const searchElement = (item: any) => {
    if (item && item.length > 0) {
      return item.map((elem: string) => {
        return storeListAux.find((item) => item.code === elem) as any;
      });
    }
  };

  const getSelectedList = () => {
    return storeListAux.filter(
      (item) => selectedStore.indexOf(item.code) != -1
    );
  };

  const getUnselectedList = () => {
    const unlinkedList = partnerListStores.filter(
      (item) => selectedStore.indexOf(item) == -1
    );
    return storeListAux.filter((item) => unlinkedList.indexOf(item.code) != -1);
  };

  const handleClickLinkStore = (updatedSelectedStore) => {
    setSelectedStore(updatedSelectedStore);
    setIsStoreListShow(false);
    setIsRemoveStore(false);
  };

  const handleClickCancelLink = (cancelAddNewStore) => {
    setIsStoreListShow(false);
    setSelectedStoreAux(cancelAddNewStore);
    setIsRemoveStore(false);
  };

  useEffect(() => {
    if (selectedStore && selectedStore.length > 0) {
      let storesSelected = searchElement(selectedStore);
      if (storesSelected && storesSelected.length > 0)
        setSelectStoreList(storesSelected);
    }
  }, [selectedStore]);

  const hasChanges = () => {
    const hasUnlink =
      partnerListStores.filter((item) => selectedStore.indexOf(item) == -1)
        ?.length > 0;

    const hasLink =
      selectedStore.filter((code) => partnerListStores.indexOf(code) == -1)
        ?.length > 0;

    return hasUnlink || hasLink;
  };

  return (
    <div>
      <h3 className="text-center">
        Para cadastrar o parceiro, preencha os dados abaixo.
      </h3>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-12 p-md-6">
          <label className="p-text-bold" htmlFor="lojas-atendidas">
            Lojas atendidas
          </label>
          <ListBox
            listStyle={{ maxHeight: "250px", maxWidth: "auto" }}
            options={getSelectedList() || []}
            optionValue="code"
            optionLabel="name"
          />
        </div>
        {!isStoreListShow ? (
          <div className="p-col p-d-flex p-flex-column p-jc-center p-ai-center">
            <Button className="p-mb-4" onClick={() => setIsStoreListShow(true)}>
              Adicionar Loja
            </Button>
            <Button
              onClick={() => {
                setIsStoreListShow(true);
                setIsRemoveStore(true);
              }}
            >
              Desvincular Loja
            </Button>
          </div>
        ) : (
          <ManageSelectStores
            selectedItem={selectedStore}
            itemListAux={storeListAux}
            cannotUnlinkItemListId={storeWithSchedulling}
            lazyLoading={lazyLoading}
            idParceiro={props.state?.infoPartner.idParceiro}
            handleClickCancel={handleClickCancelLink}
            handleClickLink={handleClickLinkStore}
            placeholder={`Selecione a(s) loja(s) que deseja ${
              isRemoveStore ? "desvincular" : "adicionar"
            }:`}
            isRemoveStore={isRemoveStore}
          />
        )}
      </div>
      <Stats
        step={props.currentStep}
        {...props}
        selectedStore={selectStoreList}
        setHeaderDialog={props.setHeaderDialog}
        hasChanges={hasChanges()}
        selectedStoreUnlink={getUnselectedList() || []}
      />
    </div>
  );
};

export default Three;
