import styled from 'styled-components';
import colors from '../../tokens/colors';

interface ButtonComponentProps {
  inverted?: boolean;
  width?: string;
  height?: string;
}

export const ButtonComponent = styled.button<ButtonComponentProps>`
  background: ${(props) => (props.inverted === true
    ? colors.white
    : colors.blue)
  };
  color: ${(props) => (props.inverted === true
    ? colors.blue
    : colors.white)
  };
  ${props => props.inverted && `border: 1px solid ${colors.blue}`};
  border-radius: 4px;
  /* padding: 12px 32px; */
  min-width: ${props => props.width};
  max-width: ${props => props.width};
  height: ${props => props.height};
`;
