import styled from "styled-components";
import { colors } from "tokens";

interface ContainerProps {
  inputPadding: string
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro", sans-serif;
  
  label {
    color: ${colors.dark};
  }

  & + label {
    margin-top: 19px;
  }

  input {
    margin: 0;
    padding: ${(props) => props.inputPadding};
    border: 1px solid ${colors.gray.blueLight};
    border-radius: 4px;

    &::placeholder {
      color: ${colors.gray.blue};
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }
  }

  span {
    display: block;
    position: relative;
    color: rgb(209, 74, 74);
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-top: 8px;
    padding: 8px 16px;
    background: rgb(255, 228, 228);
    border-radius: 4px;

    img {
      margin-right: 4px;
    }
  }
`;
