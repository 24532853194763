import './scheduleLockReleaseSelects.scss'
import StateSelect, {clearStateSelectBox} from './StateSelect/stateSelect'
import StoreSelect, {clearStoreSelectBox} from './StoreSelect/storeSelect'
import {format} from 'date-fns-tz'
import {useEffect, useState} from 'react'
import {usePartnerContext} from 'contexts/partnerContextProvider'
import DateRangeModal from '../PartnerDateRangePickerModal/dateRangeModal'
import {useRouter} from "../../../hooks/useRouter";

export default function ScheduleLockReleaseSelects({methods}) {
    const [wipeOutData, handleData] = methods
    const {
        isLoading,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedState,
        setSelectedState,
        selectedStores,
        setSelectedStores
    } = usePartnerContext();
    const router = useRouter()
    const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);
    const [filterState, setFilterState] = useState("");
    const [filterStores, setFilterStores] = useState([""]);

    useEffect(() => {
        setSelectedState(filterState)
    }, [filterState, selectedState]);

    useEffect(() => {
        setSelectedStores(filterStores)
    }, [filterStores, selectedStores]);

    const handleClear = () => {
        wipeOutFilters()
        wipeOutData()
        setTimeout(() => {
            wipeOutFilters()
            wipeOutData()
        }, 300);
    }

    const wipeOutFilters = () => {
        setSelectedState("")
        setFilterState("")
        clearStateSelectBox()

        setSelectedStores([""])
        setFilterStores([""])
        clearStoreSelectBox()

        setStartDate(new Date())
        setEndDate(new Date())
    }

    return (
        <>
            <div id="scheduleLockReleaseSelects" className="scheduleLockReleaseSelects">
                <StateSelect
                    disabled={isLoading}
                    setFilterState={setFilterState}
                />

                <StoreSelect
                    disabled={isLoading}
                    filterState={filterState}
                    setFilterStores={setFilterStores}
                />

                <div className="scheduleLockReleaseSelects__filterDateRangePicker">
                    <span>Períodos por Data</span>
                    <p>Selecione a(s) data(s) para filtrar</p>
                    <button disabled={isLoading} className="datePickerTrigger"
                            onClick={() => setDateRangeModalOpen(true)}>
                        {format(startDate, "dd/MM/yyyy")} ➟ {format(endDate, "dd/MM/yyyy")}
                    </button>
                </div>

                <div className="scheduleLockReleaseSelects__filterActions">
                    <button onClick={() => handleClear()} disabled={isLoading} className="clearBtn">Limpar</button>
                    &nbsp;
                    &nbsp;
                    <button
                        onClick={() => handleData()}
                        disabled={
                            isLoading ||
                            (selectedStores[0] === "" || selectedStores[0] === undefined || selectedStores[0] === null)
                        }
                        className="filterBtn">
                        Filtrar
                    </button>
                </div>

                <button onClick={() => router.replace("home")} className="backBtn">
                    Voltar
                </button>
            </div>

            <DateRangeModal open={dateRangeModalOpen} setOpen={setDateRangeModalOpen} context={null}/>
        </>
    )
}
