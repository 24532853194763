import { ReactNode } from "react";
import { AuthProvider } from "./auth";
import { PartnerAuthProvider } from "./partnerAuth"
import { StoreProvider } from "./store";
import { SaveProvider } from "./saveConfig";
import { ReschedulingProvider } from "./rescheduling";
import { CanProvider } from "./can";
import { LoaderProvider } from "./loader";
import { TotalNotificationProvider } from "./totalNotification";
import { PartnerContextProvider } from "contexts/partnerContextProvider";

interface AppProviderProps {
  children: ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
  return (
    <PartnerAuthProvider>
      <PartnerContextProvider>
        <AuthProvider>
          <SaveProvider>
            <StoreProvider>
              <CanProvider>
                <LoaderProvider>
                  <TotalNotificationProvider>
                    <ReschedulingProvider>
                      {children}
                    </ReschedulingProvider>
                  </TotalNotificationProvider>
                </LoaderProvider>
              </CanProvider>
            </StoreProvider>
          </SaveProvider>
        </AuthProvider>
      </PartnerContextProvider>
    </PartnerAuthProvider>
  );
}