import styled from "styled-components";

export const Container = styled.div`

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      font-size: 26px;
      color: #2A333E;
      margin-top: 16px;
    }

    span {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
      color: #404F61;
      text-align: center;
    }
    
  }
  .dateReagendamento {
    p::after {
        content: " (obrigatório)";
        color: #7B8FA5;
    }

    p {
      margin-bottom: 1px;
      font-family: 'Source Sans Pro', sans-serif;
      color: #2A333E;
    }
  }

  .periodoHorario {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 16px;

    p::after {
        content: " (obrigatório)";
        color: #7B8FA5;
    }

    p {
      font-family: 'Source Sans Pro', sans-serif;
      color: #2A333E;
    }

    .input {
      margin-top: -14px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    .cancel {
      margin-right: 24px;
    }

    button {
      :disabled {
        color: #404f61;
        background: #f2f2f2;
        cursor: not-allowed;
      }
    }
  }
`;