import styled, { css } from "styled-components";

interface PositionProps {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

interface ContainerProps {
  position: PositionProps;
}

interface TriangleProps {
  inverter?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: absolute !important;
  z-index: 9;

  ${({ position }) => {
    const customPosition = {} as any;

    if (position.top) {
      customPosition.top = position.top;
    }

    if (position.right) {
      customPosition.right = position.right;
    }

    if (position.bottom) {
      customPosition.bottom = position.bottom;
    }

    if (position.left) {
      customPosition.left = position.left;
    }

    const arrayCss = Object.keys(customPosition).map((prop: string) => {
      return `${[prop]}: ${customPosition[prop]}`;
    });

    return css`
      ${arrayCss.join(";")}
    `;
  }};

  width: 331px;
  background: #000;
  opacity: 0.8;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;

  cursor: pointer;

  p {
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const Triangle = styled.div<TriangleProps>`
  position: absolute;
  top: ${props => props.inverter ? '-11px !important' : '-20px !important'};
  left: ${props => props.inverter ? '' : '288px'};

  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #000;
`;
