import "./storeServicesLinkedGrid.scss";
import { useEffect, useRef, useState } from "react";
import { usePartnerContext } from "../../../../contexts/partnerContextProvider";
import { partnerService } from "services/partnerService";
import { useRouter } from "hooks/useRouter";
import { DataTable, DataTableRowEditSaveParams } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { partnerLoginService } from "services/partnerLoginService";
import { usePartnerAuth } from "hooks/partnerAuth";
import { toast } from "react-toastify";

const status = [
  { value: "Ativo", key: "1", status: true },
  { value: "Inativo", key: "0", status: false },
];

interface StoreRoomService {
  idParceiroLojaSalaServico?: number;
  idParceiroServico: number;
  nomeServico: string;
  preco: number;
  flAtivo: boolean;
}

function StoreServicesLinked({ storeId, roomId }) {
  const router = useRouter();
  const { setIsLoading, wipeOutPartnerData } = usePartnerContext();
  const { partnerEmail, handlePartnerSignout } = usePartnerAuth();
  const [originalRows, setOriginalRows] = useState<any>();
  const [partnerId, setPartnerId] = useState(0);
  const datatable = useRef<any>();
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [storeRoomService, setStoreRoomService] = useState<StoreRoomService[]>(
    []
  );

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = () => {
    if (storeId > 0 && roomId > 0) {
      setIsLoading(true);
      partnerLoginService
        .acquirePartnerId(partnerEmail)
        .then((data) => {
          if (data == null || data < 0) {
            wipeOutPartnerData()
            handlePartnerSignout();
          }

          setPartnerId(data);
          return Promise.resolve(data);
        })
        .then((partnerIdResultPromise: number) => {
          if (partnerIdResultPromise != 0) {
            return partnerService
              .getAllStoreRoomServices(partnerIdResultPromise, storeId, roomId)
              .then((items) => {
                items?.map((item: any) => {
                  item.flAtivo = item.flAtivo ?? false;
                  item.preco = item.preco ?? 0;

                  return item;
                });
                setStoreRoomService(items);
              });
          } else setStoreRoomService([]);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const renderCodeCell = (data: any) => {
    return (
      <div className="servicesGridCell">
        <span>{data?.idParceiroServico}</span>
      </div>
    );
  };

  const renderNameCell = (data: any) => {
    return (
      <div className="servicesGridCell">
        <span>{data?.nomeServico}</span>
      </div>
    );
  };

  const renderPriceCell = (data: any) => {
    return (
      <div className="servicesGridCell">
        <span>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(data?.preco)}
        </span>
      </div>
    );
  };

  const handleStatusServiceLinkedByPartnerId = (data: any) => {
    if (!data) return;

    setIsLoading(true);
    partnerService
      .manageStoreRoomServicesById(partnerId, data, storeId, roomId)
      .then((response) => {
        let servicesAux: any[];
        if (typeof response === "boolean") {
          servicesAux = storeRoomService?.map((item: any) => {
            if (item.id == data.id) item.ativo = !item.ativo;

            return item;
          });
          toast.success("Serviço atualizado com sucesso.");
        } else {
          servicesAux = storeRoomService?.map((item: any) => {
            if (item.idParceiroServico == data.idParceiroServico) {
              item.ativo = !item.ativo;
              item.idParceiroLojaSalaServico =
                response.idParceiroLojaSalaServico;
            }

            return item;
          });
          toast.success("Serviço vinculado com sucesso.");
        }
        setStoreRoomService(servicesAux);
      })
      .catch(error => {
        let message = error.response.data.message || error.response.data["message"];
        let indexArray = storeRoomService.findIndex(item => item.idParceiroServico === data.idParceiroServico);
        onRowEditCancel({index: indexArray});
        toast.error(`Não conseguimos salvar o registro. ${message}`);
      })
      .finally(() => setIsLoading(false));
  };

  const onRowEditInit = (event: any) => {
    let originalRowsAux = {};
    originalRowsAux[event.index] = { ...storeRoomService[event.index] };
    setOriginalRows(originalRowsAux);
  };

  const onRowEditCancel = (event: any) => {
    let products = [...storeRoomService];
    products[event.index] = originalRows[event.index];
    delete originalRows[event.index];

    setStoreRoomService(products);
  };

  const onRowEditSave = (event: DataTableRowEditSaveParams) =>
    handleStatusServiceLinkedByPartnerId(event.data);

  const onEditorValueChange = (props: any, value: any) => {
    let updateStoreRoomService = [...props.value];
    props.rowData[props.field] = value;
    updateStoreRoomService[props.rowIndex][props.field] = value;

    setStoreRoomService(updateStoreRoomService);
  };

  const renderStatusCell = (data: any) => getStatusLabel(data?.flAtivo);

  const getStatusLabel = (_status: boolean) =>
    status.find((item) => item.status === _status)?.value;

  const statusEditor = (options: any, field: string) => {
    let stateAux = status.find(
      (item) => item.status === options.rowData[field]
    );

    return (
      <Dropdown
        value={stateAux?.status}
        options={status}
        optionLabel="value"
        optionValue="status"
        onChange={(e) => {
          onEditorValueChange(options, e.value);
        }}
        placeholder="Select a Status"
        itemTemplate={(option) => {
          return (
            <span
              className={`product-badge status-${option.value.toLowerCase()}`}
            >
              {option.value}
            </span>
          );
        }}
      />
    );
  };

  const priceEditor = (options: any, field: string) => {
    return (
      <InputNumber
        value={options.rowData[field] ?? 0}
        onValueChange={(e) => onEditorValueChange(options, e.value)}
        mode="currency"
        currency="BRL"
        locale="pt-BR"
      />
    );
  };

  const onStatusFilterChange = (e: DropdownChangeParams) => {
    if (datatable) {
      datatable.current.filter(e.value, "flAtivo", "equals");
      setSelectedStatusFilter(e.value);
    }
  };

  const representativeFilter = (
    <Dropdown
      value={selectedStatusFilter}
      options={status}
      onChange={onStatusFilterChange}
      optionLabel="value"
      optionValue="status"
      placeholder="Selecione"
      className="p-column-filter"
      showClear
    />
  );

  return (
    <>
      {storeRoomService.length > -1 && (
        <div className="storeServicesLinked">
          <DataTable
            ref={datatable}
            value={storeRoomService}
            editMode="row"
            dataKey="idParceiroServico"
            onRowEditInit={onRowEditInit}
            onRowEditCancel={onRowEditCancel}
            onRowEditSave={(e) => onRowEditSave(e)}
            emptyMessage="Sem resultados para esta consulta."
            className="p-datatable-sm"
            stripedRows
          >
            <Column
              field="idParceiroServico"
              header="Código"
              body={renderCodeCell}
              style={{ width: "10%" }}
              filter
              filterPlaceholder="Código"
            ></Column>
            <Column
              field="nomeServico"
              header="Serviço"
              body={renderNameCell}
              filter
              filterPlaceholder="Nome"
            ></Column>
            <Column
              field="flAtivo"
              header="Status"
              body={renderStatusCell}
              editor={(props) => statusEditor(props, "flAtivo")}
              filter
              filterElement={representativeFilter}
            ></Column>
            <Column
              field="preco"
              header="Preço"
              body={renderPriceCell}
              editor={(props) => priceEditor(props, "preco")}
              filter
              filterPlaceholder="Preço"
            ></Column>
            <Column
              rowEditor
              headerStyle={{ width: "7rem" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default StoreServicesLinked;
