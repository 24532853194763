import './citySelect.scss'
import DropDownBox from "devextreme/ui/drop_down_box";
import DataGrid from "devextreme/ui/data_grid";
import { useEffect, useState } from 'react';
import { usePartnerContext } from 'contexts/partnerContextProvider';
import { partnerScheduleControlService } from 'services/partnerScheduleControlService';
import { toast } from 'react-toastify';
import { DropDownBox as DropDown } from 'devextreme-react/drop-down-box';
import {
    DataGrid as Grid,
    Selection, Paging, FilterRow, Scrolling, Column,
} from 'devextreme-react/data-grid';
import { partnerLoginService } from 'services/partnerLoginService';
import { usePartnerAuth } from 'hooks/partnerAuth';

export const clearCitiesSelectBox = () => {
    const selectBox: any = document.querySelector('#citySelectDropDownBox')
    let instance = DropDownBox.getInstance(selectBox) as DropDownBox;
    instance.reset()

    const selectBoxGrid: any = document.querySelector('#citySelectDropDownBoxGrid')
    let grid_instance = DataGrid.getInstance(selectBoxGrid) as DataGrid;
    grid_instance.option('dataSource', [])
}

interface City {
    nome: string;
    descricao: string;
}

export default function CitySelect({ disabled, filterState, setFilterCities }) {
    const { setIsLoading, selectedState, wipeOutPartnerData } = usePartnerContext();
    const { partnerEmail, handlePartnerSignout } = usePartnerAuth();
    const [cities, setCities] = useState([])
    const [gridBoxValue, setgridBoxValue] = useState<City[]>([])
    const [inputPlaceholder, setInputPlaceholder] = useState('Selecione a(s) cidade(s)')

    useEffect(() => {
        async function handleCities() {
            setIsLoading(true)
        
            return partnerLoginService.acquirePartnerId(partnerEmail)
                .then(async partnerId => {
    
                    if (partnerId == null || partnerId < 0) {
                        wipeOutPartnerData()
                        handlePartnerSignout();
                    }
    
                    const statesData = await partnerScheduleControlService.acquirePartnerCities(partnerId, filterState);
                    const { items } = statesData
        
                    if (items && items.length) setCities(items);
                    else toast.error("Ocorreu um erro ao buscar as cidades.");
                })
                .catch(_ => {
                    wipeOutPartnerData()
                    handlePartnerSignout()
                })
                .finally(() => setIsLoading(false));
        }

        if (selectedState !== "" && selectedState !== null) {
            handleCities()
        }
    }, [filterState, selectedState])

    function dataGridRender() {
        return (
            <>
                <style>
                    {`
                        #citySelectGrid .dx-command-select {  
                            width: 25px!important;  
                            min-width: 25px!important;  
                        }

                        .dx-popup-wrapper > .dx-overlay-content{
                            width: auto !important;
                        }
                    `}
                </style>
                <div id="citySelectGrid">
                    <Grid
                        noDataText="Nada para exibir"
                        id="citySelectDropDownBoxGrid"
                        style={{ maxHeight: '250px', width: '100%' }}
                        dataSource={cities}
                        hoverStateEnabled={true}
                        allowColumnResizing
                        selectedRowKeys={gridBoxValue}
                        onSelectionChanged={dataGridOnSelectionChanged}>
                        <Selection mode="multiple" />
                        <Paging enabled={true} pageSize={10} />
                        <FilterRow visible={true} />
                        <Scrolling mode="virtual" />
                        <Column
                            width="auto"
                            allowSorting={true}
                            dataField="nome"
                            caption="Cidades" />
                    </Grid>
                </div>
            </>
        );
    }

    const dataGridOnSelectionChanged = (e) => {
        setgridBoxValue(e.selectedRowKeys)
        if (e?.selectedRowKeys?.length > 0) {
            setInputPlaceholder(e.selectedRowKeys.map((value) => {
                return value.descricao
            }).join(', '))
        } else {
            setInputPlaceholder("Selecione a(s) cidade(s)")
        }
    }

    const syncDataGridSelection = (e) => {        
        setgridBoxValue(e.value || [])
        if (e?.value?.length > 0) {
            setInputPlaceholder(e.value.map((value) => {
                return value.descricao
            }).join(', '))
        } else {
            setInputPlaceholder("Selecione a(s) cidade(s)")
        }
    }

    const handleSelectedCities = () => {
        const mapCities = gridBoxValue.map((city) => {
            return city.descricao
        })
        setFilterCities(mapCities)
    }

    return (
        <div className="citySelect">
            <span className="citySelect__label">Cidades</span>

            <div title={inputPlaceholder} className="citySelect__field">
                <DropDown
                    id="citySelectDropDownBox"
                    disabled={disabled}
                    value={gridBoxValue}
                    valueExpr="nome"
                    deferRendering={false}
                    displayExpr="descricao"
                    placeholder={inputPlaceholder}
                    showClearButton={true}
                    dataSource={cities}
                    onValueChanged={syncDataGridSelection}
                    contentRender={dataGridRender}
                />

                <button
                    onClick={() => handleSelectedCities()}
                    className="citySelect__field__proceedButton"
                    disabled={!gridBoxValue.length}>
                    OK
                </button>
            </div>
        </div>
    )
}

