import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
  font-family: "Source Sans Pro", sans-serif;

  div.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 33px;
  }

  div.description {
    label {
      font-family: "Source Sans Pro", sans-serif;
      color: #404f61;
    }

    textarea {
      width: 350px;
      height: 96px;
      padding: 10px 10px;

      background: #ffffff;
      border: 1px solid #aec0d2;
      box-sizing: border-box;
      border-radius: 4px;

      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;

      color: #404f61;
      resize: none;
    }
  }

  .motivosContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    color: #2A333E;

    padding-left: 16px;

    span {
      display: flex;
      align-items: center;

      & + span {
        margin-top: 12px;
      }

      label {
        margin: 0;
        margin-left: 7px;
        color: #2A333E;
      }

      input[type="radio"] {
        position: relative;
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #7B8FA5;
        border-radius: 50%;
        outline: none;
        color: #2A333E;
      }

      input[type="radio"][class="checked"] {
        border-color: #0054A6;
        color: #2A333E;
      }

      input[type="radio"]:before {
        content: "";
        display: block;
        border-radius: 50%;
        color: #2A333E;
      }
      
      input[type="radio"]:checked:before {
        position: absolute;
        width: 8px;
        height: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #0054A6;
        color: #2A333E;
      }
    }
  }

  .descricaoContainer {
    display: block;
    margin-top: 8px;
    padding-left: 16px;

    label {
      color: #2A333E;
    }

    label::after {
      content: " (obrigatório)";
      color: ${colors.gray.blue};
    }

    input {
      width: 304px;
      height: 40px;

      display: block;
      border: 1px solid #aec0d2;
      border-radius: 4px;

      padding: 0 8.69px;

      &::placeholder {
        color: ${colors.gray.blue};
      }
    }
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    margin-top: 16px;
    margin-bottom: 0;

    color: #2a333e;
  }

  p {
    max-width: 350px;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    margin-top: 8px;

    color: #404f61;

    /* &.increase-margin {
      margin-bottom: 22px;
    } */
  }
`;

export const ButtonContinue = styled.button`
  width: 132px;
  height: 48px;
  margin-left: 24px;

  color: #ffffff;
  text-align: center;

  background: #0054a6;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;

  :disabled {
    color: #404f61;
    background: #f2f2f2;
    cursor: not-allowed;
  }
`;
