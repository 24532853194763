import styled from "styled-components";
import colors from "../../../tokens/colors";
import arrowImg from "../../../images/icons/select-dropdown.svg";
import calendarImg from "../../../images/icons/iconFilter-calendar.svg";

export const Container = styled.div`
  h2 {
    font-size: 22px;
  }

  p {
    margin-bottom: 4px;
    color: ${colors.dark};
  }
`;

interface OpenModalProps {
  openCalendar: boolean;
}
export const Content = styled.div<OpenModalProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;

  div.calendar {
    margin-bottom: ${(props) => (props.openCalendar ? "100px" : "")};

    span {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #2a333e;
    }

    input {
      margin-top: 4px;
      width: 240px;
      height: 40px;

      border: 1px solid #aec0d2;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px 16px;

      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #7b8fa5;

      ::-webkit-input-placeholder {
        color: #7b8fa5;
      }
    }

    img {
      width: 24px;
      height: 24px;

      color: #7b8fa5;

      margin-left: -35px;
    }
  }

  div.calendarComponent {
    position: absolute;
    z-index: 1;
  }

  div.horario {
    margin-top: 28px;

    div {
      left: 0;
    }

    span {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;

      color: #2a333e;
    }

    span.select {
      font-size: 16px;
    }
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const SelectDropdown = styled.select`
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  border: 1px solid ${colors.gray.blueLight};
  border-radius: 4px;
  padding: 7px 16px;
  appearance: none;
  color: ${colors.gray.blue};

  * {
    background: ${colors.white};

    &:focus {
      background: ${colors.gray.light};
    }
  }

  background: transparent;
  padding-right: 45px;
  background-image: url(${arrowImg});
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + div {
    margin-top: 21px;
  }

  p {
    font-family: "Source Sans Pro", sans-serif;
  }
`;

export const ContainerAgendamento = styled.div`
  max-width: 304px;

  input[type="date"] {
    background: ${colors.white} url(${calendarImg}) 93% 50% no-repeat;
    font-family: "Source Sans Pro", sans-serif;
    border: 1px solid ${colors.gray.blue};
    padding: 7px 16px;
    width: 100%;
    border-radius: 4px;
  }

  input[type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
`;

export const ContainerPeriodoHorario = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const ContainerButton = styled.div`
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  margin-top: 43px;
`;
