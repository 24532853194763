import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import colors from "../../tokens/colors";

interface NotificationProps {
  isNotification: boolean;
  totalNotification?: string;
}

export const Container = styled.div`
  position: fixed;
  width: 100%;
  background-color: ${colors.blue};
  padding: 17px 50px;
  height: 60px;
  display: flex;
  align-items: center;
  z-index: 11;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    nav {
      display: flex;

      > a {
        border: 1px solid ${colors.blueLight};
        border-radius: 4px;
        box-sizing: border-box;
        color: ${colors.white};

        &:hover {
          text-decoration: none;
        }
      }

      button {
        margin-left: 8px;
        background: ${colors.blue};
        border: 1px solid ${colors.blueLight};
        border-radius: 4px;
        box-sizing: border-box;
        color: ${colors.white};
      }
    }
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonText = styled(Link)`
  font-family: "Source Sans Pro", sans-serif;
  padding: 7px 33px;
  text-transform: uppercase;
`;

export const ButtonBell = styled.button<NotificationProps>`
  padding: 7px 9px;
  position: relative;
  background: ${props => props.isNotification ? '#FFFFFF' : '${colors.blue}'} !important;
  border: 1px solid  ${props => props.isNotification ? '#C91C17' : '${colors.blueLight}'} !important;

  ${props => props.isNotification && css`
      &::after {
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -5px;
        top: -5px;
        width: 16px;
        height: 16px;
        background: #C91C17;
        content: '${props.totalNotification}';
        border-radius: 50%;
        color: #FFFFFF;
      }
    `}
    
    ${props => props.isNotification && css`
      .bell {
        animation: ring 1.5s ease;
      }
    `}
    
    @keyframes ring {
      0% {
          transform: rotate(35deg);
      }
      12.5% {
          transform: rotate(-30deg);
      }
      25% {
          transform: rotate(25deg);
      }
      37.5% {
          transform: rotate(-20deg);
      }
      50% {
          transform: rotate(15deg);
      }
      62.5% {
          transform: rotate(-10deg);
      }
      75% {
          transform: rotate(5deg);
      }
      100% {
          transform: rotate(0deg);
      }
  }
`;

export const ButtonMenu = styled.button`
  padding: 7px 12px;
  position: relative;
`;

export const ContainerMenu = styled.div`
  position: relative;
`;

export const ContainerDropdown = styled.div`
  width: 255px;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-72%);
  padding: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  background: ${colors.white};
  border: 1px solid ${colors.gray.light};
  z-index: 11;

  p {
    font-size: 14px;
    margin-bottom: 0;

    & + p {
      margin-top: 16px;
    }

    a {
      color: ${colors.gray.default};

      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      &:hover {
        text-decoration: none;
        color: ${colors.blue};
        font-weight: 600;
      }
    }
  }
`;
