import styled from "styled-components";

interface ContainerProps {
  showCancel: boolean;
  buttonsMarginTop: string;
  isAnimation: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "Source Sans Pro", sans-serif;
  text-align: center;

  img {
    width: 66px;
    height: 66px;
  }

  img {
    animation: ${({ isAnimation }) => isAnimation ? 'loading 1.5s linear infinite' : ''};
    width: ${({ isAnimation }) => isAnimation ? '100px' : ''};
    height: ${({ isAnimation }) => isAnimation ? '100px' : ''};
  }

  h2 {
    color: #2a333e;
    font-size: 26px;
    font-weight: 600;
    margin-top: 24px;
  }

  p {
    color: #404f61;
    margin: 0;
  }

  div {
    max-width: 279px;
    width: 100%;

    display: flex;
    justify-content: center;

    button {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }

    margin-top: ${({ buttonsMarginTop }) => buttonsMarginTop};

    button + button {
      margin-left: 24px;
    }
  }

  .marginButton {
    margin-bottom: 18px;
  }

  @keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
`;
