import { AccountInfo } from "@azure/msal-browser";
import { useState } from "react";
import LoginModal from "../../components/PartnerComponents/LoginModal/loginModal";
import AzureAuthenticationButton from "../../azure/azure-authentication-component";
import { useAuth } from "hooks/auth";

import {
  BannerContainer,
  Container,
  FormContainer,
  Title,
  SubTitle,
  FormContent,
  VersionApp,
  ContainerButton,
} from "./styles";

import bannerImg from "../../images/banner-login.png";

export function FormLogin() {
  const { handleSignIn, handleSession } = useAuth();
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const onAuthenticated = async (userAccountInfo: AccountInfo | undefined) => {
    const matricula = userAccountInfo?.username.split("@")[0];

    try {
      if (matricula) {
        await handleSignIn(matricula, true);
        window.location.href = "/";
      }
      handleSession();
    } catch (err) {
      console.error(err);
    }
  };

  const openLink = (url: string) => window.open(url, "_blank")?.focus();

  const handleLoginModal = () => {
    setLoginModalOpen(!loginModalOpen);
  };

  return (
    <Container>
      <BannerContainer>
        <img src={bannerImg} alt="Banner PagueMenos" />
      </BannerContainer>

      <FormContainer>
        <Title>
          <h1>Clinic Farma</h1>
          <span>Área administrativa</span>
        </Title>

        <SubTitle>
          <p>
            Sua plataforma para gerenciar
            <br />
            atendimento farmacêutico.
          </p>
        </SubTitle>

        <FormContent>
          <ContainerButton>
            <AzureAuthenticationButton onAuthenticated={onAuthenticated} />
          </ContainerButton>
          <ContainerButton style={{ marginTop: "15px" }}>
            <button onClick={() => setLoginModalOpen(true)}>
              Login parceiro
            </button>
          </ContainerButton>
        </FormContent>

        <VersionApp>
          <p>Pague Menos I Clinic Farm V 1.0</p>
        </VersionApp>
      </FormContainer>

      <LoginModal open={loginModalOpen} handleOpen={handleLoginModal} />
    </Container>
  );
}
