import { useState, useContext, createContext, ReactNode } from 'react';

interface SaveContextData {
  isSaved: boolean;
  setIsSaved: (save: boolean) => void;
}

interface SaveProviderProps {
  children: ReactNode;
}

export const SaveContext = createContext({} as SaveContextData);

export function SaveProvider({ children }: SaveProviderProps) {
  const [isSaved, setIsSaved] = useState(true);

  return (
    <SaveContext.Provider
      value={{
        isSaved,
        setIsSaved
      }
      } >
      {children}
    </SaveContext.Provider>

  )
}

export const useSaved = () => useContext(SaveContext);
