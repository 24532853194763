import styled from 'styled-components';
import colors from '../../../tokens/colors';
import arrowImg from '../../../images/icons/select-dropdown.svg';
import calendarImg from '../../../images/icons/iconFilter-calendar.svg';

export const Container = styled.div`
  p {
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
    color: ${colors.dark};
  }

  h2 {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #2A333E;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const SelectDropdown = styled.select`
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid ${colors.gray.blueLight};
  border-radius: 4px;
  padding: 7px 16px;
  appearance: none;
  color: ${colors.gray.blue};

  * {
    background: ${colors.white};

    &:focus {
      background: ${colors.gray.light};
    }
  }

  background: transparent;
  padding-right: 45px;
  background-image: url(${arrowImg});
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + div {
    margin-top: 21px;
  }
`;

export const ContainerAgendamento = styled.div`
  max-width: 304px;

  input[type="date"] {
    background: ${colors.white} url(${calendarImg}) 93% 50% no-repeat;
    font-family: 'Source Sans Pro',sans-serif;
    border:1px solid ${colors.gray.blue};
    padding: 7px 16px;
    width: 100%;
    border-radius: 4px;
  }

  input[type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
`;

export const ContainerGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const ContainerButton = styled.section`
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  margin-top: 50px;
`;