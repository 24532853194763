import { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";

import { min } from "utils";
import { colors } from "tokens";

import * as S from "./styles";

function MoreIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
        fill={colors.blue}
      />
    </svg>
  );
}

function LessIcon() {
  return (
    <svg
      width="14"
      height="2"
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2H1C0.45 2 0 1.55 0 1C0 0.45 0.45 0 1 0H13C13.55 0 14 0.45 14 1C14 1.55 13.55 2 13 2Z"
        fill={colors.gray.blue}
      />
    </svg>
  );
}

interface QuantityProps {
  name: string;
  value?: number | null;
  handleChangeQuantity?: (quantity: number | null) => void;
  disabled?: boolean;
  renovacao?:number;
}

export enum RenovacaoBeneficio {
  Unico = 1,
  Diario = 2,
  Semanal = 3,
  Quinzenal = 4,
  Mensal = 5,
  Trimestral = 6,
  Semestral = 7,
  Anual = 8,
  Ilimitada = 9
}

export function Quantity({
  name,
  value = 1,
  handleChangeQuantity = () => {},
  disabled = false,
  renovacao,
}: QuantityProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  function handleIncrement() {
    handleChangeQuantity(value !== null ? Number(value) + 1 : null);
  }

  function handleDecrement() {
    if (value !== null && Number(value)<= 1) {
      return;
    }

    handleChangeQuantity(value !== null ? Number(value) - 1 : null);
  }

  function handleChange(event) {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    
    handleChangeQuantity(renovacao === RenovacaoBeneficio.Ilimitada ? null : min(1, Number(numericValue)));
  }

  return (
    <S.Container>
      <button disabled={disabled} type="button" onClick={handleDecrement}>
        <LessIcon />
      </button>
      <input
        disabled={disabled}
        ref={inputRef}
        name={name}
        value={value !== null ? value : ''}
        onChange={handleChange}
      />
      <button disabled={disabled} type="button" onClick={handleIncrement}>
        <MoreIcon />
      </button>
    </S.Container>
  );
}
