import './scheduleLockRelease.scss'
import PageContainer from "../../../components/PartnerComponents/PartnerPageContainer/pageContainer";
import {useState} from 'react';
import {usePartnerContext} from 'contexts/partnerContextProvider';
import {partnerLoginService} from 'services/partnerLoginService';
import {usePartnerAuth} from 'hooks/partnerAuth';
import SchedulesGrid from "../../../components/PartnerComponents/SchedulesGrid/schedulesGrid";
import ScheduleLockReleaseSelects
    from "../../../components/PartnerComponents/ScheduleLockReleaseFilters/scheduleLockReleaseSelects";
import {partnerScheduleLockReleaseService} from "../../../services/partnerScheduleLockReleaseService";
import Swal from 'sweetalert2';

function ScheduleLockRelease() {
    const {
        setIsLoading,
        startDate,
        endDate,
        selectedStores,
        wipeOutPartnerData
    } = usePartnerContext()

    const [period, setPeriod] = useState("")
    const {partnerEmail, handlePartnerSignout} = usePartnerAuth()
    const [agendamentos, setAgendamentos] = useState([]);
    const [totalManha, setTotalManha] = useState(0);
    const [totalTarde, setTotalTarde] = useState(0);
    const [totalNoite, setTotalNoite] = useState(0);

    const wipeOutData = () => {
        setAgendamentos([])
    }

    const handleData = async (p = "") => {
        setIsLoading(true)

        const partnerId = await partnerLoginService.acquirePartnerId(partnerEmail)
        if (partnerId == null || partnerId < 0) {
            wipeOutPartnerData()
            handlePartnerSignout()
        }

        const filters = {
            startDate,
            endDate,
            partnerId,
            selectedStores,
            p
        }

        const dashboardData = await partnerScheduleLockReleaseService.acquirePartnerSchedules(filters)

        const {agendamentos, totalManha, totalTarde, totalNoite} = dashboardData.items

        if (!agendamentos.length) {
            Swal.fire(
                {
                    title: "Ops...",
                    text: "Nenhum agendamento encontrado na(s) data(s) informada(s).",
                    icon: 'info',
                    confirmButtonColor: '#0054A6',
                    confirmButtonText: 'Confirmar'
                }
            )
        }

        setAgendamentos(agendamentos)
        setTotalManha(totalManha)
        setTotalTarde(totalTarde)
        setTotalNoite(totalNoite)
        setPeriod(p)

        setIsLoading(false)
    }

    return (
        <PageContainer
            sidebar={
                <ScheduleLockReleaseSelects methods={[wipeOutData, handleData]}/>
            }

            content={
                <div>
                    <SchedulesGrid
                        setPeriod={setPeriod}
                        handleData={(p) => handleData(p)}
                        dashData={[agendamentos, totalManha, totalTarde, totalNoite, period]}/>
                </div>
            }/>
    )
}

export {ScheduleLockRelease}