import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Stats } from "../Stats";

const First = (props: any) => {
  const { state, nextStep } = props;
  const [email, setEmail] = useState("");

  useEffect(() => {
    if(state.infoPartner.emailResponsavel) {
      setEmail(state.infoPartner.emailResponsavel);
      props.update("email", state.infoPartner.emailResponsavel);
      nextStep();
    }
  }, [state?.infoPartner?.emailResponsavel]);

  const update = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.update(e.target.name, e.target.value);
    setEmail(e.target.value);
  };

  return (
    <div className="">
      <h3 className="text-center">
        Para cadastrar o parceiro, preencha os dados abaixo.
      </h3>

      <div className="p-formgroup-inline">
        <div className="p-field p-col-8">
          <label htmlFor="email" className="p-sr-only">
            Email
          </label>
          <InputText
            id="email"
            type="text"
            className="form-control"
            name="email"
            value={email}
            placeholder="email@email.com"
            onChange={update}
            disabled={!!state?.infoPartner?.emailResponsavel}
          />
        </div>
        <Stats step={1} {...props} />
      </div>
    </div>
  );
};

export default First;
