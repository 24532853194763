import './serviceIcon.scss'
import IconService1 from "../../../../../assets/IconService1.svg";
import IconService2 from "../../../../../assets/IconService2.svg";
import IconService3 from "../../../../../assets/IconService3.svg";
import IconService4 from "../../../../../assets/IconService4.svg";
import IconService5 from "../../../../../assets/IconService5.svg";
import IconService6 from "../../../../../assets/IconService6.svg";
import IconService7 from "../../../../../assets/IconService7.svg";
import IconService8 from "../../../../../assets/IconService8.svg";
import IconService9 from "../../../../../assets/IconService9.svg";
import IconService10 from "../../../../../assets/IconService10.svg";
import IconService11 from "../../../../../assets/IconService11.svg";
import IconService12 from "../../../../../assets/IconService12.svg";
import IconService13 from "../../../../../assets/IconService13.svg";
import IconService14 from "../../../../../assets/IconService14.svg";
import IconService15 from "../../../../../assets/IconService15.svg";
import IconService16 from "../../../../../assets/IconService16.svg";
import IconService17 from "../../../../../assets/IconService17.svg";
import IconService18 from "../../../../../assets/IconService18.svg";
import { useEffect, useState } from "react";
import ServicesModal from "../../ServicesModal/servicesModal"

const iconsServices = [
    { id: 1, icon: IconService1 },
    { id: 2, icon: IconService2 },
    { id: 3, icon: IconService3 },
    { id: 4, icon: IconService4 },
    { id: 5, icon: IconService5 },
    { id: 6, icon: IconService6 },
    { id: 7, icon: IconService7 },
    { id: 8, icon: IconService8 },
    { id: 9, icon: IconService9 },
    { id: 10, icon: IconService10 },
    { id: 11, icon: IconService11 },
    { id: 12, icon: IconService12 },
    { id: 13, icon: IconService13 },
    { id: 14, icon: IconService14 },
    { id: 15, icon: IconService15 },
    { id: 16, icon: IconService16 },
    { id: 17, icon: IconService17 },
    { id: 18, icon: IconService18 },
];

interface Icon {
    id: number;
    icon: string;
    name: string;
}

export default function ServiceIcon({ services, clientName }) {
    const [icons, setIcons] = useState<Icon[]>([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const iconArray: any[] = []

        services.forEach(service => {
            const icon = iconsServices.find(i => i.id === service.icone)
            const serviceIcon = {
                ...icon,
                name: `${service.nomeAbreviado}`
            }
            if (icon) { iconArray.push(serviceIcon) }
        })

        setIcons(iconArray)
    }, [])

    const handleServiceNames = () => {
        let serviceNames = ""
        icons.forEach(icon => {
            const { name } = icon
            serviceNames === ""
                ? serviceNames = `➥ ${name}`
                : serviceNames += `\n➥ ${name}`
        })

        return serviceNames
    }

    return (
        <>
            <div
                className="serviceIcons"
                onClick={() => setOpen(true)}
                title={handleServiceNames()}>
                {icons.map((icon, index) => (
                    <div key={index} className="serviceIcons__service">
                        <span className="serviceIcons__service__icon" >
                            <img src={icon?.icon} alt={icon?.name} />
                        </span>
                    </div>
                ))}


            </div>

            <ServicesModal open={open} setOpen={setOpen} icons={icons} clientName={clientName} />
        </>
    )
}