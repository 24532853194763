import "./partnerHome.scss";
import PageContainer from "../../../components/PartnerComponents/PartnerPageContainer/pageContainer";
import SidebarFilterSelects from "components/PartnerComponents/SidebarFilterSelects/sidebarFilterSelects";
import ServicesSlider from "../../../components/PartnerComponents/ServicesSlider/servicesSlider";
import { useState } from "react";
import { usePartnerContext } from "contexts/partnerContextProvider";
import ServicesGrid from "../../../components/PartnerComponents/ServicesGrid/servicesGrid";
import { partnerLoginService } from "services/partnerLoginService";
import { usePartnerAuth } from "hooks/partnerAuth";
import { partnerDashboardService } from "services/partnerDashboardService";
import Swal from "sweetalert2";

interface Servico {
  id: number;
  nome: string;
  nomeAbreviado: string;
  subTitulo: string;
  total: number;
  iconeId: number;
  oderm: number;
  preRequisito: string;
  subtitulo: string;
  tempoExecucao: object;
  tempoResultado: string;
  hora: object;
  descricaoRestricao: string;
  descricaoPreparo: string;
  descricao: string;
  coletaMaterial: string;
  codProduto: string;
  ativo: boolean;
}

interface Agendamento {
  cliente: Cliente;
  data: string;
  hora: string;
  id: number;
  idAgendamento: number;
  loja: string;
  lojaId: number;
  status: number;
  servicos: Servico[];
}

interface Cliente {
  nome: string;
}

function PartnerHome() {
  const {
    isLoading,
    setIsLoading,
    startDate,
    endDate,
    selectedStores,
    wipeOutPartnerData,
  } = usePartnerContext();
  const { partnerEmail, handlePartnerSignout } = usePartnerAuth();
  const [servicos, setServicos] = useState<Servico[]>([]);
  const [agendamentos, setAgendamentos] = useState<Agendamento[]>([]);

  const wipeOutData = () => {
    setServicos([]);
    setAgendamentos([]);
  };

  const handleDashboardData = async () => {
    setIsLoading(true);

    const partnerId = await partnerLoginService.acquirePartnerId(partnerEmail);
    if (partnerId == null || partnerId < 0) {
      wipeOutPartnerData();
      handlePartnerSignout();
    }

    const filters = {
      startDate,
      endDate,
      partnerId,
      selectedStores,
    };

    const dashboardData = await partnerDashboardService.acquireDashboardData(
      filters
    );

    const { servicos: srv, agendamentos: agd } = dashboardData;

    if (!agd.length) {
      Swal.fire({
        title: "Ops...",
        text: "Nenhum agendamento encontrado na(s) data(s) informada(s).",
        icon: "info",
        confirmButtonColor: "#0054A6",
        confirmButtonText: "Confirmar",
      });
    }

    setServicos(srv);
    setAgendamentos(agd);

    setIsLoading(false);
  };

  return (
    <PageContainer
      sidebar={
        <SidebarFilterSelects methods={[wipeOutData, handleDashboardData]} />
      }
      content={
        <div className="partnerHomeContent">
          <div className="partnerHomeContent__servicesSlider">
            <ServicesSlider services={servicos} loading={isLoading} />
          </div>

          <ServicesGrid
            dashData={[servicos, agendamentos, handleDashboardData]}
          />
        </div>
      }
    />
  );
}

export { PartnerHome };
