import { useEffect, useState } from "react";
import axios from "axios";

import Button from "components/Button";
import { ModalBase } from "components/ModalGroup/ModalBase";
import { Grid } from "components/Grid";
import { debounce } from "utils/debounce";

import { Container, ContainerInput, ContainerButton } from "./styles";

interface Loja {
  bairro: string;
  cep: string;
  cidade: string;
  codigo: number;
  endereco: string;
  estado: string;
  nome: string;
  numero: number;
  telefone01: string;
  telefone02: string;
}

interface StoreLinkServiceModalProps {
  onRequestClose: () => void;
  isOpen: boolean;
  idServico: number;
}

export function StoreLinkServiceModal({
  onRequestClose,
  isOpen,
  idServico
}: StoreLinkServiceModalProps) {
  const [stores, setStores] = useState<Loja[]>([]);
  const [storesFilter, setStoresFilter] = useState<Loja[]>([]);
  const [filterText, setFilterText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      setStores([]);
      if (idServico !== 0) {
          axios.get(`/ServicoData/GetLojas/${idServico}`).then((response) => {
              if (response.data.result === "Success") {
                  setStores(response.data.items);
                  setStoresFilter(response.data.items);
                  setIsLoading(false);
              }
          });
      }
  }, [idServico]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }

    if (filterText) {
      debounce(() => {
        const filterStores = stores.filter(
          (store) =>
            store.nome.toLowerCase().includes(filterText.toLowerCase()) ||
            String(store.codigo).includes(filterText)
        );

        if (filterStores) {
          setStoresFilter(filterStores);
        }

        setIsLoading(false);
      }, 500);
    } else {
      setStoresFilter(stores);
      setIsLoading(false);
    }
  }, [filterText, stores]);

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type="large">
      <Container>
        <h2>Lojas vinculadas ao serviço</h2>

        <ContainerInput>
          <input
            type="text"
            placeholder="Buscar por nome ou código"
            onChange={(event) => setFilterText(event.target.value)}
            value={filterText}
          />
          <svg
            width="20"
            height="20"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/200/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.9624 11.4716H12.7629L17.0595 15.7316C17.4749 16.1416 17.4749 16.8116 17.0595 17.2216C16.644 17.6316 15.9651 17.6316 15.5496 17.2216L11.2429 12.9716V12.1816L10.9693 11.9016C9.55068 13.1016 7.61521 13.7216 5.55814 13.3816C2.74108 12.9116 0.491476 10.5916 0.146943 7.79157C-0.379991 3.56157 3.22748 0.0157288 7.51388 0.521573C10.3512 0.861573 12.7021 3.08157 13.1784 5.86157C13.5229 7.89157 12.8947 9.80157 11.6787 11.2016L11.9624 11.4716ZM2.12294 6.97157C2.12294 9.46157 4.15974 11.4716 6.68294 11.4716C9.20615 11.4716 11.2429 9.46157 11.2429 6.97157C11.2429 4.48157 9.20615 2.47157 6.68294 2.47157C4.15974 2.47157 2.12294 4.48157 2.12294 6.97157Z"
              fill="#7B8FA5"
            />
          </svg>
        </ContainerInput>

        {((!!stores.length && !filterText) || !!storesFilter.length) && (
          <Grid
            columns={["Codigo", "Loja", "Estado"]}
            omit={[
              "bairro",
              "cep",
              "cidade",
              "endereco",
              "numero",
              "telefone01",
              "telefone02",
            ]}
            className="custom-display-grid"
            customStyle={{
              width: "104%",
            }}
            columnsWidth={[
              {
                column: "Codigo",
                width: "70px",
              },
              {
                column: "Loja",
                width: "300px",
              },
              {
                column: "Estado",
                width: "91px",
              },
            ]}
            data={storesFilter}
          />
        )}
        {isLoading && <p>Carregando...</p>}

        <ContainerButton>
          <Button onClick={onRequestClose} inverted>
            Fechar
          </Button>
        </ContainerButton>
      </Container>
    </ModalBase>
  );
}
