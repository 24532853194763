import { useEffect, useState } from "react";
import { Container, Select, Selected, CheckDiv } from "./styles";

import SelectIcon from "images/icons/icon-select-option.svg";
import { Checkbox } from "components/Checkbox";

interface StoreData {
  codigo: number;
  nome: string;
}

interface SelectStoreProps {
  storesSelected: StoreData[];
  storeList: StoreData[];
  handleSelectStore: React.Dispatch<React.SetStateAction<StoreData[]>>;
}

export default function SelectStores({
  storesSelected,
  storeList,
  handleSelectStore,
}: SelectStoreProps) {
  const [openSelectStore, setOpenSelectStore] = useState(false);

  useEffect(() => {
    window.removeEventListener("click", detectClickOusideMenu);
    window.addEventListener("click", detectClickOusideMenu);

    return () => window.removeEventListener("click", detectClickOusideMenu);
  }, [openSelectStore]);

  function detectClickOusideMenu(e: MouseEvent) {
    const marginScroll = document.getElementById("marginScroll");

    // @ts-ignore
    if (!marginScroll?.contains(e.target)) {
      if (!marginScroll) {
        return;
      }

      if (openSelectStore) {
        setOpenSelectStore(false);
      }
    }
  }

  function handleSelectAll() {
    handleSelectStore([...storeList.map((item) => item)]);
  }

  function handleToggleSelected(store: StoreData) {
    if (storeList?.length === 1) return;
    let newArray;
    if (storesSelected.includes(store)) {
      newArray = storesSelected.filter((item) => item.codigo !== store.codigo);
    } else {
      newArray = [...storesSelected, store];
    }
    handleSelectStore(newArray);
  }

  return (
    <Container>
      <label>Loja</label>

      {storeList?.length === 1 ? (
        <Select storesSelected={storeList.length}>
          {storeList?.map((item) => (
            <label
              key={item.codigo}
              className="selectFix"
              onClick={() => handleToggleSelected(item)}
            >
              {item.codigo + " - " + item.nome}
            </label>
          ))}
          <img src={SelectIcon} alt="select/option" id="selection" />
        </Select>
      ) : (
        <Select
          onClick={() => setOpenSelectStore(!openSelectStore)}
          storesSelected={storesSelected.length}
        >
          {storesSelected?.length === 0 ? (
            <span>Selecione uma loja</span>
          ) : (
            <span>
              {storesSelected?.length === storeList?.length
                ? "Todas lojas selecionadas"
                : `${storesSelected?.length} Lojas selecionadas`}
            </span>
          )}

          <img src={SelectIcon} alt="select/option" id="selection" />
        </Select>
      )}

      {openSelectStore && (
        <div id="marginScroll">
          <Selected>
            <div className="selected">
              <CheckDiv
                isChecked={storesSelected?.length === storeList?.length}
              >
                <Checkbox
                  checked={storesSelected?.length === storeList?.length}
                  onClick={() =>
                    !(storesSelected?.length === storeList?.length)
                      ? handleSelectAll()
                      : handleSelectStore([])
                  }
                  isSelectAll
                />
                <label>Selecionar todos</label>
              </CheckDiv>
              {!!storeList?.length &&
                storeList?.map((item: any) => (
                  <CheckDiv
                    key={item.codigo}
                    isChecked={storesSelected?.includes(item)}
                    id="checkbox"
                  >
                    <Checkbox
                      checked={storesSelected?.includes(item)}
                      onClick={() => handleToggleSelected(item)}
                    />
                    <label>{item.codigo + " - " + item.nome}</label>
                  </CheckDiv>
                ))}
            </div>
          </Selected>
        </div>
      )}
    </Container>
  );
}
