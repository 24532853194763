import './partnerUserCard.scss'
import { usePartnerAuth } from '../../../hooks/partnerAuth'

export default function PartnerUserCard() {
    const { partnerName, partnerEmail } = usePartnerAuth()

    return (
        <div className="partnerUserCard">
            <div className="partnerUserCard__userImg" />
            <div className="partnerUserCard__userInfo">
                <span title={partnerName} className="partnerUserCard__userInfo__userName">
                    {partnerName}
                </span>
                <span title={partnerEmail} className="partnerUserCard__userInfo__userEmail">
                    {partnerEmail}
                </span>
                <span className="partnerUserCard__userInfo__description">
                    <span>Parceiro</span>
                </span>
            </div>
        </div>
    )
}
