import styled from "styled-components";
import colors from "tokens/colors";
import arrowImg from "images/icons/select-dropdown.svg";

export const Container = styled.div`
  font-family: "Source Sans Pro", sans-serif;

  .cabecalho {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      margin-top: 16.5px;

      font-weight: 600;
      font-size: 26px;
      color: #2A333E;
    }

    span {      
      text-align: center;
      color: #404F61;
    }

    .icon {
      width: 66.16px;
      height: 66px;
      border-radius: 50%;
      background: #CCE5F3;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  .body {
    width: 354.85px;
    margin-top: 16px;

    select {
      width: 100%;
      height: 40px;
      font-family: "Source Sans Pro", sans-serif;
      background: transparent;
      border: 1px solid ${colors.gray.blueLight};
      border-radius: 4px;
      padding: 7px 16px;
      color: #404F61;
      appearance: none;
      padding-right: 45px;
      background-image: url(${arrowImg});
      background-repeat: no-repeat;
      background-position-x: 95%;
      background-position-y: 50%;
    }
  }

  .botoes {
    margin-top: 26.5px;
    display: flex;
    justify-content: center;
    
    .cancelar {
      margin-right: 24px;
    }

    button:disabled {
      background: #f2f2f2;
      color: ${colors.gray.text};
      cursor: not-allowed;
    }
  }
`;