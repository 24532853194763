import styled from "styled-components";

interface ContainerProps {
  showCancel: boolean;
  buttonsMarginTop: string;
}

export const Container = styled.div<ContainerProps>`
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;

  img {
    width: 66px;
    height: 66px;
  }

  h2 {
    color: #2a333e;
    font-size: 26px;
    font-weight: 600;
    margin-top: 24px;
  }

  p {
    color: #404f61;
    margin: 0;
  }

  .flex-1 {
    flex: 1;
  }

  .mw-unset {
    max-width: unset;
  }

  div:last-child:not(.p-dropdown-trigger) {
    margin-top: ${({ buttonsMarginTop }) => buttonsMarginTop};

    button {
      + button {
        margin-left: 24px;
      }

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }
  }

  .marginButton {
    margin-bottom: 18px;
  }
`;
