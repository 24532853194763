import { useEffect, useRef, useState } from 'react';
import { Card } from './styles';
import { useStore } from 'hooks/store';
import axios from 'axios';
import { Tooltip } from 'components/Tooltip';
import { formatDistanceToNow } from 'date-fns';
import ptBR from "date-fns/locale/pt-BR";
import useClickAway from 'react-use/lib/useClickAway';

import { useLoader } from 'hooks/loader';

import IconRescheduling from 'images/icons/icon-rescheduling.svg';
import IconCanceled from 'images/icons/icon-canceled.svg';
import IconSchedule from 'images/icons/icon-schedule.svg';

import iconLido from 'images/icons/icon-mensagem-lida.svg';

import IconService1 from "../../assets/IconService1.svg";
import IconService2 from "../../assets/IconService2.svg";
import IconService3 from "../../assets/IconService3.svg";
import IconService4 from "../../assets/IconService4.svg";
import IconService5 from "../../assets/IconService5.svg";
import IconService6 from "../../assets/IconService6.svg";
import IconService7 from "../../assets/IconService7.svg";
import IconService8 from "../../assets/IconService8.svg";
import IconService9 from "../../assets/IconService9.svg";
import IconService10 from "../../assets/IconService10.svg";
import IconService11 from "../../assets/IconService11.svg";
import IconService12 from "../../assets/IconService12.svg";
import IconService13 from "../../assets/IconService13.svg";
import IconService14 from "../../assets/IconService14.svg";
import IconService15 from "../../assets/IconService15.svg";
import IconService16 from "../../assets/IconService16.svg";
import IconService17 from "../../assets/IconService17.svg";
import IconService18 from "../../assets/IconService18.svg";

interface ServicoProps {
  id: number;
  iconeId: number;
  nome: string;
  nomeAbreviado: string;
}

interface NotificationProps {
  cliente: string;
  data: string;
  horario: string;
  dataLeitura: string;
  id: number;
  idAgendamento: number;
  tipo: string;
  servicos: ServicoProps[];
  nomeServicos: string;
  dataFormat: string;
  dataNotificacao: string;
  dataNotificacaoLida: string;
}


export default function CardNotification() {
  const { selectedStores, store } = useStore();
  const { handleSetIsLoader } = useLoader();
  const [notifications, setNotifications] = useState<NotificationProps[]>([])
  const [notificationLidas, setNotificationLidas] = useState<NotificationProps[]>([])
  const [TooltipOpen, setTooltipOpen] = useState(0);

  const tooltipRef = useRef(null);
  useClickAway(tooltipRef, () => {
    setTooltipOpen(0);
  });

  const iconStatusObject = {
    "Reagendamento":IconRescheduling,
    "Cancelamento":IconCanceled,
    "Agendamento":IconSchedule,
  }

  const iconsServices = {
    1: IconService1,
    2: IconService2,
    3: IconService3,
    4: IconService4,
    5: IconService5,
    6: IconService6,
    7: IconService7,
    8: IconService8,
    9: IconService9,
    10: IconService10,
    11: IconService11,
    12: IconService12,
    13: IconService13,
    14: IconService14,
    15: IconService15,
    16: IconService16,
    17: IconService17,
    18: IconService18,
  };

  useEffect(() => {
    loadNotification();
  }, [])

  function loadNotification(){
    
    if(selectedStores.length === 1){
      handleSetIsLoader(true);
      axios.get(`/AgendamentoData/GetNotificacoes?codigoLoja=${selectedStores[0].codigo}`)
           .then((response) => {
              if(response.data.result === "Success"){                
                const formattedNotification: NotificationProps[] = response.data.items.map((notification: any) => {
                    const formattedServicos: ServicoProps[] = 
                      notification.servicos.map((item: any) => {
                        return {
                          id: item.id,
                          iconeId: item.iconeId,
                          nome: item.nome,
                          nomeAbreviado: item.nomeAbreviado,
                        }
                      })
                    //fformatação data
                    const [dataForm, horaForm] = notification.data.split(' ', 2);
                    const [dia, mes, ano] = dataForm.split('/', 3);
                    const [hora, min, _] = horaForm.split(':',3);

                    // formatação Data Notificação
                    const [dataNoti, horaNoti] = notification.dataNotificacao.split(' ', 2);
                    const [diaN, mesN, anoN] = dataNoti.split('/', 3);
                    const [horaN, minN] = horaNoti.split(':', 3);

                    const dataNotificationForm = new Date(`${mesN}/${diaN}/${anoN}`);
                    dataNotificationForm.setHours(horaN,minN,0,0);

                    // formatação Data Notificação Lida
                    let dataNotificationLidasForm: string = '';
                    let dataAuxiliar;
                    if(notification.dataLeitura !== ''){
                      const [dataNotiL, horaNotiL] = notification.dataLeitura.split(' ', 2);
                      const [diaNL, mesNL, anoNL] = dataNotiL.split('/', 3);
                      const [horaNL, minNL] = horaNotiL.split(':', 3);

                      dataAuxiliar = new Date(`${mesNL}/${diaNL}/${anoNL}`);
                      dataAuxiliar.setHours(parseInt(horaNL),parseInt(minNL),0,0);

                      dataNotificationLidasForm = formatDistanceToNow(dataAuxiliar, { locale: ptBR, includeSeconds: true })
                    }

                    console.log('cliente', notification.cliente.nome);
                    console.log('dataNotificacao', notification.dataNotificacao);
                    console.log('dataLeitura', notification.dataLeitura);
                    console.log('******************************');

                    return {
                      cliente: notification.cliente.nome,
                      data: `${dia}/${mes}/${ano}`,
                      horario: `${hora}:${min}`,
                      dataLeitura: notification.dataLeitura,
                      id: notification.id,
                      idAgendamento: notification.idAgendamento,
                      tipo: notification.tipo,
                      servicos: formattedServicos,
                      nomeServicos: formattedServicos
                        .map((servico) => servico.nomeAbreviado)
                        .join("<br />"),
                      dataNotificacao: formatDistanceToNow(dataNotificationForm, { locale: ptBR, includeSeconds: true }),
                      dataNotificacaoLida: dataNotificationLidasForm,
                    }
                    
                    
                  });
                 carregar(formattedNotification);
                 handleSetIsLoader(false);
              }

            });  
    }
  }

  function carregar(notificacoe: NotificationProps[]){    
    let lidas: NotificationProps[] = [];
    let naoLidas: NotificationProps[] = [];    
      notificacoe.map(item => {
        if(item.dataLeitura === '')
          naoLidas.push(item);
        else
          lidas.push(item);
    })
    setNotifications(naoLidas);
    setNotificationLidas(lidas);
  }

  function handleMarkSingleNotification(item: NotificationProps){
    const data = {
      id: item.id,
      tipo: item.tipo,
    }
    axios.put("/AgendamentoData/SetLeituraUnica", data )
         .then((response) => {
            loadNotification();
          })
    
  }

  function handleMarkMultNotification(items: NotificationProps[]){
    let notificationsForm = items.map(item => {
      return {
        id: item.id,
        tipo: item.tipo
      }
    })
    const data = {
      notificacoes: notificationsForm
    }
    
    axios.put("/AgendamentoData/SetLeitura", data )
         .then((response) => {
           console.log(response.data);
           loadNotification();
         })
  }

  function iconSelected(tipo: string){
    // @ts-ignore
    return iconStatusObject[tipo]
  }
  return(
    <Card>
      <header>
        <p>Notificações</p>
        <span>Somente ações realizadas no dia de hoje serão notificadas.</span>
      </header>
      <main>
        {((notifications.length > 0 || notificationLidas.length > 0) && selectedStores.length === 1) ? (
          <>
            {notifications.map((item) => (
              <div key={item.id}>
                <div className="item-notification">
                  <div className="row1">
                    <img src={iconSelected(item.tipo)} alt="icon" />
                    <p style={{
                      color: `${item.tipo === "Reagendamento" ? '#0054A6' : item.tipo === "Cancelamento" ? '#C91C17' : '#237B4B'}`
                    }}>{item.tipo}</p>
                    <label>para o dia {item.data} às {item.horario} - Loja {selectedStores[0].codigo}</label>
                  </div>
                  <span>{item.cliente}</span>
                  <div className="icons">
                    {item.servicos.map((service: ServicoProps) => {
                      //@ts-ignore
                     return  <img src={iconsServices[service.iconeId]} alt="Ícone da notificação" key={service.id} onClick={() => {setTooltipOpen(TooltipOpen === item.id ? 0 : item.id);}} />
                    })}
                    {TooltipOpen === item.id &&
                      <Tooltip
                        onClick={() => setTooltipOpen(0)}
                        position={{ left: "-15px", top: "37px" }}
                        html={item.nomeServicos}
                        inverter={true}
                        ref={tooltipRef}
                      />
                    }
                    
                  </div>
                  <div className="row2">
                    <span className="tempo">{item.dataNotificacao} atrás</span>
                    <label className="button-marcar-todos" onClick={() => handleMarkSingleNotification(item)}>Marcar como lida</label>
                  </div>
                  <div className="linha"></div>
                </div>
              </div>
            ))}

            {(notificationLidas.length > 0) && !(notifications.length > 0 && notificationLidas.length === 0) && <div className="notificacoes-lidas">Notificações lidas</div>}

            {notificationLidas.map((item) => (
              <div key={item.id}>
                <div className="item-notification">
                  <div className="row1">
                    <img src={iconLido} alt="icon" />
                    <p style={{
                      color: `${item.tipo === "Reagendamento" ? '#0054A6' : item.tipo === "Cancelamento" ? '#C91C17' : '#237B4B'}`
                    }}>{item.tipo}</p>
                    <label>para o dia {item.data} às {item.horario} - Loja {selectedStores[0].codigo}</label>
                  </div>
                  <span>{item.cliente}</span>
                  <div className="icons">
                    {item.servicos.map((service: ServicoProps) => {               
                      {/* @ts-ignore  */}
                      return <img src={iconsServices[service.iconeId]} alt="" key={service.id} onClick={() => {
                                  setTooltipOpen(TooltipOpen === item.id ? 0 : item.id);
                              }} />
                    })}
                      
                      {TooltipOpen === item.id &&
                      <Tooltip
                        onClick={() => setTooltipOpen(0)}
                        position={{ left: "-15px", top: "47px" }}
                        html={item.nomeServicos}
                        inverter={true}
                        ref={tooltipRef}
                      />}
                  </div>
                  <div className="row2">
                    <span className="tempo">{item.dataNotificacaoLida} atrás - lida</span>
                  </div>
                  <div className="linha"></div>
                </div>
              </div>
            ))}
            
          </>
        ) : selectedStores.length > 1 ? (
          <div className="nenhuma-notificacao">
            <label>"Para visualizar as notificações<br />   selecione apenas uma loja"</label>
          </div>
        ) : (
          <div className="nenhuma-notificacao">
            <label>Nenhuma notificação listada.</label>
          </div>
        )}
        
      </main>
      {((
          (notifications.length > 0 && notificationLidas.length > 0) ||
          (notifications.length > 1)
        ) && selectedStores.length === 1) && (
        <footer>
          <button onClick={() => handleMarkMultNotification(notifications)}>Marcar todas como lida</button>
        </footer>
      )}
      
    </Card>
  );
}