import { useEffect, useState } from "react";
import { ModalBase } from "../ModalBase";
import axios from "axios";

import iconBlock from "images/icons/icon-cancel.svg";
import IconClose from "images/icons/icon-close-modal.png";

import { Container, Description, ButtonContinue } from "./styles";

type MotivoCancelamentoData = {
  motivoSelecionado: number;
  descricaoMotivo: string;
};

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onContinue: (data: MotivoCancelamentoData) => void;
}

interface MotivoProps {
  descricao: string;
  id: number;
}

export function MotivoCancelamento({
  isOpen,
  onRequestClose,
  onContinue,
}: ModalProps) {
  const [motivoSelecionado, setMotivoSelecionado] = useState(-1);
  const [descricaoMotivo, setDescricaoMotivo] = useState("");
  const [motivos, setMotivos] = useState<MotivoProps[]>([]);

  useEffect(() => {
    async function loadMotivo(){
      const response = await axios.get("/AgendamentoData/GetCancelamentoMotivos");
      setMotivos(response.data.items);
    }
    loadMotivo();
  }, [])

  useEffect(() => {
    setDescricaoMotivo("");
  }, [motivoSelecionado]);

  useEffect(() => {
    setMotivoSelecionado(-1);
  }, [isOpen]);

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type={"medium"}>
      <img src={IconClose} alt="close" style={{
        position: "absolute",
        top: "20px",
        right: "16px",
        cursor: "pointer"
      }} onClick={() => onRequestClose()} />
      <Container>
        <Description>
          <div>
            <img
              src={iconBlock}
              alt="Ícone de cancelamento"
            />
          </div>
          <h2>Cancelar agendamento</h2>
          <p className={motivoSelecionado !== 0 ? "increase-margin" : ""}>
            Deseja realmente cancelar esse agendamento? Informe abaixo o motivo.
          </p>
        </Description>

        <div className="motivosContainer">
          {motivos?.map((motivo) => (
            <span key={motivo.id}>
              <input
                type="radio"
                name="motivo"
                id={"motivo-" + motivo.id}
                className={motivoSelecionado === motivo.id ? "checked" : ""}
                onClick={() => setMotivoSelecionado(motivo.id)}
              />
              <label htmlFor={"motivo-" + motivo.id}>
                {motivo.descricao}
              </label>
            </span>
          ))}
          <span>
            <input
              type="radio"
              name="motivo"
              className={motivoSelecionado === 0 ? "checked" : ""}
              id="motivo-outro"
              onClick={() => setMotivoSelecionado(0)}
            />
            <label htmlFor="motivo-outro">Outro</label>
          </span>
        </div>

        {motivoSelecionado === 0 && (
          <span className="descricaoContainer">
            <label htmlFor="motivo-descricao">Qual o motivo?</label>
            <input
              name="motivo-descricao"
              id="motivo-descricao"
              placeholder="Limite de 40 caracteres"
              maxLength={40}
              value={descricaoMotivo}
              onChange={(event) => setDescricaoMotivo(event.target.value)}
            />
          </span>
        )}

        <div className="buttons">
          <ButtonContinue
            disabled={
              motivoSelecionado === -1 ||
              (motivoSelecionado === 0 && !descricaoMotivo)
            }
            onClick={() => {
              onContinue({ motivoSelecionado, descricaoMotivo });
            }}
          >
            Continuar
          </ButtonContinue>
        </div>
      </Container>
    </ModalBase>
  );
}
