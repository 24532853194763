import Button from "components/Button";
import closeModal from "images/icons/icon-close-modal.png";
import Select from "components/SelectDropdown";
import { ModalBase } from "../ModalBase";
import { InputCalendar } from "components/InputCalendar";

import {
  Container,
  CloseModal,
  SectionContent,
  ContainerButton,
  ContainerAgendamento,
  ContainerGroup,
} from "./styles";
import { Form } from "@unform/web";
import { useEffect, useState } from "react";
import axios from "axios";

interface Servico {
  id: number;
  codigo: string;
  servico: string;
  status: number;
}

interface UF {
  sigla: string;
  descricao: string;
}
interface Cidade {
  nome: string;
  descricao: string;
}
interface Loja {
  id: string;
  codigo: string;
  nome: string;
}

interface ServiceFilterModalProps {
  onRequestClose: () => void;
  onContinue: (
    ufSelected: string,
    cidadeSelected: string,
    bairroSelected: string,
    horarioSelected: string,
    servicoSelected: number
  ) => void;
  defaultData: {
    filterUf: string;
    filterCidade: string;
    filterBairro: string;
    filterHorario: string;
    filterServico: number;
  };
  isOpen: boolean;
}

export function ServiceFilterModal({
  onRequestClose,
  onContinue,
  defaultData,
  isOpen,
}: ServiceFilterModalProps) {
  const horariosManha = [
    {
      value: "600",
      label: "06:00",
    },
    {
      value: "630",
      label: "06:30",
    },
    {
      value: "700",
      label: "07:00",
    },
    {
      value: "730",
      label: "07:30",
    },
    {
      value: "800",
      label: "08:00",
    },
    {
      value: "830",
      label: "08:30",
    },
    {
      value: "900",
      label: "09:00",
    },
    {
      value: "930",
      label: "09:30",
    },
    {
      value: "1000",
      label: "10:00",
    },
    {
      value: "1030",
      label: "10:30",
    },
    {
      value: "1100",
      label: "11:00",
    },
    {
      value: "1130",
      label: "11:30",
    },
  ];

  const horariosTarde = [
    {
      value: "1200",
      label: "12:00",
    },
    {
      value: "1230",
      label: "12:30",
    },
    {
      value: "1300",
      label: "13:00",
    },
    {
      value: "1330",
      label: "13:30",
    },
    {
      value: "1400",
      label: "14:00",
    },
    {
      value: "1430",
      label: "14:30",
    },
    {
      value: "1500",
      label: "15:00",
    },
    {
      value: "1530",
      label: "15:30",
    },
    {
      value: "1600",
      label: "16:00",
    },
    {
      value: "1630",
      label: "16:30",
    },
    {
      value: "1700",
      label: "17:00",
    },
    {
      value: "1730",
      label: "17:30",
    },
  ];

  const horariosNoite = [
    {
      value: "1800",
      label: "18:00",
    },
    {
      value: "1830",
      label: "18:30",
    },
    {
      value: "1900",
      label: "19:00",
    },
    {
      value: "1930",
      label: "19:30",
    },
    {
      value: "2000",
      label: "20:00",
    },
    {
      value: "2030",
      label: "20:30",
    },
    {
      value: "2100",
      label: "21:00",
    },
    {
      value: "2130",
      label: "21:30",
    },
    {
      value: "2200",
      label: "22:00",
    },
    {
      value: "2230",
      label: "22:30",
    },
    {
      value: "2300",
      label: "23:00",
    },
    {
      value: "2330",
      label: "23:30",
    },
    {
      value: "0000",
      label: "00:00",
    },
    {
      value: "0030",
      label: "00:30",
    },
    {
      value: "100",
      label: "01:00",
    },
    {
      value: "130",
      label: "01:30",
    },
    {
      value: "200",
      label: "02:00",
    },
    {
      value: "230",
      label: "02:30",
    },
    {
      value: "300",
      label: "03:00",
    },
    {
      value: "330",
      label: "03:30",
    },
    {
      value: "400",
      label: "04:00",
    },
    {
      value: "430",
      label: "04:30",
    },
    {
      value: "500",
      label: "05:00",
    },
    {
      value: "530",
      label: "05:30",
    },
  ];

  const [ufs, setUfs] = useState<UF[]>([]);
  const [cidades, setCidades] = useState<Cidade[]>([]);
  const [bairros, setBairros] = useState<string[]>([]);
  const [servicos, setServicos] = useState<Servico[]>([]);

  const [ufSelected, setUfSelected] = useState(defaultData.filterUf);
  const [cidadeSelected, setCidadeSelected] = useState(
    defaultData.filterCidade
  );
  const [bairroSelected, setBairroSelected] = useState(
    defaultData.filterBairro
  );
  const [horarioSelected, setHorarioSelected] = useState(
    defaultData.filterHorario
  );
  const [servicoSelected, setServicoSelected] = useState(
    defaultData.filterServico
  );

  useEffect(() => {
    setCidadeSelected("");
    setBairroSelected("");
  }, [ufSelected]);

  useEffect(() => {
    axios.get<Servico[]>(`servicoData/`).then((response: any) => {
      if (response.data.result == "Success") {
        let servicos: Servico[] = [];
        response.data.items.map((item: any) => {
          let servico = {} as Servico;
          servico.id = item.id;
          servico.codigo = item.codProduto;
          servico.servico = item.nomeAbreviado;
          servicos.push(servico);
        });
        setServicos(servicos);
      }
    });
    axios.get("/lojaData/GetEstados").then((response) => {
      if (response.data.result == "Success") {
        setUfs(response.data.items);
      }
    });
  }, []);

  function loadCidades(uf: string) {
    setUfSelected(uf);
    axios.get(`/lojaData/GetCidades?uf=${uf}`).then((response) => {
      if (response.data.result == "Success") {
        setCidades(response.data.items);
      }
    });
  }

  function loadBairros(cidade: string) {
    setCidadeSelected(cidade);
    axios
      .get(`/lojaData/GetBairros?uf=${ufSelected}&cidade=${cidade}`)
      .then((response) => {
        if (response.data.result == "Success") {
          setBairros(response.data.items);
        }
      });
  }

  function obterHorariosPorPeriodo() {
    return [...horariosManha, ...horariosTarde, ...horariosNoite];
  }

  return (
    <ModalBase isOpen={isOpen} onRequestClose={onRequestClose} type="large">
      <Container>
        <CloseModal onClick={onRequestClose}>
          <img src={closeModal} alt="Fechar Modal" />
        </CloseModal>

        <h2>Filtro</h2>

        <Form onSubmit={(data) => { }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          <SectionContent>
            <ContainerGroup>
              {/* <div style={{ width: "100%" }}>
                <ContainerAgendamento>
                  <InputCalendar
                    name="date"
                    defaultDateSelected={
                      dateSelected.length ? dateSelected : []
                    }
                    handleDateChange={(event) => {
                      if (!dateSelected.length || dateSelected.length === 2) {
                        setDateSelected(event);
                        return;
                      }

                      setDateSelected(
                        [...dateSelected, event[0]]
                          .sort((a, b) => a.getDate() - b.getDate())
                          .sort((a, b) => a.getMonth() - b.getMonth())
                      );
                    }}
                  />
                </ContainerAgendamento>
              </div> */}

              <div style={{ width: "100%" }}>
                <p>Estado</p>
                <Select
                  name="estado"
                  placeholder="Selecione o estado"
                  options={ufs.map((uf) => ({
                    value: uf.sigla,
                    label: uf.descricao,
                  }))}
                  onChange={(event: any) => {
                    loadCidades(event?.value);
                    setUfSelected(event?.value);
                  }}
                  newValue={{
                    label:
                      ufs.find((item) => item.sigla === ufSelected)
                        ?.descricao || "Selecione o estado",
                    value: ufSelected ? ufSelected : "",
                  }}
                />
              </div>
            </ContainerGroup>
          </SectionContent>

          <SectionContent>
            <ContainerGroup>
              <div style={{ width: "100%" }}>
                <p>Cidade</p>
                <Select
                  name="cidade"
                  placeholder="Selecione a cidade"
                  options={cidades.map((cidade) => ({
                    value: cidade.nome,
                    label: cidade.descricao,
                  }))}
                  onChange={(event: any) => {
                    loadBairros(event?.value);
                    setCidadeSelected(event?.value);
                  }}
                  newValue={{
                    label:
                      cidades.find((item) => item.nome === cidadeSelected)
                        ?.descricao || "Selecione a cidade",
                    value: cidadeSelected ? cidadeSelected : "",
                  }}
                />
              </div>

              <div style={{ width: "100%" }}>
                <p>Bairro</p>
                <Select
                  name="bairro"
                  placeholder="Selecione o bairro"
                  options={bairros.map((bairro) => ({
                    value: bairro,
                    label: bairro,
                  }))}
                  onChange={(event: any) => {
                    setBairroSelected(event?.value);
                  }}
                  newValue={{
                    label:
                      bairros.find((item) => item === bairroSelected) ||
                      "Selecione a bairro",
                    value: bairroSelected ? bairroSelected : "",
                  }}
                />
              </div>
            </ContainerGroup>
          </SectionContent>

          <SectionContent>
            <ContainerGroup>
              <div style={{ width: "100%" }}>
                <p>Horário</p>
                <Select
                  name="horario"
                  placeholder="Selecione o horário"
                  options={[
                    {
                      value: "todos",
                      label: "Todos os horários",
                    },
                    ...obterHorariosPorPeriodo(),
                  ]}
                  onChange={(event: any) => {
                    setHorarioSelected(event?.label);
                  }}
                  newValue={{
                    label:
                      [
                        {
                          value: "todos",
                          label: "Todos os horários",
                        },
                        ...obterHorariosPorPeriodo(),
                      ].find((item) => item.label === horarioSelected)?.label ||
                      "Selecione o horário",
                    value: horarioSelected ? horarioSelected : "",
                  }}
                />
              </div>

              <div style={{ width: "100%" }}>
                <p>Serviços </p>
                <Select
                  name="servicos"
                  placeholder="Selecione o serviços"
                  options={servicos.map((service) => ({
                    value: service.id,
                    label: service.servico,
                  }))}
                  onChange={(event: any) => setServicoSelected(event?.value)}
                  newValue={{
                    label:
                      servicos.find((item) => item.id === servicoSelected)
                        ?.servico || "Selecione o serviços",
                    value: servicoSelected ? servicoSelected : "",
                  }}
                />
              </div>
            </ContainerGroup>
          </SectionContent>

          <ContainerButton>
            <Button onClick={onRequestClose} inverted>
              Cancelar
            </Button>
            <Button
              type="button"
              onClick={() =>
                onContinue(
                  ufSelected,
                  cidadeSelected,
                  bairroSelected,
                  horarioSelected,
                  servicoSelected
                )
              }
            >
              Continuar
            </Button>
          </ContainerButton>
        </Form>
      </Container>
    </ModalBase>
  );
}
