import axiosConfig from '../config/axiosConfig'
import axios from 'axios'
import { toast } from 'react-toastify'

export const partnerLoginService = {

    performLogin: async userInfo => {
        const { userEmail, userPassword } = userInfo

        const req = await axios.post(
            '/partnerLogin/PerformLogin',
            {
                email: userEmail,
                password: userPassword
            },
            axiosConfig
        )

        return req.data
    },

    acquirePartnerId: async (email) => {
        const req = await axios.get(`/partnerLogin/AcquirePartnerId/${email}`)

        const { data, status } = req

        if (status === 200) {
            return data
        } else {
            return -1
        }
    },

    registerOptInAgreement: async partnerId => {
        const req = await axios.put(`/ParceiroData/parceiro/${partnerId}/AtualizarAceite?aceite=true`)

        const { data, status } = req

        if (status === 204) {
            return data
        } else {
            toast.error(data.message)
            return null
        }
    }
}
