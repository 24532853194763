import { useRef, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { ActualFileObject, FilePondFile } from "filepond";
import Swal from "sweetalert2";
import FilePondLocaleBR from "filepond/locale/pt-br";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import jschardet from 'jschardet';

import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileEncode);

interface HttpResponse {
  id: number;
  status: boolean;
  responseStatusCode: number;
  message: string;
}

interface Props {
  idParceria: number;
}

export function CadastroParceriaAbaImportarClientes({ idParceria }: Props) {
  const pond = useRef<FilePond>(null);
  const [files, setFiles] = useState<ActualFileObject[] | []>([]);

  function onErrorResponse(error: string) {
    const resultSet: HttpResponse = JSON.parse(error);
              
    if (resultSet.responseStatusCode === 400) {
      Swal.fire({
        title: resultSet.message,
        icon: "error",
        confirmButtonColor: "#0054A6",
        confirmButtonText: "Entendi",
      });
    }

    if (resultSet.responseStatusCode === 500 || resultSet.responseStatusCode === 0) {
        Swal.fire({
          title: "Houve um erro na importação do arquivo",
          icon: "error",
          confirmButtonColor: "#0054A6",
          confirmButtonText: "Entendi",
        });
    }
  }

  function onSuccessResponse(response: string) {
    const resultSet: HttpResponse = JSON.parse(response);

    if (resultSet.responseStatusCode === 200) {
      Swal.fire({
        title: resultSet.message,
        icon: "success",
        confirmButtonColor: "#0054A6",
        confirmButtonText: "Entendi",
      });
    }
  }

  function handleUpdateFiles(files: FilePondFile[]) {
    try {
      let encodingFile;
      let base64String = ''; 
      
      if (files.length > 0) base64String = files[0].getFileEncodeBase64String();
      
      if (base64String) {
        const fileUint8Array = Buffer.from(base64String, 'base64');
        const fileBuffer = Buffer.from(fileUint8Array);

        encodingFile = jschardet.detect(fileBuffer);
        
        if (encodingFile?.encoding !== "UTF-8" && encodingFile?.encoding !== "UTF8") {
          Swal.fire({
            title: "Formato do arquivo não suportado. Utilize a opção de exportar como CSV UTF-8.",
            icon: "error",
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Entendi",
          });

          pond.current?.removeFiles({ revert: true });
          return [];
        }

        setFiles(files.map(item => {
          return item.file
        }));
      }
    } catch (err) {
      Swal.fire({
        title: "Erro ao tentar identificar o tipo do arquivo. Entre em contato com o suporte.",
        icon: "error",
        confirmButtonColor: "#0054A6",
        confirmButtonText: "Entendi",
      });

      pond.current?.removeFiles({ revert: true });
      return [];
    }
  }

  return (
    <div className="p-field p-col-12 p-d-flex p-flex-column">
      <FilePond 
        allowFileTypeValidation={true}
        acceptedFileTypes={[
          'text/csv',
          'application/vnd.ms-excel',
          'text/x-csv',
        ]}
        allowFileEncode={true}
        files={files}
        credits={false}
        instantUpload={false}
        name="files"
        ref={pond}
        server={{
          process: {
            url: `/ClienteData/Importar/${idParceria}`,
            method: "POST",
            onerror: (error: string) => onErrorResponse(error),
            onload: (response: string) => onSuccessResponse(response)
          }
        }}
        onupdatefiles={handleUpdateFiles}
        {...FilePondLocaleBR}
        labelFileTypeNotAllowed="Tipo de arquivo não suportado. É permitido apenas arquivos CSV."
        fileValidateTypeLabelExpectedTypes="Corrija o arquivo e tente importar novamente."
      />
    </div>
  )
}