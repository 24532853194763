import styled from "styled-components";

export const TextArea = styled.textarea`
  padding: 10px;
  width: 100%;
  max-width: 100%;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
  }
`;