import styled from "styled-components";
import colors from "../../tokens/colors";

export const Container = styled.div`
  display: flex;
`;

export const BannerContainer = styled.div`
  img {
    height: 100vh;
    width: 62vw;
    top: 50%;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 0 9px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 40px;

  h1 {
    font-style: italic;
    font-weight: bold;
    font-size: 48px;
    color: ${colors.blue};
    margin-bottom: 0;
  }

  span {
    color: ${colors.blue};
    font-weight: 500;
  }
`;

export const SubTitle = styled.div`
  text-align: center;
  margin-bottom: 17px;

  p {
    margin-bottom: 0;
  }
`;

export const FormContent = styled.div`
  width: 100%;
  max-width: 304px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-family: "Source Sans Pro", sans-serif;

  label {
    margin-bottom: 4px;
  }
`;

export const InputContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  & + label {
    margin-top: 19px;
  }

  input {
    margin: 0;
    padding: 7px 60px 7px 15px;
    border: 1px solid ${colors.gray.blueLight};
    border-radius: 4px;

    &::placeholder {
      color: ${colors.gray.blue};
    }
  }

  svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }
  }
`;

export const VersionApp = styled.div`
  p {
    font-family: "Source Sans Pro", sans-serif;
    position: absolute;
    bottom: 0;
    right: 12%;
    font-size: 14px;
    color: ${colors.gray.text};
  }
`;

export const ContainerLink = styled.div`
  margin: 7px 0 27px;
  text-align: center;

  a {
    color: ${colors.blue};
    font-weight: 600;
    font-size: 14px;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 232px;
    border: none;
    border-radius: 4px;
    background: ${colors.blue};
    color: ${colors.white};
    padding: 12px 32px;
    transition: filter 0.2s;

    &.secondaryBtn{
      width: 232px;
      border: none;
      border-radius: 4px;
      background: ${colors.gray.medium};
      color: ${colors.gray.default};
      padding: 12px 32px;
      transition: filter 0.2s;
    }

    &:hover {
      filter: brightness(1.1);
    }
  }
`;
