import "./linkServices.scss";
import { useEffect, useState } from "react";
import { usePartnerContext } from "contexts/partnerContextProvider";
import { usePartnerAuth } from "hooks/partnerAuth";
import { partnerLoginService } from "services/partnerLoginService";
import PageContainer from "components/PartnerComponents/PartnerPageContainer/pageContainer";
import Sidebar from "components/PartnerComponents/ManageService/SideBar/sidebar";
import { partnerService } from "services/partnerService";
import StoreLinked from "components/PartnerComponents/ManageService/StoreLinkedGrid/storeLinked";

interface Store {
  bairro: string;
  idLoja: number;
  cidade: string;
  cnpj: string;
  codigo: number;
  descricaoCidade: string;
  estado: string;
  nome: string;
  siglaEstado: string;
  timeZone: object;
}

export const LinkServices = () => {
  const { setIsLoading, wipeOutPartnerData } = usePartnerContext();
  const { partnerEmail, handlePartnerSignout } = usePartnerAuth();
  const [partnerId, setPartnerId] = useState(0);
  const [store, setStore] = useState<Store[]>([]);

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = () => {
    setIsLoading(true);
    partnerLoginService
      .acquirePartnerId(partnerEmail)
      .then((data) => {
        if (data == null || data < 0) {
          wipeOutPartnerData()
          handlePartnerSignout();
        }

        setPartnerId(data);
        return Promise.resolve(data);
      })
      .then((partnerIdResultPromise: number) => {
        if (partnerIdResultPromise != 0) {
          return partnerService
            .getAllStore(partnerIdResultPromise)
            .then((items) => setStore(items));
        } else setStore([]);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <PageContainer
      sidebar={<Sidebar backToHome={false} partnerId={partnerId}></Sidebar>}
      content={
        <div className="manageServiceContent">
          <div className="manageServiceContent__header">
            <h1>Vincular serviços à loja</h1>
          </div>

          <StoreLinked store={store}></StoreLinked>
        </div>
      }
    />
  );
};
