import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./styles.scss";

import { Steps } from "antd";
const { Step } = Steps;

interface stepsProps {
  index: number;
  label: string;
  disabled?: boolean;
}

const steps = [
  { label: "Email", disabled: true, index: 0 },
  { label: "Dados", disabled: true, index: 1 },
  { label: "Lojas", disabled: true, index: 2 },
  { label: "Serviços", disabled: true, index: 3 },
  { label: "Resumo", disabled: true, index: 4 },
] as stepsProps[];

export const Nav = (props: any) => {
  const [activeIndex, setActiveIndex] = useState(props.stepActive ?? 0);

  useEffect(() => {
    setActiveIndex(props.stepActive);
    steps.map((item) => {
      if (props.stepActive !== 0) {
        if (props.stepActive < item.index) item.disabled = true;
        else delete item.disabled;
      } else item.disabled = true;
    });
    delete steps[props.stepActive].disabled;
  }, [props.state?.form?.email, props.stepActive]);

  const onChange = (current: number) => {
    setActiveIndex(current);
    props.goToStep(current === 0 ? 1 : ++current);
  };

  return (
    <Steps current={activeIndex} onChange={onChange} className="my-steps">
      {steps.map((item) => (
        <Step title={item.label} disabled={item.disabled} key={item.label} />
      ))}
    </Steps>
  );
};
