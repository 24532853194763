import { useStore } from "hooks/store";
import { Container, ContainerUser, ContainerShop } from "./styles";
import userImg from "../../images/icons/icon-user.svg";
import { ChangeEvent } from "react";
import { useAuth } from "hooks/auth";
import SelectStores from "components/SelectStores";
import { useCan } from "hooks/can";

interface LojaProps {
  displayStore?: boolean;
  multSelection?: boolean;
  requestAcess?: boolean;
  onClickRequestAcess?: () => void;
}

export default function DashboardUser({
  displayStore = false,
  multSelection = false,
  requestAcess = false,
  onClickRequestAcess,
}: LojaProps) {
  const { storeList, selectedStores, setSelectedStores } = useStore();
  const { username, cargo, nivelAcesso } = useAuth();
  const { rolesCanRequestAccess } = useCan();

  function handleSelectStore(event: ChangeEvent<HTMLSelectElement>) {
    const selectedStore = storeList.find(
      (store) => store.codigo === parseInt(event.target.value)
    );

    if (!selectedStore) {
      return;
    }

    setSelectedStores([selectedStore]);
  }

  return (
    <Container displayStore={displayStore}>
      <ContainerUser displayStore={displayStore}>
        <img src={userImg} alt="Ícone de usuário" />
        <div>
          <p>{username}</p>
          <span>{cargo}</span>
        </div>
      </ContainerUser>

      {displayStore && (
        <ContainerShop>
          {requestAcess &&
            rolesCanRequestAccess?.includes(nivelAcesso?.trim()) && (
              <span
                style={{
                  position: "absolute",
                  marginLeft: "100px",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontFamily: "Source Sans Pro",
                  color: "#0054A6",
                }}
                onClick={onClickRequestAcess}
              >
                Solicitar acesso
              </span>
            )}

          {multSelection && (
            <SelectStores
              storeList={storeList}
              storesSelected={selectedStores}
              handleSelectStore={setSelectedStores}
            />
          )}

          {!multSelection && (
            <>
              <label>Lojas</label>
              <select
                name="store"
                defaultValue={selectedStores[0]?.codigo}
                onChange={(event) => handleSelectStore(event)}
              >
                {!storeList.length && (
                  <option value={selectedStores[0]?.codigo}>
                    Carregando...
                  </option>
                )}
                {storeList && storeList.length && (
                  <>
                    <option value={0}>Seleciona a loja</option>
                    {storeList.map((storeItem, index) => {
                      return (
                        <option key={index} value={storeItem.codigo}>
                          {storeItem.codigo} - {storeItem.nome}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </>
          )}
        </ContainerShop>
      )}
    </Container>
  );
}
