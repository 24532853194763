import "./loadingScreen.scss";
import AnimatedLogo from "../../../images/AnimatedLogo.svg";
import BarLoader from "react-spinners/BarLoader";
import { usePartnerContext } from "contexts/partnerContextProvider";

export default function LoadingScreen() {
  const { isLoading } = usePartnerContext();

  return (
    <>
      {isLoading && (
        <div className={`loadingScreenOverlay ${isLoading ? "open" : ""}`}>
          <div className="loadingScreenOverlay__barLoader">
            <BarLoader color="#ed1d24" height={22} width={4200} />
          </div>
          <div className="loadingScreenOverlay__animatedLogo">
            <object type="image/svg+xml" data={AnimatedLogo}>
              svg-animation
            </object>
          </div>
        </div>
      )}
    </>
  );
}
