import React, { useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { useAuth } from "hooks/auth";
import { useStore } from "hooks/store";

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const CustomRoute: React.FC<CustomRouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { username, cargo, permissoes, isLoading, handleSession } = useAuth();
  const { setLoad } = useStore();

  useEffect(() => {
    if (!username || !cargo) {
      handleSession();
      if (username) setLoad(true);
    }
  }, [username, cargo]);

  if (isLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        let hasPermission = false;

        permissoes?.forEach((permissao) => {
          permissao.route?.forEach((route) => {
            if (location.pathname.includes(route)) {
              hasPermission = true;
            }
          });
        });

        if (!!username && permissoes.length && !hasPermission) {
          return (
            <Redirect
              to={{
                pathname: "/portaladmin/home",
                state: { from: location },
              }}
            />
          );
        }

        return isPrivate === !!username ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/portaladmin/login" : "/portaladmin/home",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default CustomRoute;
