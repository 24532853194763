import { ButtonHTMLAttributes, ReactNode } from "react";
import { ButtonComponent } from "./styles";

interface Props {
  inverted?: boolean;
  children: ReactNode;
  onClick?: () => void;
  width?: string;
  height?: string;
}

type ButtonProps = ButtonHTMLAttributes<any> & Props;

export default function Button({
  inverted,
  children,
  onClick = () => {},
  width = "132px",
  height = "48px",
  ...rest
}: ButtonProps) {
  return (
    <ButtonComponent
      onClick={onClick}
      inverted={inverted}
      width={width}
      height={height}
      {...rest}
    >
      {children}
    </ButtonComponent>
  );
}
