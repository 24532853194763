import "./partnerTimePickerModal.scss";
import { useState } from "react";
import { Slider, Tooltip } from "devextreme-react/slider";

export default function PartnerTimePickerModal({
  open,
  setOpen,
  setSelectedHour,
  selectedHour,
  modalName,
}) {
  const [chosenTime, setChosenTime] = useState(0);

  const overlayHandler = (e: any) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      setOpen(false);
    }
  };

  const formatHour = (value) => {
    let hour = `${value}`.split(".")[0];
    let minute = "00";

    if (parseInt(hour) < 10) hour = `0${hour}`;

    if (`${value}`.includes(".")) {
      minute = "30";
    }

    return `${hour}:${minute}`;
  };

  const setHourSliderValue = (e) => {
    const { value } = e;
    setChosenTime(value);
  };

  return (
    <>
      {open && (
        <div
          className="timePickerModalOverlay"
          onClick={(e) => overlayHandler(e)}
        >
          <div className="timePickerModal">
            <div className="timePickerModal__title">{modalName}</div>
            <div className="timePickerModal__pickers">
              <div className="timePickerModal__pickers__item">
                <span>Horas</span>
                <Slider
                  min={0}
                  max={23}
                  defaultValue={
                    isNaN(parseInt(selectedHour[0]))
                      ? 0
                      : parseInt(selectedHour[1]) === 30
                      ? parseFloat(`${selectedHour[0]}.${selectedHour[1]}`)
                      : 0
                  }
                  step={0.5}
                  onValueChanged={(e) => setHourSliderValue(e)}
                >
                  <Tooltip
                    enabled={true}
                    showMode="always"
                    position="top"
                    format={formatHour}
                  />
                </Slider>
              </div>

              <div className="timePickerModal__pickers__selectedTime">
                {`${formatHour(chosenTime)}`}
              </div>
            </div>

            <button
              className="closeModalBtn"
              onClick={() => {
                setSelectedHour(`${formatHour(chosenTime)}`);
                setOpen(false);
              }}
            >
              Selecionar
            </button>
          </div>
        </div>
      )}
    </>
  );
}
