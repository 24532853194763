import styled from "styled-components";
import { colors } from "tokens";

export const Container = styled.div`
  min-width: 925px;
  margin-left: 20px;
  margin-right: 50px;

  color: #2a333e;

  p#title {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #2A333E
  }

  p {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: ${colors.gray.text};
  }

  label {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #2A333E;
  }

  span {
    position: absolute;
    margin-top: 20px;
    text-decoration-line: underline;
    cursor: pointer;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    color: #0054a6;
  }

  .lbNomeImg {    
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    color: #404F61;
    font-style: italic;
    float: left;
  }
`;

export const Content = styled.div`
  .paddingRow {
      margin-top: 30px;    
    .div50{
      width: 420px;
      padding-right: 26px;
      float: left;
    }
    .div100{
      width: 100%;
    }
    .div30{
      width: 30%;
      float: left;
    }
    .div60{
      width: 60%;
      float: left;
    }
  }
    .rowClear {
      clear: both;
  }

  .contentRow {
    display: flex;
    justify-content: space-between;

    &.contentRow {
      margin-top: 30px;
    }

    div {
      flex: 1;
      display: flex;
      flex-direction: column;

      p {
        overflow-wrap: anywhere;
        margin: 0;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    &:nth-child(1) {
      div:first-child {
        max-width: 140px;
        margin-right: 24px;
      }
    }

    &:nth-child(2) {
      div:nth-child(1) {
        max-width: 140px;
        margin-right: 24px;
      }

      div:nth-child(2) {
        min-width: 344px;
        margin-right: 45px;
      }
    }

    &:nth-child(3) {
      div:nth-child(1) {
        max-width: 140px;
        margin-right: 24px;
      }

      div:nth-child(2) {
        max-width: 300px;
      }
    }
  }
`;

export const ButtonCancel = styled.button`
  width: 123px;
  height: 48px;
  margin-top: 84px;
  margin-left: 623px;
  margin-bottom: 32px;

  color: #0054a6;
  text-align: center;

  background: #ffffff;
  border: 1px solid #0054a6;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const ButtonSave = styled.button`
  width: 123px;
  height: 48px;
  margin-left: 24px;

  color: #ffffff;
  text-align: center;

  background: #0054a6;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
`;
export const ButtonLojas = styled.button`
  width: 282px;
  height: 48px;

  color: #0054a6;
  text-align: center;

  background: #ffffff;
  border: 1px solid #0054a6;
  box-sizing: border-box;
  border-radius: 4px;
`;
