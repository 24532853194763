import { useHistory } from "react-router-dom";

import { DashboardContainer } from './styles';

import DashboardUser from "components/DashboardUser";
import { Footer } from "components/Footer";
import Header from "components/Header";
import Main from "components/Main";
import { DetailService } from "components/DetailService";
import { useAuth } from "hooks/auth";

export function DetailsService() {
  const history = useHistory();
  const { permissoes } = useAuth();

  function handleCreateNewService() {
    history.push("/portaladmin/register-service");
  }

  return (
    <>
      <Header />
      <Main>
        <DashboardContainer>
          <DashboardUser />
          {permissoes?.filter((item: any) => item.route[2] === "register-service")
            .length > 0 && (
              <button type="button" onClick={handleCreateNewService}>
                Cadastrar novo serviço
              </button>
            )}
        </DashboardContainer>
        <DetailService />
      </Main>
      <Footer />
    </>
  );
}