import axios from "axios";

export const clientService = {
  getMinimumData: async (cpf: string) => {
    const req = await axios.get(`/ClienteData/getdadosminimos/${cpf}`);
    const { data } = req;

    return data;
  }
};
