import "./schedulesGrid.scss";
import DataGrid, {
  Column,
  FilterRow,
  Selection,
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import StatusIcon, { statusIconMap } from "./Icons/StatusIcon/statusIcon";
import ServiceIcon from "./Icons/ServiceIcon/serviceIcon";
import { Button } from "devextreme-react/button";
import { usePartnerContext } from "contexts/partnerContextProvider";
import { partnerDashboardService } from "../../../services/partnerDashboardService";
import Swal from "sweetalert2";
import { PeriodCard } from "../../PeriodCard";
import { format } from "date-fns-tz";
import BlockUnblockPeriodsModal from "../BlockUnblockPeriodsModal/blockUnblockPeriodsModal";
import { useState } from "react";
import { partnerScheduleLockReleaseService } from "../../../services/partnerScheduleLockReleaseService";
import moment from "moment";
import { partnerLoginService } from "../../../services/partnerLoginService";
import { usePartnerAuth } from "../../../hooks/partnerAuth";
import IconRelese from "../../../images/icons/icon-release.svg";

function SchedulesGrid({ dashData, setPeriod, handleData }) {
  const [agendamentos, totalManha, totalTarde, totalNoite, period] = dashData;
  const [blockUnblockModalOpen, setBlockUnblockModalOpen] = useState(false);
  const [performedAction, setPerformedAction] = useState("");
  const {
    setIsLoading,
    startDate,
    endDate,
    selectedStores,
    wipeOutPartnerData,
  } = usePartnerContext();
  const { partnerEmail, handlePartnerSignout } = usePartnerAuth();

  const handleCancelSchedule = async (
    periodId,
    action,
    data,
    hora,
    idAgendamento
  ) => {
    Swal.fire({
      title: "Atenção!",
      html: `Deseja realmente ${
        action === "BLOCK" ? "bloquear" : "desbloquear"
      } o período selecionado?\n${data} ${hora}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0054A6",
      cancelButtonColor: "#ed1d24",
      confirmButtonText: "Prosseguir",
      cancelButtonText: "Abandonar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const reasons = await partnerScheduleLockReleaseService.acquireReasons(
          action
        );

        setIsLoading(false);

        let items = reasons.items.map((reason) => {
          return reason.descricao;
        });

        items = ["OUTRO", ...items];

        const handleText = async () => {
          const { value: text } = await Swal.fire({
            input: "textarea",
            inputLabel: `Motivo ${
              action === "BLOCK" ? "do bloqueio" : "da liberação"
            }`,
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Confirmar",
            inputPlaceholder: "Descreva brevemente o motivo...",
            inputAttributes: {
              "aria-label": "Descreva brevemente o motivo...",
            },
            showCancelButton: false,
            allowOutsideClick: false,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value && value.trim().length) {
                  resolve(null);
                } else {
                  resolve("Por favor, descreva motivo acima");
                }
              });
            },
          });

          return text;
        };

        if (reasons.result === "Success") {
          let text = "";

          const { value: reason } = await Swal.fire({
            title: `Motivo ${
              action === "BLOCK" ? "do bloqueio" : "da liberação"
            }`,
            input: "select",
            inputOptions: Object.assign({}, items),
            inputPlaceholder: "Selecione o motivo",
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: "#0054A6",
            cancelButtonColor: "#ed1d24",
            confirmButtonText: "Prosseguir",
            cancelButtonText: "Abandonar",
            reverseButtons: true,
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value && value.length) {
                  resolve(null);
                } else {
                  resolve("Por favor, selecione o motivo acima");
                }
              });
            },
          });

          if (reason) {
            if (reason === "0") {
              text = await handleText();
            }

            setIsLoading(true);

            const partnerId = await partnerLoginService.acquirePartnerId(
              partnerEmail
            );
            if (partnerId == null || partnerId < 0) {
              wipeOutPartnerData();
              handlePartnerSignout();
            }

            const filters = {
              idParceiro: partnerId,
              idAgenda: idAgendamento,
              lojas: `${selectedStores.join(",")}`,
              dataInicial: `${data} ${hora}`,
              dataFinal: `${data} ${hora}`,
              horaInicial: `${hora}`,
              horaFinal: `${hora}`,
              idMotivo: parseInt(`${reason}`),
              motivo: `${text}`,
            };

            const performBlockUnblock =
              await partnerScheduleLockReleaseService.performBulkBlockUnblock(
                filters,
                action
              );

            setIsLoading(false);

            if (performBlockUnblock.result === "Success") {
              Swal.fire({
                title: `${action === "BLOCK" ? "Bloqueio" : "Liberação"}`,
                text: "O período da agenda foi atualizado com sucesso.",
                icon: "success",
                confirmButtonColor: "#0054A6",
                confirmButtonText: "Entendi",
              }).then(() => {
                handleData();
              });
            } else {
              Swal.fire({
                title: `${action === "BLOCK" ? "Bloqueio" : "Liberação"}`,
                text: performBlockUnblock.message,
                icon: "error",
                confirmButtonColor: "#0054A6",
                confirmButtonText: "Entendi",
              });
            }
          }
        }
      }
    });
  };

  const renderRowActionButtons = (val) => {
    const { servicos: srv, status, data, hora, idAgendamento } = val.data;

    const dateObject = moment(data, "DD/MM/YYYY").toDate();
    const isAfter = moment().isAfter(dateObject);

    const actions = [
      {
        id: 1,
        text: "Bloquear",
        icon: "close",
        disabled: srv.length > 0 || isAfter,
      },
      {
        id: 2,
        text: "Desbloquear",
        icon: "check",
        disabled: srv.length > 1 || status === 1 || isAfter,
      },
    ];

    const handleOptionClick = (e) => {
      switch (e.itemData.id) {
        case 1: {
          handleCancelSchedule(-1, "BLOCK", data, hora, idAgendamento);
          break;
        }

        case 2: {
          handleCancelSchedule(-1, "UNBLOCK", data, hora, idAgendamento);
          break;
        }
      }
    };

    return (
      <DropDownButton
        disabled={isAfter}
        text="Ações"
        icon="preferences"
        items={actions}
        style={{ width: "100%" }}
        keyExpr="id"
        displayExpr="text"
        onItemClick={(e) => {
          handleOptionClick(e);
        }}
      />
    );
  };

  const renderStatusCell = (data) => {
    return (
      <div className="schedulesGridCell">
        <StatusIcon data={data.data} />
      </div>
    );
  };

  const renderStoreCell = (data: any, exporting: boolean) => {
    if (!exporting) {
      return <div className="schedulesGridCell">{data.data.loja}</div>;
    }

    return `${data.data.loja} / ${data.data.nomeSala}`;
  };

  const renderServicesCell = (data) => {
    const { servicos: srv, cliente } = data.data;

    return (
      <>
        {srv.length > 0 && (
          <div className="schedulesGridCell">
            <ServiceIcon services={srv} clientName={cliente.nome} />
          </div>
        )}
        {srv.length < 1 && (
          <div className="schedulesGridCell">
            <span>Nenhum serviço agendado</span>
          </div>
        )}
      </>
    );
  };

  const renderGridCell = (data) => {
    const { value } = data;
    return <div className="schedulesGridCell">{value}</div>;
  };

  const renderColumnHeader = (title) => {
    return (
      <strong style={{ color: "#444", textTransform: "uppercase" }}>
        {title}
      </strong>
    );
  };

  const handleBulkAction = (type) => {
    switch (type) {
      case "BLOCK": {
        setPerformedAction(type);
        setBlockUnblockModalOpen(true);
        break;
      }

      case "UNBLOCK": {
        setPerformedAction(type);
        setBlockUnblockModalOpen(true);
        break;
      }
    }
  };

  return (
    <>
      <div className="header">
        <p>BLOQUEIO E LIBERAÇÃO DE AGENDA</p>
        {format(startDate, "dd/MM/yyyy") === format(endDate, "dd/MM/yyyy") ? (
          <span>
            Quantidade de cada serviço do dia {format(startDate, "dd/MM/yyyy")}
          </span>
        ) : (
          <span>
            Quantidade de cada serviço de {format(startDate, "dd/MM/yyyy")} à{" "}
            {format(endDate, "dd/MM/yyyy")}
          </span>
        )}
      </div>
      <>
        {/*<div className="periodSelection">
                        <div
                            className={`periodSelection__button ${period === 'manha' ? 'selected' : ''}`}
                            onClick={() => {
                                setPeriod('manha')
                                handleData("manha")
                            }}
                        >
                            <div className="periodSelection__button__title">
                                Manhã
                            </div>
                            <div className="periodSelection__button__interval">
                                06:00 às 11:59
                            </div>
                            <div className="periodSelection__button__count">
                                {totalManha > 0 ? `${totalManha} agendamentos marcados` : "Nenhum agendamento"}
                            </div>
                        </div>

                        <div
                            className={`periodSelection__button ${period === 'tarde' ? 'selected' : ''}`}
                            onClick={() => {
                                setPeriod('tarde')
                                handleData('tarde')
                            }}
                        >
                            <div className="periodSelection__button__title">
                                Tarde
                            </div>
                            <div className="periodSelection__button__interval">
                                12:00 às 17:59
                            </div>
                            <div className="periodSelection__button__count">
                                {totalTarde > 0 ? `${totalTarde} agendamentos marcados` : "Nenhum agendamento"}
                            </div>
                        </div>

                        <div
                            className={`periodSelection__button ${period === 'noite' ? 'selected' : ''}`}
                            onClick={() => {
                                setPeriod('noite')
                                handleData('noite')
                            }}
                        >
                            <div className="periodSelection__button__title">
                                Noite
                            </div>
                            <div className="periodSelection__button__interval">
                                18:00 às 05:59
                            </div>
                            <div className="periodSelection__button__count">
                                {totalNoite > 0 ? `${totalNoite} agendamentos marcados` : "Nenhum agendamento"}
                            </div>
                        </div>
                    </div>*/}
        <div className="schedulesGrid" id="schedulesGrid">
          <div className="schedulesGrid__exportButtons">
            <div className="schedulesGrid__exportButtons__legendAndBtns">
              <span>Bloqueie ou desbloqueie agendas em massa</span>
              <div className="schedulesGrid__exportButtons__legendAndBtns__buttons">
                <button onClick={() => handleBulkAction("BLOCK")}>
                  <svg
                    width="16.67"
                    height="16.67"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "5px" }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.00001 0.666687C4.40001 0.666687 0.666672 4.40002 0.666672 9.00002C0.666672 13.6 4.40001 17.3334 9.00001 17.3334C13.6 17.3334 17.3333 13.6 17.3333 9.00002C17.3333 4.40002 13.6 0.666687 9.00001 0.666687ZM2.33334 9.00002C2.33334 5.31669 5.31667 2.33335 9.00001 2.33335C10.5417 2.33335 11.9583 2.85835 13.0833 3.74169L3.74167 13.0834C2.85834 11.9584 2.33334 10.5417 2.33334 9.00002ZM4.91667 14.2584C6.04167 15.1417 7.45834 15.6667 9 15.6667C12.6833 15.6667 15.6667 12.6834 15.6667 9.00002C15.6667 7.45835 15.1417 6.04169 14.2583 4.91669L4.91667 14.2584Z"
                      fill="#F14A50"
                    />
                  </svg>
                  Bloquear períodos
                </button>
                &nbsp; &nbsp;
                <button onClick={() => handleBulkAction("UNBLOCK")}>
                  <img
                    src={IconRelese}
                    width="18.62"
                    height="15.12"
                    alt="block"
                  />
                  Desbloquear períodos
                </button>
              </div>
            </div>
          </div>

          {agendamentos.length > 0 && (
            <DataGrid
              showColumnLines={false}
              showRowLines={true}
              id="dataGrid"
              dataSource={agendamentos}
              keyExpr="idAgendamento"
              allowColumnResizing={false}
              columnAutoWidth={true}
              onExporting={handleBulkAction}
            >
              <Column
                width="auto"
                name="Status"
                allowExporting={true}
                allowFiltering={false}
                allowSorting={false}
                dataField="status"
                caption="Status"
                headerCellRender={() => renderColumnHeader("Status")}
                cellRender={(data) => renderStatusCell(data)}
              />
              <Column
                allowSorting={false}
                cellRender={(data) => renderGridCell(data)}
                headerCellRender={() => renderColumnHeader("Data")}
                caption="Data"
                dataField="data"
                width={100}
              />
              <Column
                allowSorting={false}
                cellRender={(data) => renderGridCell(data)}
                headerCellRender={() => renderColumnHeader("Hora")}
                caption="Hora"
                dataField="hora"
                width={100}
              />
              <Column
                width="auto"
                allowSorting={false}
                name="Loja"
                caption="Loja"
                headerCellRender={() => renderColumnHeader("Loja")}
                cellRender={(data) => renderStoreCell(data, false)}
              />
              <Column
                name="Serviços"
                allowSorting={false}
                allowExporting={true}
                cellRender={(data) => renderServicesCell(data)}
                headerCellRender={() => renderColumnHeader("Serviços")}
                caption="Serviços"
              />
              <Column
                allowExporting={false}
                width={150}
                headerCellRender={() => renderColumnHeader("Ações")}
                allowSorting={false}
                allowSearch={false}
                cellRender={renderRowActionButtons}
              />
              <FilterRow visible={true} />
              <Selection mode="none" />
            </DataGrid>
          )}
        </div>
      </>

      <BlockUnblockPeriodsModal
        open={blockUnblockModalOpen}
        setOpen={setBlockUnblockModalOpen}
        action={performedAction}
        handleData={handleData}
      />
    </>
  );
}

export default SchedulesGrid;
