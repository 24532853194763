import { Container, Content, ButtonCancel, ButtonSave, ButtonLojas } from "./styles";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import IconService1 from "../../assets/IconService1.svg";
import IconService2 from "../../assets/IconService2.svg";
import IconService3 from "../../assets/IconService3.svg";
import IconService4 from "../../assets/IconService4.svg";
import IconService5 from "../../assets/IconService5.svg";
import IconService6 from "../../assets/IconService6.svg";
import IconService7 from "../../assets/IconService7.svg";
import IconService8 from "../../assets/IconService8.svg";
import IconService9 from "../../assets/IconService9.svg";
import IconService10 from "../../assets/IconService10.svg";
import IconService11 from "../../assets/IconService11.svg";
import IconService12 from "../../assets/IconService12.svg";
import IconService13 from "../../assets/IconService13.svg";
import IconService14 from "../../assets/IconService14.svg";
import IconService15 from "../../assets/IconService15.svg";
import IconService16 from "../../assets/IconService16.svg";
import IconService17 from "../../assets/IconService17.svg";
import IconService18 from "../../assets/IconService18.svg";

import axios from "axios";
import { StoreLinkServiceModal } from "components/ModalGroup/StoreLinkServiceModal";
import { useAuth } from "hooks/auth";
import { useLoader } from "hooks/loader";

export function DetailService() {
  const history = useHistory();
  const { permissoes } = useAuth();
  const { handleSetIsLoader } = useLoader();

  const icons = [
    { id: 1, icon: IconService1 },
    { id: 2, icon: IconService2 },
    { id: 3, icon: IconService3 },
    { id: 4, icon: IconService4 },
    { id: 5, icon: IconService5 },
    { id: 6, icon: IconService6 },
    { id: 7, icon: IconService7 },
    { id: 8, icon: IconService8 },
    { id: 9, icon: IconService9 },
    { id: 10, icon: IconService10 },
    { id: 11, icon: IconService11 },
    { id: 12, icon: IconService12 },
    { id: 13, icon: IconService13 },
    { id: 14, icon: IconService14 },
    { id: 15, icon: IconService15 },
    { id: 16, icon: IconService16 },
    { id: 17, icon: IconService17 },
    { id: 18, icon: IconService18 },
  ];

  const [idServico, setIdServico] = useState(0);
  const [icone, setIcone] = useState(0);
  const [codProduto, setCodProduto] = useState("");
  const [nome, setNome] = useState("");
  const [nomeAbreviado, setNomeAbreviado] = useState("");
  const [horaExecucao, setHoraExecucao] = useState("");
  const [resultado, setResultado] = useState("");
  const [preparo, setPreparo] = useState("");
  const [preRequisito, setPrerequisito] = useState("");
  const [descricao, setDescricao] = useState("");
  const [recomendacao, setRecomendacao] = useState("");
  const [status, setStatus] = useState("");
  const [coleta, setColeta] = useState("");
  const [subTitulo, setSubTitulo] = useState("");
  const [categorias, setCategorias] = useState("");
  const [subCategorias, setSubCategorias] = useState("");
  const [urlImg, setUrlImg] = useState("");
  const [nomeImg, setNomeImg] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isModalStoresOpen, setIsModalStoresOpen] = useState(false);

  const searchParams = new URLSearchParams(history.location.search);
  const _id = searchParams.get("id");

  useEffect(() => {
    handleSetIsLoader(true);
    setIsLoading(true);
    if (_id !== null) setIdServico(parseInt(_id));

    axios.get(`/servicoData/${_id}`).then((response: any) => {
      if (response.data.result === "Success") {
        setCodProduto(response.data.servico.codProduto);
        setNome(response.data.servico.nome);
        setNomeAbreviado(response.data.servico.nomeAbreviado);
        setHoraExecucao(response.data.servico.horaExecucao);
        setResultado(response.data.servico.resultado);
        setPreparo(response.data.servico.preparo);
        setPrerequisito(response.data.servico.preRequisito);
        setDescricao(response.data.servico.descricao);
        setRecomendacao(response.data.servico.restricao);
        setColeta(response.data.servico.coleta);
        setSubTitulo(response.data.servico.subTitulo);
        setStatus(response.data.servico.ativo ? "Ativo" : "Desativado");

        setColeta(response.data.servico.coletaMaterial);
        setResultado(response.data.servico.tempoResultado);
        setUrlImg(response.data.servico.urlImg);
        setNomeImg(response.data.servico.nomeImg);
        setCategorias(response.data.servico._categorias.map(function (elem: any) { return elem.nome; }).join("; "));
        setSubCategorias(response.data.servico._subcategorias.map(function (elem: any) { return elem.nome; }).join("; "));

        console.log(response.data.servico)
        if (response.data.servico.iconeId !== null)
          setIcone(response.data.servico.iconeId);
      }
      handleSetIsLoader(false);
      setIsLoading(false);
    });
  }, []);

  function handleService() {
    history.push("/portaladmin/servico");
  }

  function getIcon() {
    var _icone = icons.filter(function (x: any) {
      return x.id == icone;
    });
    if (_icone.length > 0) return _icone[0].icon;
  }

  function actionOpenModal() {
    setIsModalStoresOpen(true);
  }

  const tempoExecucaoFormatado = (tempoExecucao: string) => {
    const splitTempoExecucao = tempoExecucao.split(":");
    const newDate = new Date();

    const horas = splitTempoExecucao[0];
    const minutos = splitTempoExecucao[1];

    newDate.setHours(Number(horas));
    newDate.setMinutes(Number(minutos));

    if (horas !== "00") {
      return `${horas} hora(s)`;
    }

    return `${minutos} minutos`;
  };

  return (
    <Container>
      {isLoading && <p>Carregando...</p>}
      {!isLoading && (
        <>
          <p id="title">DETALHES DO SERVIÇO</p>
          <Content>
            <div className="contentRow">
              <div>
                <label>Código do produto</label>
                <p>{codProduto}</p>
              </div>

              <div>
                <label>Nome do serviço</label>
                <p>{nome}</p>
              </div>
            </div>

            <div className="contentRow">
              <div>
                <label>Icone</label>
                {icone !== 0 && <img src={getIcon()} alt="icon" />}
              </div>

              <div>
                <label>Abreviação</label>
                <p>{nomeAbreviado}</p>
              </div>

              <div>
                <label>Subtítulo</label>
                <p>{subTitulo}</p>
              </div>
            </div>

            <div className="paddingRow">
              <div className="div50">
                <label>Categoria</label>
                <p>{categorias}</p>
              </div>
              <div className="div50">
                <label>Sub Categoria</label>
                <p>{subCategorias}</p>
              </div>
            </div>
            <div className="rowClear">
            </div>

            <div className="paddingRow">
              <div className="div50">
                <label>Descrição</label>
                <p>{descricao}</p>
              </div>
              <div className="div50">
                <label>Tempo de execução</label>
                <p>{tempoExecucaoFormatado(horaExecucao)}</p>
              </div>
            </div>
            <div className="rowClear">
            </div>


            <div className="paddingRow">
              <div className="div50">
                <label>Resultado</label>
                <p>{resultado}</p>
              </div>
              <div className="div50">
                <label>Preparo</label>
                <p>{preparo}</p>
              </div>
            </div>
            <div className="rowClear">
            </div>

            <div className="paddingRow">
              <div className="div50">
                <label>Coleta</label>
                <p>{coleta}</p>
              </div>
              <div className="div50">
                <label>Recomendações</label>
                <p>{recomendacao}</p>
              </div>
            </div>
            <div className="rowClear">
            </div>

            <div className="paddingRow">
              <div className="div100">
                <label>Pré requisito</label>
                <p>{preRequisito}</p>
              </div>
            </div>
            <div className="rowClear">
            </div>

            <div className="paddingRow">
              <div className="div60">
                <ButtonLojas onClick={() => actionOpenModal()}>Ver lojas vinculadas ao serviço</ButtonLojas>
              </div>
              <div className="div30">
                <label>Status</label>
                <p>{status}</p>
              </div>
            </div>
            <div className="rowClear">
            </div>

            <div className="paddingRow">
              <div className="div100">
                <img src={urlImg} style={{ width: '300px' }} />
                <div className="rowClear">
                </div>
                <label className="lbNomeImg">{nomeImg}</label>
              </div>
            </div>
            <div className="rowClear">
            </div>

            <ButtonCancel onClick={handleService}>Voltar</ButtonCancel>
            {permissoes?.filter((item: any) => item.route[2] === "register-service")
              .length > 0 && (
                <ButtonSave
                  onClick={() =>
                    history.push(`/portaladmin/register-service/?id=${_id}`)
                  }
                >
                  Editar
                </ButtonSave>
              )}
          </Content>

          <StoreLinkServiceModal
            isOpen={isModalStoresOpen}
            onRequestClose={() => setIsModalStoresOpen(false)}
            idServico={idServico}
          />
        </>
      )}
    </Container>
  );
}
