import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Form } from "@unform/web";
import axios from "axios";
import { useLoader } from "hooks/loader";

import SelectDropdown from "components/SelectDropdown";
import { Quantity } from "components/Quantity";
import Button from "components/Button";
import { Grid } from "components/Grid";
import { SimpleModal } from "components/ModalGroup/SimpleModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button as ButtonPrime } from "primereact/button";

import * as S from "./styles";

import successImg from "images/arrowSuccess.png";
import unlinkImg from "images/icons/icon-trash.svg";
import warningImg from "images/arrowWarning.png";
import loadingImg from "images/icons/loading-register.svg";
import errorImg from "images/iconError.png";

import { NoResults } from "components/NoResults";
import { colors } from "tokens";
import { classNames } from "primereact/utils";

import { RenovacaoBeneficio } from "components/Quantity";

interface ServicoData {
  id: number;
  codProduto: string;
  nomeAbreviado: string;
  status: number;
}

interface RenovacaoData {
  value: number;
  label: string;
}

interface DropdownSelectedItem {
  value: number;
  label: string;
}

interface Log {
  data: string;
  codigoColaborador: string;
  colaborador: string;
}

interface HistoricoEdicoesData {
  editadoEm: string;
  editadoPor: string;
  matriculaEditor: string;
}

interface Autenticacao {
  id?: number; 
  idParceria?: number;
  url?: string;
  tipoAutenticacao: string;
  token?: string;
  headerRecebeToken?: string;
  metodo?: string;
  usuario?: string;
  senha?: string;
  propriedadeRetornaToken?: string;
  body?: string;
  myHeaders?: MyHeaderProps[];
}

interface CadastroParceriaAbaParceriaFormData {
  id: number;
  nome: string;
  vigenciaInicio: string;
  vigenciaFim: string;
  tipo: number;
  idTipoParceria: number;
  urlBaseExterna: string;
  ativo: boolean;
  beneficios: BeneficiosVinculados[];
  historicoEdicoes: HistoricoEdicoesData[];
  ultimaAlteracao?: Log;
  utilizarMascara?: boolean;
  landingPageCadastro: boolean;
  exibirPainel: boolean;
  myHeaders?: MyHeaderProps[];
  logo?: string;
  logoExtensao?: string;
  autenticacao?: Autenticacao;
}

interface BeneficiosVinculados {
  id?: number;
  key?: number;
  idParceria?: number;
  quantidade: number | null;
  idServico: number;
  codigoServico: string;
  nomeServico: string;
  renovacao: number;
  nomeRenovacao?: string;
  periodicidade?: object;
}

interface BeneficiosResponse {
  id: number;
  quantidade: number;
  renovacao: number;
  nomeRenovacao: string;
  servico: {
    id: number;
    nome: string;
    codProduto: string;
  };
  periodicidade: {
    id: number;
    nome: string;
  };
}

interface CadastroParceriaAbaBeneficiosFormData {
  idServico: number;
  quantidade: number;
  renovacao: number;
  nomeRenovacao: string;
}

interface CadastroParceriaAbaBeneficiosProps {
  formDataParceriaParams: CadastroParceriaAbaParceriaFormData;
  formDataBeneficios: CadastroParceriaAbaBeneficiosFormData;
  showButtons?: boolean;
  onChangeFormData: (data: CadastroParceriaAbaBeneficiosFormData) => void;
  onLinkBenefit?: (data: CadastroParceriaAbaParceriaFormData) => void;
}

interface MyHeaderProps {
  index?: number;
  chave: string;
  valor: string;
}

interface KeyValuePair {
  Key: string;
  Value: string;
}

export function CadastroParceriaAbaBeneficios({
  formDataParceriaParams,
  formDataBeneficios,
  showButtons = true,
  onChangeFormData,
  onLinkBenefit,
}: CadastroParceriaAbaBeneficiosProps) {
  const { handleSetIsLoader } = useLoader();
  const history = useHistory();

  const [renovacao, setRenovacao] = useState<RenovacaoData[]>([]);

  const [formDataParceria, setFormDataParceria] =
    useState<CadastroParceriaAbaParceriaFormData>(formDataParceriaParams);

  const [servicos, setServicos] = useState<ServicoData[]>([]);
  const [loading, setLoading] = useState(true);
  const [servicoSelecionado, setServicoSelecionado] =
    useState<DropdownSelectedItem>({} as DropdownSelectedItem);
  const [renovacaoSelecionada, setRenovacaoSelecionada] = useState(
    formDataBeneficios.renovacao >= 0
      ? {
        value: formDataBeneficios.renovacao,
        label: formDataBeneficios.nomeRenovacao || "INDEFINIDA",
      }
      : ({} as DropdownSelectedItem)
  );

  const [currentQuantity, setCurrentQuantity] = useState(
    formDataBeneficios.quantidade ? formDataBeneficios.quantidade : 1
  );
  const [beneficiosVinculados, setBeneficiosVinculados] = useState<
    BeneficiosVinculados[]
  >(
    formDataParceriaParams.beneficios.length
      ? formDataParceriaParams.beneficios
      : []
  );

  const [beneficioDesvincular, setBeneficioDesvincular] = useState<{
    key: number;
    id?: number;
  }>({ key: 0, id: 0 });

  const [isModalServiceLinkSuccessOpen, setIsModalServiceLinkSuccessOpen] =
    useState(false);
  const [isModalWarningParceriaOpen, setIsModalWarningParceriaOpen] =
    useState(false);
  const [isModalConfirmServiceUnlinkOpen, setIsModalConfirmServiceUnlinkOpen] =
    useState(false);
  const [isModalServiceUnlinkSuccessOpen, setIsModalServiceUnlinkSuccessOpen] =
    useState(false);

  const [
    isModalLoadingPartnershipRegisterOpen,
    setIsModalLoadingPartnershipRegisterOpen,
  ] = useState(false);

  const [
    isModalConfirmRegisterPartnershipOpen,
    setIsModalConfirmRegisterPartnershipOpen,
  ] = useState(false);
  const [
    isModalRegisterPartnershipSuccessOpen,
    setIsModalRegisterPartnershipSuccessOpen,
  ] = useState(false);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        handleSetIsLoader(true);
        const response = await axios.get(`/servicoData/`);
        const periodicidades = await axios.get(
          `/beneficioData/GetPeriodicidades`
        );

        if (formDataParceriaParams.id) {
          handleSetIsLoader(true);
          const responseBeneficios = await axios.get(
            `/beneficioData/GetAllBeneficio/${formDataParceriaParams.id}`
          );
          setBeneficiosVinculados(
            responseBeneficios.data.items.map(
              (item: BeneficiosResponse, index: number) => {
                const ben = {
                  id: item.id,
                  key: index + 1,
                  idParceria: formDataParceriaParams.id,
                  idServico: item.servico.id,
                  codigoServico: item.servico.codProduto,
                  nomeServico: item.servico.nome,
                  renovacao: item.renovacao,
                  nomeRenovacao: item.periodicidade.nome || "INDEFINIDA",
                  quantidade: item.quantidade, 
                };

                return ben;
              }
            )
          );
        }

        if (response.data.result === "Success") {
          setServicos(response.data.items);
        }

        if (periodicidades.data.result === "Success") {
          const { data } = periodicidades;

          setRenovacao(
            data.items.map((r) => {
              return {
                value: parseInt(r.id),
                label: r.nome,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        handleSetIsLoader(false);
      }
    })();
  }, []);

  useEffect(() => {
    onChangeFormData({
      idServico: servicoSelecionado.value,
      quantidade: currentQuantity,
      renovacao: renovacaoSelecionada.value,
      nomeRenovacao: renovacaoSelecionada.label,
    });
  }, [servicoSelecionado, currentQuantity, renovacaoSelecionada]);

  useEffect(() => {
    setServicoSelecionado(
      formDataBeneficios.idServico
        ? {
          value: formDataBeneficios.idServico,
          label:
            servicos
              .map((servico) => ({
                value: servico.id,
                label: `${servico.codProduto} - ${servico.nomeAbreviado}`,
              }))
              .find(
                (servico) => servico.value === formDataBeneficios.idServico
              )?.label || "",
        }
        : ({} as DropdownSelectedItem)
    );
  }, [servicos]);

  const handleGoBack = () => history.goBack();

  async function handleLinkService(data: BeneficiosVinculados) {
    try {
      handleSetIsLoader(true);
      const servicoSelecionado = servicos.find(
        (servico) => servico.id === data.idServico
      );

      if (!servicoSelecionado) {
        return;
      }

      let _renovacao = renovacao?.find(
        (renovacao) => renovacao.value === data.renovacao
      );
      let _periodicidade;
      if (_renovacao?.label) {
        _periodicidade = {
          id: _renovacao?.value,
          nome: _renovacao?.label,
        };
      }
      const novoBeneficio: BeneficiosVinculados = {
        idParceria: formDataParceriaParams.id,
        idServico: servicoSelecionado.id,
        codigoServico: servicoSelecionado.codProduto,
        nomeServico: servicoSelecionado.nomeAbreviado,
        periodicidade: _periodicidade,
        renovacao: data.renovacao,
        nomeRenovacao: _renovacao?.label || "INDEFINIDA",
        quantidade: _periodicidade.id === RenovacaoBeneficio.Ilimitada ? null : Number(data.quantidade),
      };
      setBeneficiosVinculados([
        ...beneficiosVinculados,
        { ...novoBeneficio, key: beneficiosVinculados.length + 1 },
      ]);

      const newFormDataParceria = {
        ...formDataParceriaParams,
        beneficios: [
          ...formDataParceriaParams.beneficios,
          { ...novoBeneficio, key: beneficiosVinculados.length + 1 },
        ],
      };

      setFormDataParceria(newFormDataParceria);
      if (onLinkBenefit) onLinkBenefit(newFormDataParceria);

      const hash = localStorage.getItem("@portalAdmin:hash") || "";

      if (formDataParceria.id) {
        await axios.put(
          `/beneficioData/AddBeneficio/${servicoSelecionado.id}`,
          novoBeneficio,
          {
            headers: {
              pm_hash: hash,
            },
          }
        );
      }

      setIsModalServiceLinkSuccessOpen(true);
      handleSetIsLoader(false);
    } catch (error) {
      console.error(error);
    } finally {
      handleSetIsLoader(false);
    }
  }

  async function handleUnlinkService(key: number, id?: number) {
    try {
      const beneficiosAtualizados = beneficiosVinculados.filter(
        (beneficio) => beneficio.key !== key
      );

      setBeneficiosVinculados(beneficiosAtualizados);
      setBeneficioDesvincular({ key, id });

      const newFormDataParceria = {
        ...formDataParceriaParams,
        beneficios: formDataParceriaParams.beneficios.filter(
          (beneficio) => beneficio.key !== key
        ),
      };

      setFormDataParceria(newFormDataParceria);
      if (onLinkBenefit) onLinkBenefit(newFormDataParceria);

      const hash = localStorage.getItem("@portalAdmin:hash") || "";

      if (formDataParceria.id) {
        await axios.put(
          `/beneficioData/RemoverBeneficio/${id}`,
          {},
          {
            headers: {
              pm_hash: hash,
            },
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getServiceName = () => {
    return beneficiosVinculados.find(
      (servico) => servico.id === beneficioDesvincular.id
    )?.nomeServico;
  };

  async function handleRegister() {
    try {
      handleSetIsLoader(true);
      //workarround
      formDataParceria.idTipoParceria = Number(formDataParceria.idTipoParceria);
      let auxHeaders;
      let auxHeadersAuth;

      if (formDataParceria.myHeaders) {
        auxHeaders = [...formDataParceria.myHeaders];
        auxHeaders = auxHeaders.map((item) => {
          let headerFormated: KeyValuePair = {
            Key: item.chave,
            Value: item.valor,
          };

          return headerFormated;
        });
      }

      if (formDataParceria.autenticacao?.myHeaders) {
        auxHeadersAuth = [...formDataParceria.autenticacao?.myHeaders];
        auxHeadersAuth = auxHeadersAuth.map((item) => {
          let headerFormated: KeyValuePair = {
            Key: item.chave,
            Value: item.valor,
          };         
          return headerFormated;
        });       
      }

      const dadosAuthParceria = {
        ...formDataParceria.autenticacao,        
        headers: auxHeadersAuth
      }; 

      const dadosParceria = {
        ...formDataParceria,
        beneficios: beneficiosVinculados,
        ativo: true,
        tipo: Number(formDataParceria.tipo),
        headers: auxHeaders,
        buscaComMascara: formDataParceria.utilizarMascara,
        landingPageCadastro: formDataParceria.landingPageCadastro,
        exibirPainel: formDataParceria.exibirPainel,
        logo: formDataParceria.logo,
        logoExtensao: "png",
        autenticacao: dadosAuthParceria  
      };

      delete dadosParceria.myHeaders;

      const hash = localStorage.getItem("@portalAdmin:hash");

      await new Promise(async (resolve) => {
        const response = await axios.post(
          `/beneficioData/AddParceria`,
          dadosParceria,
          {
            headers: {
              pm_hash: hash,
            },
          }
        );
        setTimeout(() => {
          resolve(response);
        }, 1000);
      });
    } catch (error) {
      console.error(error);
    } finally {
      handleSetIsLoader(false);
      setIsModalLoadingPartnershipRegisterOpen(false);
      setIsModalRegisterPartnershipSuccessOpen(true);
    }
  }

  const checkIfFormDataParceriaIsValid = () => {
    return (
      formDataParceria.vigenciaInicio &&
      formDataParceria.vigenciaFim &&
      formDataParceria.nome &&
      formDataParceria.tipo &&
      formDataParceria.idTipoParceria
    );
  };

  const checkIfFormLinkIsValid = () =>
    formDataBeneficios.idServico && formDataBeneficios.renovacao >= 0;

  const isString = (value: any) => {
    return typeof value === "string" || value instanceof String;
  };

  function handleSubmit(data: CadastroParceriaAbaBeneficiosFormData) {
    if (!checkIfFormLinkIsValid() || (data.quantidade === null && data.renovacao !== RenovacaoBeneficio.Ilimitada)) {
      setIsModalErrorOpen(true);
      return;
    }

    handleLinkService(data as any);
    onChangeFormData({} as CadastroParceriaAbaBeneficiosFormData);

    setServicoSelecionado({
      value: 0,
      label: "Selecione",
    } as DropdownSelectedItem);
    setRenovacaoSelecionada({
      value: 0,
      label: "Selecione",
    });
    setCurrentQuantity(1);
  }

  const actionBodyTemplate = (rowData: any) => {
    return (
      <ButtonPrime
        icon="pi pi-trash"
        className="p-button-outlined p-button-danger"
        onClick={() => {
          if (beneficiosVinculados?.length === 1) {
            setIsModalWarningParceriaOpen(true);
            return;
          } else {
            setBeneficioDesvincular({
              id: rowData.id,
              key: rowData.key || 0,
            });
            setIsModalConfirmServiceUnlinkOpen(true);
          }
        }}
        label="Desvincular"
      />
    );
  };

  const statusBodyTemplate = (value) => {   
    return value.quantidade === null || value.renovacao === RenovacaoBeneficio.Ilimitada ? '-' : value.quantidade;
};
  
  return (
    <S.Container className="form p-fluid p-formgrid p-grid">
      <Form onSubmit={handleSubmit} className="p-field p-col" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <h2
          style={{
            color: "#2A333E",
            fontWeight: 600,
            fontSize: "18px",
          }}
        >
          Lista de benefícios
        </h2>
        <div className="p-fluid p-formgrid p-grid">
          <div id="servico" className="p-field p-col">
            <label htmlFor="servico">Serviço</label>
            <SelectDropdown
              name="idServico"
              placeholder="Selecione"
              isSearchable
              options={servicos
                .map((servico) => ({
                  value: servico.id,
                  label: `${servico.codProduto} - ${servico.nomeAbreviado}`,
                }))
                .filter(
                  (servico) =>
                    !beneficiosVinculados.find(
                      (beneficio) => beneficio.idServico === servico.value
                    )
                )}
              newValue={
                servicoSelecionado.value
                  ? servicoSelecionado
                  : { value: 0, label: "Selecione" }
              }
              onChange={(event: any) => {
                const selecionado = event as DropdownSelectedItem;
                if (!selecionado.value)
                  setRenovacaoSelecionada({
                    value: -1,
                    label: "Selecione",
                  } as DropdownSelectedItem);
                setServicoSelecionado(selecionado);
              }}
            />
          </div>
          <div id="quantidade" className="p-field p-col-2">
            <label htmlFor="quantidade">Quantidade</label>
            <Quantity
              disabled={renovacaoSelecionada.value === RenovacaoBeneficio.Ilimitada}
              name="quantidade"
              value={renovacaoSelecionada.value === RenovacaoBeneficio.Ilimitada ? null : currentQuantity}
              renovacao={renovacaoSelecionada.value}
              handleChangeQuantity={(newQuantity) => setCurrentQuantity(newQuantity !== null ? Number(newQuantity) : 0)}
            />
          </div>
          <div id="renovacao" className="p-field p-col-2">
            <label htmlFor="renovacao">Renovação</label>
            <SelectDropdown
              name="renovacao"
              placeholder="Selecione"
              newValue={
                renovacaoSelecionada.value
                  ? renovacaoSelecionada
                  : { value: 0, label: "Selecione" }
              }
              disabled={!servicoSelecionado.value}
              options={renovacao}
              onChange={(event: any) => {
                const selecionado = event as DropdownSelectedItem;
                selecionado.value = !isString(event.value) ? event.value : 0;
                setRenovacaoSelecionada(selecionado);
              }}
            />
          </div>
          <div id="vincular">
            <label htmlFor="vincular">&nbsp;</label>
            <Button inverted type="submit" style={{ height: "40px" }}>
              Vincular
            </Button>
          </div>
        </div>
      </Form>
      <div className="p-field p-col-12 part-grid">
        {loading && <p style={{ width: "100%" }}>Carregando informações...</p>}
        <DataTable
          value={beneficiosVinculados}
          stripedRows
          columnResizeMode="fit"
          resizableColumns
          showGridlines
          scrollable
          scrollHeight="300px"
          style={loading ? { visibility: "hidden" } : { visibility: "visible" }}
          emptyMessage="Nenhum serviço foi vinculado até o momento."
        >
          <Column
            field="codigoServico"
            header="Código"
            headerClassName="custom-header"
            style={{ width: "5rem" }}
          />
          <Column
            field="nomeServico"
            header="Serviços farmacêuticos"
            headerClassName="custom-header"
            style={{ width: "50%" }}
          />
          <Column
            field="nomeRenovacao"
            header="Renovação"
            headerClassName="custom-header"
            style={{ width: "10rem" }}
          />
          <Column
            field="quantidade"
            header="Qtd"
            headerClassName="custom-header"
            style={{
              width: "2.75rem",
              padding: "1rem 0",
              textAlign: "center",
            }}
            body={statusBodyTemplate}
          />
          <Column
            body={actionBodyTemplate}
            headerClassName="custom-header"
            style={{ width: "10rem" }}
          />
        </DataTable>
      </div>
      {showButtons && (
        <>
          <div
            className={classNames({ "p-field p-col-12 p-m-0": true })}
            style={{ opacity: 0 }}
          />
          <div className="p-col-12 contentFooter">
            <Button inverted onClick={handleGoBack}>
              Cancelar
            </Button>
            <Button
              disabled={
                !beneficiosVinculados?.length ||
                !checkIfFormDataParceriaIsValid()
              }
              onClick={() => setIsModalConfirmRegisterPartnershipOpen(true)}
            >
              Cadastrar
            </Button>
          </div>
        </>
      )}
      <SimpleModal
        isOpen={isModalWarningParceriaOpen}
        title="Atenção"
        message="Parceria não pode ficar sem benefícios."
        icon={errorImg}
        onRequestClose={() => setIsModalWarningParceriaOpen(false)}
        confirm={() => setIsModalWarningParceriaOpen(false)}
        continueButtonText="Fechar"
      />
      <SimpleModal
        isOpen={isModalServiceLinkSuccessOpen}
        title="Sucesso"
        message="Serviço foi vinculado com sucesso."
        icon={successImg}
        onRequestClose={() => setIsModalServiceLinkSuccessOpen(false)}
        confirm={() => setIsModalServiceLinkSuccessOpen(false)}
      />

      {isModalConfirmServiceUnlinkOpen && (
        <SimpleModal
          isOpen={isModalConfirmServiceUnlinkOpen}
          title="Desvincular produto"
          message={
            <>
              Deseja desvincular este produto{" "}
              <strong>{getServiceName()}</strong>
              {"\n"}
              da lista de benefícios desta parceria?
            </>
          }
          icon={unlinkImg}
          showCancel
          onRequestClose={() => setIsModalConfirmServiceUnlinkOpen(false)}
          confirm={() => {
            handleUnlinkService(
              beneficioDesvincular.key,
              beneficioDesvincular.id
            );
            setIsModalConfirmServiceUnlinkOpen(false);
            setIsModalServiceUnlinkSuccessOpen(true);
          }}
        />
      )}

      <SimpleModal
        isOpen={isModalServiceUnlinkSuccessOpen}
        title="Sucesso"
        message="Serviço foi desvinculado com sucesso."
        icon={successImg}
        onRequestClose={() => setIsModalServiceUnlinkSuccessOpen(false)}
        confirm={() => setIsModalServiceUnlinkSuccessOpen(false)}
      />

      <SimpleModal
        isOpen={isModalConfirmRegisterPartnershipOpen}
        title="Cadastrar parceria"
        message="Deseja continuar com esta operação?"
        icon={warningImg}
        onRequestClose={() => setIsModalConfirmRegisterPartnershipOpen(false)}
        confirm={() => {
          setIsModalConfirmRegisterPartnershipOpen(false);
          setIsModalLoadingPartnershipRegisterOpen(true);
          handleRegister();
        }}
      />

      <SimpleModal
        isOpen={isModalLoadingPartnershipRegisterOpen}
        title="Cadastrando parceiro..."
        message=""
        hideButtons
        icon={loadingImg}
        onRequestClose={() => { }}
        confirm={() => { }}
      />

      <SimpleModal
        isOpen={isModalRegisterPartnershipSuccessOpen}
        title="Sucesso"
        message="Parceria foi registrada com sucesso."
        icon={successImg}
        onRequestClose={() => {
          setIsModalRegisterPartnershipSuccessOpen(false);
          history.push("/portaladmin/parcerias");
        }}
        confirm={() => {
          setIsModalRegisterPartnershipSuccessOpen(false);
          history.push("/portaladmin/parcerias");
        }}
      />

      <SimpleModal
        isOpen={isModalErrorOpen}
        onRequestClose={() => setIsModalErrorOpen(false)}
        confirm={() => setIsModalErrorOpen(false)}
        icon={errorImg}
        title="Erro"
        message="É preciso preencher todos os campos para vincular um benefício."
        continueButtonText="Fechar"
      />
    </S.Container>
  );
}
