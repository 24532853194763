import "./storeLinked.scss";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import { createRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  Selection,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import { ScrollView } from "devextreme-react";
import { Dialog } from "primereact/dialog";
import StoreServicesLinked from "../StoreServicesLinkedGrid/storeServicesLinkedGrid";

function StoreLinked({ store }) {
  const dataGridRef: any = createRef();
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [rowSelected, setRowSelected] = useState<any>();

  const togglePopup = () => setPopupVisibility(!isPopupVisible);

  const renderRowActionButtons = (data: any) => {
    return (
      <Button
        icon="edit"
        text="Editar"
        style={{ width: "100%" }}
        onClick={() => {
          setRowSelected(data);
          togglePopup();
        }}
      ></Button>
    );
  };

  const renderCodeCell = (data: any) => {
    return (
      <div className="servicesGridCell">
        <span>{data.data?.codigo}</span>
      </div>
    );
  };

  const renderNameCell = (data: any) => {
    return (
      <div className="servicesGridCell">
        <span>{data.data?.nome}</span>
      </div>
    );
  };

  const renderColumnHeader = (title: string) => {
    return (
      <strong style={{ color: "#444", textTransform: "uppercase" }}>
        {title}
      </strong>
    );
  };

  const getNameDialog = () => rowSelected?.data?.nome;

  const renderContent = () => {
    return (
      <>
        <ScrollView height="100%" width="100%">
          <StoreServicesLinked storeId={rowSelected?.data?.idLoja} roomId={rowSelected?.data?.idSala}/>
        </ScrollView>
      </>
    );
  };

  return (
    <>
      {store.length > -1 && (
        <div className="storeLinked">
          {/* <div className="storeLinked__exportButtons">
            <Button className="exportBtn" icon="link" text="Ação 1" />
            <Button className="exportBtn" icon="link" text="Ação 2" />
          </div> */}

          <DataGrid
            ref={dataGridRef}
            showColumnLines={false}
            showRowLines={true}
            id="dataGridStoreLinked"
            dataSource={store}
            keyExpr="codigo"
            allowColumnResizing={false}
            columnAutoWidth={true}
            noDataText="Nenhuma loja vinculada."
            hoverStateEnabled={true}
          >
            <Column
              cssClass="p-text-left"
              width={75}
              name="codigo"
              dataField="codigo"
              caption="Código"
              headerCellRender={() => renderColumnHeader("Código")}
              cellRender={(data) => renderCodeCell(data)}
            />
            <Column
              width="70%"
              name="nome"
              dataField="nome"
              caption="Serviço"
              headerCellRender={() => renderColumnHeader("Loja")}
              cellRender={(data) => renderNameCell(data)}
            />
            <Column
              allowExporting={false}
              width={150}
              headerCellRender={() => renderColumnHeader("")}
              allowSorting={false}
              allowSearch={false}
              cellRender={renderRowActionButtons}
            />
            <FilterRow visible={true} />
            <Selection mode="none" />
          </DataGrid>

          <Dialog
            header={getNameDialog()}
            visible={isPopupVisible}
            style={{ width: "75vw", height: "75vh" }}
            onHide={() => togglePopup()}
            closeOnEscape={false}
          >
            {renderContent()}
          </Dialog>
        </div>
      )}
    </>
  );
}

export default StoreLinked;
