import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
// import { Calendar } from "primereact/calendar";
import { Stats } from "../Stats";
import { useEffect, useState } from "react";
import { calendarLanguage } from "../../../utils/calendarLanguage";
import { partnerService } from "services/partnerService";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";

interface DropdownKeyValue {
  key: string;
  value: string;
}

const status = [
  { value: "Ativo", key: "1" },
  { value: "Inativo", key: "0" },
] as DropdownKeyValue[];

const Second = (props: any) => {
  const { state } = props;
  const [typesPartners, setTypesPartners] = useState<DropdownKeyValue[]>([]);
  const [selectedType, setSelectedType] = useState<DropdownKeyValue>();
  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [emailResponsavel, setEmailResponsavel] = useState("");
  const [maskSac, setMaskSac] = useState("9999 999 9999");
  let defaultValues = {
    tipoParceiro: "",
    status: "",
    nomeResponsavel: "",
    telefoneResponsavel: "",
    emailSac: "",
    telefoneSac: "",
  };
  // const agora = new Date();
  // const anoAtual = agora.getFullYear();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues, mode: "onSubmit", criteriaMode: "all" });

  const onSubmit = (data: any) => {
    data.tipoParceiro = selectedType;
    if (!selectedType) {
      data.tipoParceiro = typesPartners?.find(
        (item: any) => Number(item.key) === Number(data.tipoParceiro)
      );
    }
    props.update("dadospreenchidos", data);
    props.nextStep();
  };

  const getFormErrorMessage = (name: any) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  useEffect(() => calendarLanguage, []);
  useEffect(() => {
    partnerService.getTypePartner().then((items) => {
      setTypesPartners(items);
      if (props.state?.infoPartner.idParceiro) {
        let _element = typesPartners.find(
          (item: any) =>
            Number(item.key) === Number(props.state?.infoPartner.idTipoParceiro)
        );
        setValue("tipoParceiro", _element?.key ?? "");
        setSelectedType(_element);
      }
    });
  }, []);

  useEffect(() => {
    const data = props.state.form?.dadosapi?.[0];
    if (data) {
      setCnpj(data?.cnpj);
      setRazaoSocial(data?.razaoSocial);
      setNomeFantasia(data?.nomeFantasia);
      setEmailResponsavel(props.state.form?.email);
    }
  }, [props.state.form?.dadosapi?.[0]]);

  useEffect(() => {
    const data = props.state?.infoPartner;
    if (data) {
      setCnpj(data?.cnpj);
      setRazaoSocial(data?.razaoSocial);
      setNomeFantasia(data?.nomeFantasia);
      setEmailResponsavel(props.state.form?.email);
      setValue("nomeResponsavel", data?.nomeResponsavel);
      setValue("telefoneResponsavel", data?.telefoneResponsavel);
      setValue("emailSac", data?.emailSac);
      setValue("telefoneSac", data?.telefoneSac);
      let index = status.findIndex((item) => item.key === "1" && data.status);
      setValue(
        "status",
        status[index < 0 ? 1 : index].value
      );

      let _element = typesPartners.find(
        (item: any) => Number(item.key) === Number(data.idTipoParceiro)
      );
      setValue("tipoParceiro", _element?.key ?? "");
      setSelectedType(_element);
    }
  }, [props.state?.infoPartner]);

  return (
    <div className="">
      <h3 className="text-center">
        Para cadastrar o parceiro, preencha os dados abaixo.
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="cnpj">CNPJ</label>
            <InputText id="cnpj" type="text" value={cnpj} disabled />
          </div>
          <div className="p-field p-col-12 p-md-8">
            <label htmlFor="razao-social">Razão social</label>
            <InputText
              id="razao-social"
              type="text"
              value={razaoSocial}
              disabled
            />
          </div>
          <div className="p-field p-col-12 p-md-12">
            <label htmlFor="nome-fantasia">Nome fantasia</label>
            <InputText
              id="nome-fantasia"
              type="text"
              value={nomeFantasia}
              disabled
            />
          </div>
          <div className="p-field p-col-12 p-md-3">
            <label
              htmlFor="tipoParceiro"
              className={classNames({ "p-error": errors.tipoParceiro })}
            >
              Tipo do parceiro
            </label>
            <Controller
              name="tipoParceiro"
              control={control}
              rules={{ required: "Tipo do parceiro é obrigatório." }}
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                    let element = typesPartners?.find(
                      (item: DropdownKeyValue) =>
                        Number(item.key) === Number(e.value)
                    );
                    setSelectedType(element);
                  }}
                  options={typesPartners}
                  optionLabel="value"
                  optionValue="key"
                  placeholder="Tipo de vínculo"
                  emptyMessage="Sem resultados"
                />
              )}
            />
            {getFormErrorMessage("tipoParceiro")}
          </div>
          <div className="p-field p-col-12 p-md-3">
            <label
              htmlFor="status"
              className={classNames({ "p-error": errors.status })}
            >
              Status
            </label>
            <Controller
              name="status"
              control={control}
              rules={{ required: "Status é obrigatório." }}
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={status}
                  optionLabel="value"
                  placeholder="Status do parceiro"
                  emptyMessage="Sem resultados"
                />
              )}
            />
            {getFormErrorMessage("status")}
          </div>
          {/* <div className="p-field p-col-12 p-md-3">
            <label htmlFor="data-ativacao">Data de ativação</label>
            <Calendar
              id="data-ativacao"
              value={dateActivation}
              onChange={(e) => setDateActivation(e.value?.[0])}
              monthNavigator
              yearNavigator
              yearRange={`${anoAtual - 5}:${anoAtual + 2}`}
              monthNavigatorTemplate={monthNavigatorTemplate}
              yearNavigatorTemplate={yearNavigatorTemplate}
              showIcon
              mask="99/99/9999"
              locale="pt-BR"
            />
          </div>
          <div className="p-field p-col-12 p-md-3">
            <label htmlFor="data-inativacao">Data de desativação</label>
            <Calendar
              id="data-inativacao"
              value={dateInactivation}
              onChange={(e) => setDateInactivation(e.value?.[0])}
              monthNavigator
              yearNavigator
              yearRange={`${anoAtual - 5}:${anoAtual + 2}`}
              monthNavigatorTemplate={monthNavigatorTemplate}
              yearNavigatorTemplate={yearNavigatorTemplate}
              showIcon
              mask="99/99/9999"
              locale="pt-BR"
            />
          </div> */}
          <div className="p-field p-col-12 p-md-12">
            <label
              htmlFor="nomeResponsavel"
              className={classNames({ "p-error": errors.nomeResponsavel })}
            >
              Nome do responsável*
            </label>
            <span className="p-float-label">
              <Controller
                name="nomeResponsavel"
                control={control}
                rules={{ required: "Nome do responsável é obrigatório." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("nomeResponsavel")}
          </div>
          <div className="p-field p-col-12 p-md-8">
            <label htmlFor="emailResponsavel">Email do responsável*</label>
            <span className="p-float-label">
              <InputText
                id="emailResponsavel"
                type="text"
                value={emailResponsavel}
                disabled
              />
            </span>
            {getFormErrorMessage("emailResponsavel")}
          </div>
          <div className="p-field p-col-12 p-md-4">
            <label
              htmlFor="telefoneResponsavel"
              className={classNames({ "p-error": errors.telefoneResponsavel })}
            >
              Telefone do responsável*
            </label>
            <span className="p-float-label">
              <Controller
                name="telefoneResponsavel"
                control={control}
                rules={{
                  required: "Telefone do responsável é obrigatório.",
                  pattern: {
                    value: /^\(\d{2}\)[ ]?\D*(\d{5}|\d{4})[-. ]?\D*(\d{4})$/i,
                    message: "Telefone inválido.",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputMask
                    id={field.name}
                    {...field}
                    mask="(99) ?99999-9999"
                    className={classNames({ "p-invalid": fieldState.invalid })}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("telefoneResponsavel")}
          </div>
          <div className="p-field p-col-12 p-md-8">
            <label
              htmlFor="emailSac"
              className={classNames({ "p-error": errors.emailSac })}
            >
              Email SAC*
            </label>
            <span className="p-float-label">
              <Controller
                name="emailSac"
                control={control}
                rules={{
                  required: "Email do SAC é obrigatório.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message:
                      "Endereço de email invalido. E.g. example@email.com",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("emailSac")}
          </div>
          <div className="p-field p-col-12 p-md-4">
            <label
              htmlFor="telefoneSac"
              className={classNames({ "p-error": errors.telefoneSac })}
            >
              Telefone SAC*
            </label>
            <span className="p-float-label">
              <Controller
                name="telefoneSac"
                control={control}
                rules={{
                  required: "Telefone do SAC é obrigatório.",
                  pattern: {
                    value:
                      /^\(\d{2}\)[ ]?\D*(\d{5}|\d{4})[-. ]?\D*(\d{4})|\D*(\d{4})[ ]\D*(\d{3})[ ]\D*(\d{4})$/i,
                    message: "Telefone inválido.",
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputMask
                    id={field.name}
                    {...field}
                    mask={maskSac}
                    autoClear={false}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                  />
                )}
              />
            </span>
            {getFormErrorMessage("telefoneSac")}
          </div>
        </div>
      </form>
      <Stats
        step={props.currentStep}
        {...props}
        onSubmit={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default Second;
