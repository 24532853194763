import {
  ReactElement,
  useState,
  CSSProperties,
  cloneElement,
  useEffect,
  useRef
} from "react";

import { Pagination } from "components/Pagination";
import { colors } from "tokens";
import useClickAway from 'react-use/lib/useClickAway';

import iconGridIconButtonArrow from "images/icons/icon-grid-action-button-arrow.svg";

import {
  Container,
  TableContainer,
  Table,
  TableRowHeader,
  TableRow,
  Subtitle,
  PaginationContainer,
  ActionButtonModal,
  ActionButtonHeaderModal,
} from "./styles";
import { Checkbox } from "components/Checkbox";
import { Tooltip } from "components/Tooltip";
import { useLoader } from "hooks/loader";

export interface StatusIconMapProps {
  status: number | "active" | "inactive";
  icon: ReactElement;
}

export interface ColumnWidthProps {
  column: string;
  width: string;
}

export interface ColumnAlignCenterProps {
  column: string;
  align: boolean;
}

interface GridProps {
  columns: string[];
  columnsWidth?: ColumnWidthProps[];
  columnsAlignCenter?: ColumnAlignCenterProps[];
  omit?: string[];
  maxHeight?: string;
  minHeight?: string;
  customStyle?: CSSProperties;
  data: any[];
  totalData?: number;
  statusIconMap?: StatusIconMapProps[];
  actionButtonModalContent?: ReactElement;
  actionButtonHeaderModalContent?: ReactElement;
  detailContent?: ReactElement;
  enableSelect?: boolean;
  enablePagination?: boolean;
  enableActionButton?: boolean;
  enableDetailColumn?: boolean;
  enableColumnSort?: boolean;
  disableColumnSortFor?: string[];
  onClickSort?: (column: string) => void;
  onChangePage?: (currentPage: number) => void;
  disableRowBy?: string;
  disableRowByValueToDisable?: number;
  emptyRowMessage?: string;
  columnToDisplayMessageEmpty?: string;
  emptyRow?: boolean;
  itemsPerPage?: number;
  objectKey?: string;
  className?: string;
}

interface SortColumnIconProps {
  onClick: (column: string) => void;
  column: string;
}

function SortColumnIcon({ onClick, column }: SortColumnIconProps) {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClick(column)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.904043 2.0966L2.76404 0.236602C2.89071 0.109935 3.10404 0.109935 3.23071 0.236602L5.09071 2.0966C5.30404 2.30327 5.15738 2.66327 4.85738 2.66327H3.66404V6.66993C3.66404 7.0366 3.36404 7.3366 2.99738 7.3366C2.63071 7.3366 2.33071 7.0366 2.33071 6.66993V2.66327H1.13738C0.837377 2.66327 0.69071 2.30327 0.904043 2.0966ZM7.66404 5.3366V9.34327H8.86404C9.15738 9.34327 9.31071 9.70327 9.09738 9.90994L7.23738 11.7633C7.10404 11.8899 6.89738 11.8899 6.76404 11.7633L4.90404 9.90994C4.69071 9.70327 4.83738 9.34327 5.13738 9.34327H6.33071V5.3366C6.33071 4.96994 6.63071 4.66994 6.99738 4.66994C7.36404 4.66994 7.66404 4.96994 7.66404 5.3366Z"
        fill={colors.gray.blue}
      />
    </svg>
  );
}

export function Grid({
  columns,
  columnsWidth,
  columnsAlignCenter,
  omit = [],
  maxHeight = "356px",
  minHeight = "300px",
  customStyle,
  data,
  totalData,
  statusIconMap,
  actionButtonModalContent,
  actionButtonHeaderModalContent,
  detailContent,
  enableSelect = false,
  enablePagination = false,
  enableActionButton = false,
  enableDetailColumn = false,
  enableColumnSort = false,
  disableColumnSortFor = ["Status"],
  onClickSort = () => { },
  onChangePage = () => { },
  disableRowBy = "",
  disableRowByValueToDisable = undefined,
  emptyRowMessage = "",
  columnToDisplayMessageEmpty = "",
  emptyRow = false,
  itemsPerPage = 8,
  objectKey = "id",
  className = undefined
}: GridProps) {
  const { handleSetIsLoader } = useLoader();
  const [idActionButtonModalOpen, setIdActionButtonModalOpen] = useState(0);
  const [
    actionButtonHeaderModalOpen,
    setActionButtonHeaderModalOpen,
  ] = useState(false);
  const [itemsSelected, setItemsSelected] = useState<number[]>([]);
  const [idTooltipOpen, setIdTooltipOpen] = useState<number>(0);

  const numberOfPages = totalData && Math.ceil(totalData / itemsPerPage);

  const tooltipRef = useRef(null);
  useClickAway(tooltipRef, () => {
    setIdTooltipOpen(0);
  });

  useEffect(() => {
    window.removeEventListener("click", detectClickOusideModalActionButton);
    window.addEventListener("click", detectClickOusideModalActionButton);

    return () =>
      window.removeEventListener("click", detectClickOusideModalActionButton);
  }, [idActionButtonModalOpen, actionButtonHeaderModalOpen]);

  function detectClickOusideModalActionButton(e: MouseEvent) {
    const actionButtonModal = document.getElementById("actionButtonModal");
    const headerGridModalAction = document.getElementById(
      "headerGridModalAction"
    );

    // @ts-ignore
    if (!actionButtonModal?.contains(e.target)) {
      if (idActionButtonModalOpen) {
        setIdActionButtonModalOpen(0);
      }
    }

    // @ts-ignore
    if (!headerGridModalAction?.contains(e.target)) {
      if (actionButtonHeaderModalOpen) {
        setActionButtonHeaderModalOpen(false);
      }
    }
  }

  function toggleActionButtonModal(id: number) {
    if (idActionButtonModalOpen === id) {
      setIdActionButtonModalOpen(0);
      return;
    }

    setIdActionButtonModalOpen(id);
  }

  function renderRow(
    row: Record<any, any>,
    checkIfNeedDisableRow: boolean,
    checkIfColumnIsEmpty: boolean
  ) {
    return Object.entries(row)
      .filter((item) => !omit.includes(item[0]))
      .map((item) => {
        if (
          (!item[1] || !item[1].length) &&
          item[0] === columnToDisplayMessageEmpty
        ) {
          if (item[0] === "status") {
            return <td key={item[0]}></td>;
          }

          // const rowContent = checkIfNeedDisableRow
          //   ? disabledRowMessage
          //   : item[1];

          return <td key={item[0]}>{emptyRowMessage}</td>;
        } else if (checkIfNeedDisableRow || checkIfColumnIsEmpty) {
          if (item[0] === "status") {
            return <td key={item[0]}></td>;
          }

          return <td key={item[0]}>{item[1]}</td>;
        } else {
          if (item[0] === "status") {
            const customAlign = columnsAlignCenter?.find(
              (customAlign) => customAlign.column.toLocaleUpperCase() === item[0].toLocaleUpperCase()
            );

            const statusIcon = statusIconMap?.find(
              (status) => status.status === item[1]
            );

            return <td key={item[0]} style={{
              textAlign:
                customAlign && customAlign?.align
                  ? "center"
                  : "start"
            }}>{statusIcon?.icon}</td>;
          }
          if (typeof item[1] === "string" && item[1]?.includes("<img")) {
            return (
              <td key={row[objectKey]}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item[1],
                  }}
                  onClick={() =>
                    setIdTooltipOpen(idTooltipOpen === row[objectKey] ? 0 : row[objectKey])
                  }
                />
                {row[objectKey] === idTooltipOpen && (
                  <Tooltip
                    onClick={() => setIdTooltipOpen(0)}
                    position={{ left: "-286px", top: "47px" }}
                    html={row.nomeServicos}
                    ref={tooltipRef}
                  />
                )}
              </td>
            );
          }

          const customAlign = columnsAlignCenter?.find(
            (customAlign) => customAlign.column.toLocaleUpperCase() === item[0].toLocaleUpperCase()
          );
          let paddinData = "";
          if (item[0].toLocaleUpperCase() === "DATA") {
            paddinData = "0 7px";
          }
          return <td key={item[0]}
            style={{
              textAlign:
                customAlign && customAlign?.align
                  ? "center"
                  : "start",
              padding: paddinData ? paddinData : "0 12px",
            }}
          >{item[1]}</td>;
        }
      });
  }

  function renderActionColumn() {
    const actionColumnWidth = columnsWidth?.find(
      (customColumn) => customColumn.column === "Action"
    );

    return (
      <th
        style={{
          maxWidth:
            actionColumnWidth && actionColumnWidth.width
              ? actionColumnWidth.width
              : "auto",
          minWidth:
            actionColumnWidth && actionColumnWidth.width
              ? actionColumnWidth.width
              : "auto",
        }}
      ></th>
    );
  }

  function renderDetailColumn() {
    return (
      <th
        style={{
          minWidth: "50px",
        }}
      >
        <button
          type="button"
          onClick={() =>
            setActionButtonHeaderModalOpen(!actionButtonHeaderModalOpen)
          }
        >
          <p>Ações</p>
          <img
            src={iconGridIconButtonArrow}
            alt="Seta para mostrar o menu de ações"
          />
        </button>

        {actionButtonHeaderModalOpen && (
          <ActionButtonHeaderModal id="headerGridModalAction">
            {cloneElement(actionButtonHeaderModalContent as ReactElement, {
              selectedRows: itemsSelected,
            })}
          </ActionButtonHeaderModal>
        )}
      </th>
    );
  }

  function handleSelectAll() {
    setItemsSelected([...data.map((item) => item[objectKey])]);
  }

  function handleToggleSelected(id: number) {
    let newArray;

    if (itemsSelected.includes(id)) {
      newArray = itemsSelected.filter((item) => item !== id);
    } else {
      newArray = [...itemsSelected, id];
    }

    setItemsSelected(newArray);
  }

  return (
    <Container style={customStyle} numberOfColumns={columns?.length} minHeight={minHeight}>
      <TableContainer maxHeight={maxHeight} minHeight={minHeight} className={className}>
        <Table>
          <thead>
            <TableRowHeader>
              {enableSelect && (
                <th style={{ width: "48px" }}>
                  <Checkbox
                    checked={itemsSelected?.length === data.length}
                    onClick={() =>
                      !(itemsSelected.length === data.length)
                        ? handleSelectAll()
                        : setItemsSelected([])
                    }
                    isSelectAll
                  />
                </th>
              )}
              {columns.map((column: string) => {
                const customWidth = columnsWidth?.find(
                  (customColumn) => customColumn.column === column
                );

                const customAlign = columnsAlignCenter?.find(
                  (customAlign) => customAlign.column === column
                );

                return (
                  <th
                    key={column}
                    style={{
                      maxWidth:
                        customWidth && customWidth.width
                          ? customWidth.width
                          : "auto",
                      minWidth:
                        customWidth && customWidth.width
                          ? customWidth.width
                          : "auto",
                      textAlign:
                        customAlign && customAlign?.align
                          ? "center"
                          : "start",
                    }}
                  >
                    <span>{column}</span>
                    {/*enableColumnSort &&
                      !disableColumnSortFor.includes(column) && (
                        <SortColumnIcon onClick={onClickSort} column={column} />
                      )*/}
                  </th>
                );
              })}
              {enableActionButton && renderActionColumn()}
              {enableDetailColumn && renderDetailColumn()}
            </TableRowHeader>
          </thead>
          {!!data?.length && (
            <>
              <tbody>
                {data.map((item) => {
                  const checkIfNeedDisableRow =
                    !!disableRowBy &&
                    item[disableRowBy] === disableRowByValueToDisable;

                  const checkIfColumnIsEmpty =
                    !!columnToDisplayMessageEmpty &&
                    (!item[columnToDisplayMessageEmpty] ||
                      !item[columnToDisplayMessageEmpty].length);

                  return (
                    <TableRow
                      key={item[objectKey]}
                      emptyRow={checkIfColumnIsEmpty}
                      disableRow={checkIfNeedDisableRow}
                    >
                      {enableSelect && (
                        <td>
                          <Checkbox
                            checked={itemsSelected.includes(item[objectKey])}
                            onClick={() => handleToggleSelected(item[objectKey])}
                          />
                        </td>
                      )}
                      {renderRow(
                        item,
                        checkIfNeedDisableRow,
                        checkIfColumnIsEmpty
                      )}
                      {enableActionButton && (
                        <td className="actionButtonColumn">
                          <div>
                            <button
                              type="button"
                              onClick={() => toggleActionButtonModal(item[objectKey])}
                            >
                              <p>Ações</p>
                              <img
                                src={iconGridIconButtonArrow}
                                alt="Seta para mostrar o menu de ações"
                              />
                            </button>
                            {idActionButtonModalOpen === item[objectKey] && (
                              <ActionButtonModal id="actionButtonModal">
                                {cloneElement(
                                  actionButtonModalContent as ReactElement,
                                  {
                                    data: item,
                                  }
                                )}
                              </ActionButtonModal>
                            )}
                          </div>
                        </td>
                      )}
                      {enableDetailColumn && (
                        <td>
                          {cloneElement(detailContent as ReactElement, {
                            id: item[objectKey],
                          })}
                        </td>
                      )}
                    </TableRow>
                  );
                })}
              </tbody>
            </>
          )}
        </Table>
        {enablePagination && !!totalData && (
          <Subtitle>
            <PaginationContainer>
              <Pagination
                numberOfPages={numberOfPages || 0}
                onChangePage={onChangePage}
              />
            </PaginationContainer>
          </Subtitle>
        )}
      </TableContainer>
    </Container>
  );
}
