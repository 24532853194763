import styled from "styled-components";
import colors from "../../../tokens/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-family: "Source Sans Pro", sans-serif;
    margin: 16px 0 8px;
    color: ${colors.textDarkModal};
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 32px;
    font-family: "Source Sans Pro", sans-serif;
    color: ${colors.gray.text};
    font-size: 16px;
    text-align: center;
  }

  button {
    font-family: "Source Sans Pro", sans-serif;
  }

  .custom-display-grid {
    display: inherit;
  }
`;

export const ContainerInput = styled.div`
  margin-bottom: 20px;
  position: relative;

  input {
    width: 100%;
    border: 1px solid #aec0d2;
    border-radius: 4px;
    padding: 7px 10px;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
`;

export const ContainerButton = styled.div`
  margin-top: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 123px;
    height: 48px;
  }
`;
