import {
  createContext,
  ReactNode,
  useContext,
} from "react";

interface CanContextData {
  rolesCanRequestAccess: string[];
}

interface CanProviderProps {
  children: ReactNode;
}

const CanContext = createContext({} as CanContextData);

function CanProvider({ children }: CanProviderProps) {
  const rolesCanRequestAccess  = ["OPERADOR", "FARMACEUTICO"];

  return (
    <CanContext.Provider
      value={{ rolesCanRequestAccess }}
    >
      {children}
    </CanContext.Provider>
  );
}

function useCan(): CanContextData {
  const context = useContext(CanContext);

  return context;
}

export { CanProvider, useCan };
