import "./servicesLinkedGrid.scss";
import DataGrid, {
  Column,
  FilterRow,
  Selection,
} from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import StatusIcon from "./Icons/StatusIcon/statusIcon";
import { createRef, useEffect, useState } from "react";
import { Button } from "devextreme-react/button";
import { usePartnerContext } from "../../../../contexts/partnerContextProvider";
import { ItemClickEvent } from "devextreme/ui/drop_down_button";
import Swal from "sweetalert2";
import { partnerService } from "services/partnerService";
import { useRouter } from "hooks/useRouter";

function ServicesLinkedGrid({ services, setServices, _partnerId }) {
  const router = useRouter();
  const dataGridRef: any = createRef();
  const { setIsLoading } = usePartnerContext();
  const [partnerId, setPartnerId] = useState(_partnerId ?? 0);

  useEffect(() => {
    setPartnerId(_partnerId);
  }, [_partnerId]);

  const renderRowActionButtons = (data: any) => {
    const handleOptionClick = (e: ItemClickEvent, data: any) => {
      let close = e.itemData.icon === "close";
      let complement = "Existem lojas e preços vinculados a este serviço. ";
      let messageActive = "Deseja realmente ativar?"
      let messageInactive = "Deseja realmente desativar o serviço para todas as lojas?"
      let messageCloseFinal = complement + messageInactive;
      let message = (close ? messageCloseFinal : messageActive);

      if (e.itemData.icon !== "edit") {
        Swal.fire({
          text: message,
          icon: "warning",
          showCancelButton: true,
          allowEscapeKey: true,
          confirmButtonColor: "#0054A6",
          cancelButtonColor: "#ed1d24",
          confirmButtonText: `${close ? "Desativar" : "Ativar"}`,
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            handleStatusServiceLinkedByPartnerId(data.data);
          }
        });
      }
    };

    let actions = [
      { id: 1, text: "Ativar", icon: "check" },
      { id: 2, text: "Desativar", icon: "close" },
    //  { id: 3, text: "Editar", icon: "edit" },
    ];
    const descricaoStatus = data.data?.ativo ? "Ativar" : "Desativar";
    actions = actions.filter((item) => item.text !== descricaoStatus);

    return (
      <DropDownButton
        text="Gerenciar"
        icon=""
        items={actions}
        style={{ width: "100%" }}
        keyExpr="id"
        displayExpr="text"
        onItemClick={(e) => handleOptionClick(e, data)}
      />
    );
  };

  const renderCodeCell = (data: any) => {
    return (
      <div className="servicesGridCell">
        <span>{data.data?.codProduto}</span>
      </div>
    );
  };

  const renderStatusCell = (data: any) => {
    return (
      <div className="servicesGridCell">
        <StatusIcon data={data.data} />
      </div>
    );
  };

  const renderNameCell = (data: any) => {
    return (
      <div className="servicesGridCell">
        <span>{data.data?.nome}</span>
      </div>
    );
  };

  const renderColumnHeader = (title: string) => {
    return (
      <strong style={{ color: "#444", textTransform: "uppercase" }}>
        {title}
      </strong>
    );
  };

  const handleStatusServiceLinkedByPartnerId = (data: any) => {
    if (!data) return;

    setIsLoading(true);
    partnerService
      .updateStatusServiceLinkedByPartnerId(partnerId, data.id, !data.ativo)
      .then((_) => {
        let servicesAux = services?.map((item: any) => {
          if (item.id == data.id) item.ativo = !item.ativo;

          return item;
        });
        setServices(servicesAux);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {services.length > -1 && (
        <div className="servicesLinkedGrid">
          <div className="servicesLinkedGrid__exportButtons">
            <Button
              className="exportBtn"
              icon="link"
              text="Vincular serviços"
              onClick={() => router.replace("/portaladmin/partner/linkServices")}
            />
          </div>

          <DataGrid
            ref={dataGridRef}
            showColumnLines={false}
            showRowLines={true}
            id="dataGridServiceLinked"
            dataSource={services}
            keyExpr="id"
            allowColumnResizing={false}
            columnAutoWidth={true}
            noDataText="Nenhum serviço vinculado."
          >
            <Column
              width={75}
              name="codProduto"
              allowExporting={true}
              allowFiltering={false}
              allowSorting={false}
              dataField="codProduto"
              caption="Código"
              headerCellRender={() => renderColumnHeader("Código")}
              cellRender={(data) => renderCodeCell(data)}
            />
            <Column
              width="65%"
              name="nome"
              allowExporting={true}
              allowFiltering={false}
              allowSorting={false}
              dataField="nome"
              caption="Serviço"
              headerCellRender={() => renderColumnHeader("Serviço")}
              cellRender={(data) => renderNameCell(data)}
            />
            <Column
              width="auto"
              name="ativo"
              allowExporting={true}
              allowFiltering={false}
              allowSorting={false}
              dataField="ativo"
              caption="Status"
              headerCellRender={() => renderColumnHeader("Status")}
              cellRender={(data) => renderStatusCell(data)}
            />
            <Column
              allowExporting={false}
              width={150}
              headerCellRender={() => renderColumnHeader("Ações")}
              allowSorting={false}
              allowSearch={false}
              cellRender={renderRowActionButtons}
            />
            <FilterRow visible={true} />
            <Selection mode="none" />
          </DataGrid>
        </div>
      )}
    </>
  );
}

export default ServicesLinkedGrid;
