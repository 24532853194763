import { useEffect, useState } from "react";

import checkIcon from "images/icons/grid-check-selected.svg";
import selectAllIcon from "images/icons/grid-check-select-all.svg";

import { Container } from "./styles";

interface CheckboxProps {
  checked: boolean;
  isSelectAll?: boolean;
  onClick: () => void;
}

export function Checkbox({
  checked,
  isSelectAll = false,
  onClick,
}: CheckboxProps) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  function handleClick() {
    setIsChecked(!isChecked);
    onClick();
  }

  return (
    <Container checked={isChecked} onClick={handleClick}>
      {isSelectAll && isChecked && <img src={selectAllIcon} alt="Checkbox" />}
      {!isSelectAll && isChecked && <img src={checkIcon} alt="Checkbox" />}
    </Container>
  );
}
