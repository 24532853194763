import styled, { css } from "styled-components";

import { colors } from "tokens";

interface ContainerProps {
  type: "active" | "inactive";
}

export const Container = styled.div<ContainerProps>`
  width: fit-content;
  padding: 3px 8px;
  padding-left: 17.5px;
  border-radius: 50px;

  p {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 15px;

    &:before {
      content: "";
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      height: 6px;
      width: 6px;
      border-radius: 3px;
    }
  }

  ${({ type }) => {
    if (type === "active") {
      return css`
        background: ${colors.greenLight};
        color: ${colors.success};

        p:before {
          background: ${colors.success};
        }
      `;
    } else {
      return css`
        background: #dadada;
        color: ${colors.gray.blue};

        p:before {
          background: ${colors.gray.blue};
        }
      `;
    }
  }}
`;
