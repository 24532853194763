import { forwardRef, Ref } from "react";
import * as Styled from "./styles";

interface PositionProps {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

interface TooltipProps {
  position: PositionProps;
  onClick: () => void;
  html: string;
  inverter?: boolean;
}

function TooltipComponent({ position, onClick, html, inverter }: TooltipProps, ref: Ref<HTMLDivElement>) {
  return (
    <Styled.Container ref={ref} position={position} onClick={onClick} >
      <Styled.Triangle inverter={inverter} />
      <p dangerouslySetInnerHTML={{ __html: html }} />
    </Styled.Container>
  );
}

export const Tooltip = forwardRef(TooltipComponent);