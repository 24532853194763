import styled, { css } from "styled-components";
import { colors } from "tokens";

interface ContainerProps {
  selected?: boolean;
}

export const Container = styled.span<ContainerProps>`
  width: 146px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid transparent;
  font-family: "Source Sans Pro", sans-serif;

  svg {
    margin-right: 8px;
  }


  ${({ selected }) =>
    selected &&
    css`
      border-color: ${colors.blue};
      color: ${colors.blue};

      svg path {
        fill: ${colors.blue};
      }
    `}

  &:hover {
    a {
      cursor: pointer;
      font-weight: 600;
      color: ${colors.blue} !important;
    }

    svg path {
      fill: ${colors.blue};
    }
  }

  a {
    color: ${({ selected }) => (selected ? colors.blue : colors.gray.blue)} !important;
    font-weight: ${({ selected }) => (selected ? 600 : 400)};
    text-decoration: none;
  }
`;
