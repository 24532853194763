import './loginModal.scss'
import { useState } from 'react';
import logo from '../../../images/logoBrc.png';
import BarLoader from "react-spinners/BarLoader";
import { validateEmail } from '../../../utils/utils'
import { toast } from 'react-toastify';
import { usePartnerAuth } from '../../../hooks/partnerAuth'
import { useRouter } from '../../../hooks/useRouter'
import { usePartnerContext } from 'contexts/partnerContextProvider';
import OptInModal from '../OptInModal/optInModal';

export default function LoginModal(props: any) {
    const { handlePartnerSignin, handlePartnerSession, handlePartnerSignout } = usePartnerAuth()
    const { setPartnerData, wipeOutPartnerData } = usePartnerContext()
    const [optIn, setOptIn] = useState(false)
    const router = useRouter()

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        userEmail: "",
        userPassword: ""
    })

    const handleFieldValue = (e: any) => {
        setFields({
            ...fields,
            [e.target.name]: e.target.value
        })
    }

    const overlayHandler = (e: any) => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            props.handleOpen()
        }
    }

    const handleLogin = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        if (fields.userEmail.trim() === '' || fields.userPassword.trim() === '') {
            toast.error("E-mail ou senha não informados!")
            setLoading(false);
            return false;
        }

        if (!validateEmail(fields.userEmail)) {
            toast.error("Informe um e-mail válido!")
            setLoading(false);
            return false;
        }

        const loginData: any = await handlePartnerSignin(fields.userEmail, fields.userPassword, true);

        if (loginData !== null) {
            handlePartnerSession()
            setPartnerData(loginData.partnerData)
            if (loginData.partnerData.aceite) {
                setTimeout(() => { router.replace('/portaladmin/partner/home') }, 1000)
                props.handleOpen()
            }
            else
                setOptIn(true)

        } else {
            wipeOutPartnerData()
            handlePartnerSignout()
        }

        setLoading(false)
    }

    const openLink = (url: string) => window.open(url, '_blank')?.focus();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin(event)
        }
    }

    return (
        <>
            {
                props.open && (

                    <>
                        <div onClick={(e) => overlayHandler(e)} className="loginModalOverlay">
                            <div className="loginModal" onKeyPress={(e) => handleKeyPress(e)}>
                                <div className="loginModal__header">
                                    <img style={{ width: '65%', height: 'auto' }} src={logo} alt="" />
                                </div>

                                <div className="loginModal__content">
                                    <h5>Fazer login como parceiro</h5>

                                    <div className="loginModal__content__field">
                                        <label htmlFor="email">E-mail</label>
                                        <input disabled={loading} autoComplete="off" onChange={handleFieldValue} defaultValue={fields.userEmail} name="userEmail" id="email" type="email" placeholder="Ex: exemplo@gmail.com" />
                                    </div>

                                    <div className="loginModal__content__field">
                                        <label htmlFor="password">Senha</label>
                                        <input disabled={loading} autoComplete="off" onChange={handleFieldValue} defaultValue={fields.userPassword} name="userPassword" id="password" type="password" placeholder="Digite sua senha" />
                                    </div>

                                    <span onClick={() => { openLink("https://k8shomo.pmenos.com.br/portalfornecedor") }} className="forgotPass">
                                        Esqueceu sua senha?
                                    </span>
                                </div>

                                <div className="loginModal__footer">
                                    <button disabled={loading} className="primaryBtn" onClick={(e) => { handleLogin(e) }}>
                                        Continuar
                                    </button>
                                </div>

                                {loading && (
                                    <div style={{ backgroundColor: "white" }}>
                                        <BarLoader color="#0054a5" height={8} width={350} />
                                    </div>
                                )}
                                {!loading && (
                                    <span style={{ width: '100%', height: '8px', backgroundColor: 'white' }} />
                                )}
                            </div>
                        </div>

                        <OptInModal open={optIn} />
                    </>

                )
            }
        </>
    )
}
