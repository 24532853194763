interface FormatProps {
  dateString: string;
  pattern: "yyyy-mm-dd";
}

export function convert({ dateString, pattern }: FormatProps) {
  const splitDateString = dateString.split("/");

  switch (pattern) {
    case "yyyy-mm-dd":
      return `${splitDateString[2]?.padStart(4, "20")}-${splitDateString[1]}-${splitDateString[0]}`;
    default:
      return dateString;
  }
}

export function createNewDateUTC(date: string) {
  const splitDate = date.split("-");

  return new Date(
    Number(splitDate[0]),
    Number(splitDate[1]) - 1,
    Number(splitDate[2])
  );
}
