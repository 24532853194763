import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Badge } from "primereact/badge";
import { InputText } from "primereact/inputtext";
import { format } from "date-fns";

import { Footer } from "../../components/Footer";
import Header from "../../components/Header";
import Main from "../../components/Main";
import { ParceiroFiltroModal } from "../../components/ModalGroup/ParceiroFiltroModal";
import DashboardUser from "../../components/DashboardUser";
import { SimpleModal } from "../../components/ModalGroup/SimpleModal";
import { debounce } from "../../utils/debounce";
import { useLoader } from "../../hooks/loader";
import { partnerService } from "services/partnerService";

import { DashboardContainer, Content } from "./styles";
import { usePartnerContext } from "contexts/partnerContextProvider";
import warningImg from "images/arrowWarning.png";
import successImg from "images/arrowSuccess.png";
interface KeyValue {
  key: string;
  value: string;
}

interface ParceiroProps {
  idParceiro: number;
  razaoSocial: string;
  tipo?: string;
  nomeResponsavel: string;
  dataAtivacao?: Date;
  dataAtivacaoFormatter?: string;
  dataDesativacao?: Date;
  dataDesativacaoFormatter?: string;
  tipoParceiro?: KeyValue;
  status: boolean;
}

interface FilterProps {
  classificacao: string;
  inicio: string;
  termino: string;
}

export function Parceiros() {
  const history = useHistory();
  const { handleSetIsLoader } = useLoader();
  const [parceiro, setParceiro] = useState<ParceiroProps[]>([]);
  const { setIsLoading } = usePartnerContext();
  const [selectedParceria, setSelectedParceria] = useState<ParceiroProps>(
    {} as ParceiroProps
  );

  const updateStatus = (data: ParceiroProps) => {
    if (!data) return;    
    partnerService
      .updateStatus(data.idParceiro, !data.status)
      .then((response) => setIsLoading(true))
      .catch(e => (data.status)? setIsModalActivatePartnershipOpen(true) : setIsModalDeactivatePartnershipOpen(true))
      .finally(() => setIsLoading(false));
  };

  const [
    isModalDeactivatePartnershipOpen,
    setIsModalDeactivatePartnershipOpen,
  ] = useState(false);
  const [
    isModalDeactivatePartnershipSuccessOpen,
    setIsModalDeactivatePartnershipSuccessOpen,
  ] = useState(false);
  const [isModalActivatePartnershipOpen, setIsModalActivatePartnershipOpen] =
    useState(false);
  const [
    isModalActivatePartnershipSuccessOpen,
    setIsModalActivatePartnershipSuccessOpen,
  ] = useState(false);
  const optionsAction = [{ name: "Editar" }, { name: "Manage" }];
  // const optionsAction = [{ name: "Manage" }];
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    loadParcerias();
  }, []);

  const loadParcerias = async () => {
    handleSetIsLoader(true);
    const responseParceiros = await partnerService.getAll();
    if (responseParceiros?.length >= 0) {
      const parceiros = responseParceiros as ParceiroProps[];
      const formattedParcerias = parceiros.map((item: ParceiroProps) => {
        item.tipo = item.tipoParceiro ? item.tipoParceiro.value : "";
        delete item.tipoParceiro;
        item.dataAtivacaoFormatter = item.dataAtivacao
          ? format(new Date(item.dataAtivacao), "dd/MM/yyyy")
          : undefined;
        item.dataDesativacaoFormatter = item.dataDesativacao
          ? format(new Date(item.dataDesativacao), "dd/MM/yyyy")
          : undefined;
        return { ...item };
      });
      handleSetIsLoader(false);
      setParceiro(formattedParcerias);
    }
  };

  const handleCreateNewPartnership = () => {
    history.push("/portaladmin/parceiros/cadastro");
  };

  const actionOptionTemplate = (rowData: ParceiroProps, options: any) => {
    return (
      rowData.idParceiro && (
        <>
          {options.name === "Editar" && (
            <Button
              icon="pi pi-book"
              className="p-button-outlined"
              onClick={() => {
                history.push(
                  "/portaladmin/parceiros/editar?p=" + rowData.idParceiro,
                  {
                    data: rowData,
                  }
                );
              }}
              label="Editar"
            />
          )}
          {options.name === "Manage" && rowData.status && (
            <Button
              icon={`pi ${rowData.status ? "pi-times" : "pi-check"}`}
              className="p-button-outlined p-button-danger"
              onClick={() => {
                setSelectedParceria(rowData);
                rowData.status
                  ? setIsModalDeactivatePartnershipOpen(true)
                  : setIsModalActivatePartnershipOpen(true);
              }}
              label="Desativar"
            />
          )}
          {options.name === "Manage" && !rowData.status && (
            <Button
              icon={`pi ${rowData.status ? "pi-times" : "pi-check"}`}
              className="p-button-outlined p-button-danger"
              onClick={() => {
                setSelectedParceria(rowData);
                rowData.status
                  ? setIsModalDeactivatePartnershipOpen(true)
                  : setIsModalActivatePartnershipOpen(true);
              }}
              label="Ativar"
            />
          )}
        </>
      )
    );
  };

  const statusTemplate = (rowData: ParceiroProps) => {
    return rowData.status ? (
      <Badge value="Ativo" className="badgeActive"></Badge>
    ) : (
      <Badge value="Inativo" className="badgeInactive"></Badge>
    );
  };

  const actionBodyTemplate = (rowData: ParceiroProps) => {
    return (
      <>
        <Dropdown
          value={rowData}
          options={optionsAction}
          onChange={() => console.log(rowData)}
          placeholder="Ações"
          itemTemplate={(item) => actionOptionTemplate(rowData, item)}
        />
      </>
    );
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    setGlobalFilter(value);
  };

  const clearFilter = () => {
    setGlobalFilter("");
  };

  const header = () => (
    <div className="p-d-flex p-jc-between">
      <Button
        type="button"
        icon="pi pi-filter-slash"
        label="Limpar filtro"
        className="p-button-outlined"
        onClick={clearFilter}
      />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter}
          onChange={onGlobalFilterChange}
          placeholder="Pesquisar..."
        />
      </span>
    </div>
  );

  return (
    <>
      <Header />
      <Main>
        <DashboardContainer>
          <DashboardUser />
          <button type="button" onClick={handleCreateNewPartnership}>
            Cadastrar novo parceiro
          </button>
        </DashboardContainer>

        <Content>
          <div className="titleContainer">
            <div>
              <h2>GERENCIAMENTO DE PARCEIROS</h2>
            </div>
          </div>
          <div className="grid">
            <DataTable
              header={header()}
              globalFilter={globalFilter}
              value={parceiro}
              stripedRows
              columnResizeMode="fit"
              resizableColumns
              showGridlines
              scrollable
              scrollHeight="300px"
              className="p-datatable-sm"
              emptyMessage="Sem resultados para esta consulta."
            >
              <Column
                field="idParceiro"
                header="Id"
                headerClassName="custom-header"
                style={{ width: "3rem" }}
              ></Column>
              <Column
                field="razaoSocial"
                header="Parceiro"
                headerClassName="custom-header"
              ></Column>
              <Column
                field="tipo"
                header="Tipo"
                headerClassName="custom-header"
                style={{ width: "4rem" }}
              ></Column>
              <Column
                field="nomeResponsavel"
                header="Responsável"
                headerClassName="custom-header"
              ></Column>
              <Column
                field="dataAtivacaoFormatter"
                header="Início"
                headerClassName="custom-header"
                style={{ width: "7rem" }}
              ></Column>
              <Column
                field="dataDesativacaoFormatter"
                header="Término"
                headerClassName="custom-header"
                style={{ width: "7rem" }}
              ></Column>
              <Column
                field="status"
                header="Status"
                headerClassName="custom-header"
                body={statusTemplate}
                style={{ width: "4.5rem" }}
              ></Column>
              <Column
                body={actionBodyTemplate}
                headerClassName="custom-header"
                style={{ width: "9rem" }}
              ></Column>
            </DataTable>
          </div>
        </Content>
      </Main>
      <Footer />

      <SimpleModal
        isOpen={isModalDeactivatePartnershipOpen}
        title="Desativar parceiro"
        message="Deseja continuar com esta operação?"
        icon={warningImg}
        showCancel
        cancelButtonText="Voltar"
        buttonsMarginTop="21px"
        marginTitle="16px"
        confirm={() => {
          updateStatus(selectedParceria);
          setIsLoading(true);
          loadParcerias();
          setIsModalDeactivatePartnershipOpen(false);
          setIsModalDeactivatePartnershipSuccessOpen(true);
        }}
        onRequestClose={() => setIsModalDeactivatePartnershipOpen(false)}
      />

      <SimpleModal
        isOpen={isModalDeactivatePartnershipSuccessOpen}
        title="Sucesso"
        message="Parceiro foi desativado com sucesso."
        icon={successImg}
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="21px"
        marginTitle="16px"
        confirm={() => {setIsModalDeactivatePartnershipSuccessOpen(false); loadParcerias();}}
        onRequestClose={() => setIsModalDeactivatePartnershipSuccessOpen(false)}
      />

      <SimpleModal
        isOpen={isModalActivatePartnershipOpen}
        title="Ativar parceiro"
        message="Deseja continuar com esta operação?"
        icon={warningImg}
        showCancel
        cancelButtonText="Voltar"
        buttonsMarginTop="21px"
        marginTitle="16px"
        confirm={() => {
          updateStatus(selectedParceria);          
          loadParcerias();
          setIsModalActivatePartnershipOpen(false);
          setIsModalActivatePartnershipSuccessOpen(true);
        }}
        onRequestClose={() => setIsModalActivatePartnershipOpen(false)}
      />

      <SimpleModal
        isOpen={isModalActivatePartnershipSuccessOpen}
        title="Sucesso"
        message="Parceiro foi ativado com sucesso."
        icon={successImg}
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="21px"
        marginTitle="16px"
        confirm={() => {setIsModalActivatePartnershipSuccessOpen(false); loadParcerias();}}
        onRequestClose={() => setIsModalActivatePartnershipSuccessOpen(false)}
      />
    </>
  );
}
