import { propTypes } from "react-bootstrap/esm/Image";
import styled from "styled-components";

export const Container = styled.div`
  div.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 27px;
    margin-bottom: 14px;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  label {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    margin-top: 24px;
    margin-bottom: 0;

    color: #2a333e;
  }

  p {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    margin-top: 8px;

    color: #404f61;
  }
`;

interface OpenModalProps {
  openCalendar: boolean;
}
export const Content = styled.div<OpenModalProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div.calendar {
    margin-bottom: ${(props) => (props.openCalendar ? "100px" : "")};

    span {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #2a333e;
    }

    input {
      margin-top: 4px;
      width: 240px;
      height: 40px;

      border: 1px solid #aec0d2;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 8px 16px;

      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #2a333e;

      &::placeholder {
        color: #7b8fa5;
      }
    }

    img {
      width: 24px;
      height: 24px;

      color: #7b8fa5;

      margin-left: -35px;
    }
  }

  div.calendarComponent {
    margin-left: -40px;
    position: absolute;
  }

  div.horario {
    margin-top: 28px;

    div {
      left: 0;
    }

    span {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;

      color: #2a333e;
    }

    span.select {
      font-size: 16px;
    }
  }
`;

export const ButtonCancel = styled.button`
  width: 123px;
  height: 48px;

  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #0054a6;
  text-align: center;

  background: #ffffff;
  border: 1px solid #0054a6;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const ButtonContinue = styled.button`
  width: 131px;
  height: 48px;
  margin-left: 24px;

  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  text-align: center;

  background: #0054a6;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;

  :disabled {
    color: #404f61;
    background: #f2f2f2;
    cursor: not-allowed;
  }
`;
