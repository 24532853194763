import { useState } from "react";
import { Container, Select, Selected, CheckDiv, Overlay } from "./styles";
import SelectIcon from "images/icons/icon-select-option.svg";
import { Checkbox } from "components/Checkbox";

export interface ItemData {
  id: number;
  nome: string;
}

interface SelectStoreProps {
  itemsSelected: ItemData[];
  itemsList: ItemData[];
  handleSelectItem: React.Dispatch<React.SetStateAction<ItemData[]>>;
  label: string;
  placeholder: string;
}

export default function InputMultiSelect({
  itemsSelected,
  itemsList,
  handleSelectItem,
  label,
  placeholder
}: SelectStoreProps) {
  const [openSelectItem, setOpenSelectItem] = useState(false);

  function handleSelectAll() {
    handleSelectItem([...itemsList.map((item) => item)]);
  }

  function handleToggleSelected(itemData: ItemData) {
    if (itemsList?.length === 1)
      return;
    let newArray;
    //if (itemsSelected.includes(itemData)) {
    if (itemsSelected.filter((item) => item.id === itemData.id).length > 0) {
        newArray = itemsSelected.filter((item) => item.id !== itemData.id);
    } else {
        newArray = [...itemsSelected, itemData];
    }
    handleSelectItem(newArray);
  }

  return (
    <Container>
      <label>{label}</label>
      <Select
        onClick={() => setOpenSelectItem(!openSelectItem)}
        itemsSelected={itemsSelected.length}
      >
        {itemsSelected?.length === 0 ? (
          <span>{placeholder}</span>
        ) : (
          <span>
            {itemsSelected?.length === itemsList?.length
              ? "Todos os itens selecionadas"
              : `${itemsSelected?.length} itens selecionadas`}
          </span>
        )}

        <img src={SelectIcon} alt="select/option" id="selection" />
      </Select>

      {openSelectItem && (
        <>
          <Overlay onClick={() => setOpenSelectItem(false)} />
          <div id="marginScroll">
            <Selected>
              <div className="selected">
                <CheckDiv
                  isChecked={itemsSelected?.length === itemsList?.length}
                >
                  <Checkbox
                    checked={itemsSelected?.length === itemsList?.length}
                    onClick={() =>
                      !(itemsSelected?.length === itemsList?.length)
                        ? handleSelectAll()
                        : handleSelectItem([])
                    }
                    isSelectAll
                  />
                  <label>Selecionar todos</label>
                </CheckDiv>
                {!!itemsList?.length &&
                  itemsList?.map((item: any) => (
                    <CheckDiv
                      key={item.id}
                      isChecked={itemsSelected?.some(itemSelected => itemSelected.id === item.id)}
                      id="checkbox"
                    >
                      <Checkbox
                        checked={itemsSelected?.some(itemSelected => itemSelected.id === item.id)}
                        onClick={() => handleToggleSelected(item)}
                      />
                      <label>{item.id + ' - ' + item.nome}</label>
                    </CheckDiv>
                  ))}
              </div>
            </Selected>
          </div>
        </>
      )}
    </Container>
  );
}
