import './sidebarFilterSelects.scss'
import CitySelect, { clearCitiesSelectBox } from './CitySelect/citySelect'
import StateSelect, { clearStateSelectBox } from './StateSelect/stateSelect'
import StoreSelect, { clearStoreSelectBox } from './StoreSelect/storeSelect'
import { format } from 'date-fns-tz'
import { useEffect, useState } from 'react'
import { usePartnerContext } from 'contexts/partnerContextProvider'
import DateRangeModal from '../PartnerDateRangePickerModal/dateRangeModal'

export default function SidebarFilterSelects({ methods }) {
    const [wipeOutData, handleDashboardData] = methods
    const {
        isLoading,
        setIsLoading,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedState,
        setSelectedState,
        selectedCities,
        setSelectedCities,
        selectedStores,
        setSelectedStores
    } = usePartnerContext();

    const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);
    const [filterState, setFilterState] = useState("");
    const [filterCities, setFilterCities] = useState([""]);
    const [filterStores, setFilterStores] = useState([""]);

    useEffect(() => {
        setSelectedState(filterState)
    }, [filterState, selectedState]);

    useEffect(() => {
        setSelectedCities(filterCities)
    }, [filterCities, selectedCities]);

    useEffect(() => {
        setSelectedStores(filterStores)
    }, [filterStores, selectedStores]);

    const handleClear = () => {
        wipeOutFilters()
        wipeOutData()
        setTimeout(() => {
            wipeOutFilters()
            wipeOutData()
        }, 300);
    }

    const wipeOutFilters = () => {
        setSelectedState("")
        setFilterState("")
        clearStateSelectBox()

        setSelectedCities([""])
        setFilterCities([""])
        clearCitiesSelectBox()

        setSelectedStores([""])
        setFilterStores([""])
        clearStoreSelectBox()

        setStartDate(new Date())
        setEndDate(new Date())
    }

    return (
        <>
            <div id="sidebarFilterSelects" className="sidebarFilterSelects">

                <StateSelect
                    disabled={isLoading}
                    setFilterState={setFilterState}
                />

                <CitySelect
                    disabled={isLoading || selectedState === "" || selectedState === undefined || selectedState === null}
                    filterState={filterState}
                    setFilterCities={setFilterCities}
                />

                <StoreSelect
                    disabled={isLoading || (selectedCities[0] === "" || selectedCities[0] === undefined || selectedCities[0] === null)}
                    filterCities={filterCities}
                    setFilterStores={setFilterStores}
                />

                <div className="sidebarFilterSelects__filterDateRangePicker">
                    <span>Data dos agendamentos</span>
                    <p>Selecione a(s) data(s) para filtrar</p>
                    <button disabled={isLoading} className="datePickerTrigger" onClick={() => setDateRangeModalOpen(true)} >
                        {format(startDate, "dd/MM/yyyy")} ➟ {format(endDate, "dd/MM/yyyy")}
                    </button>
                </div>

                <div className="sidebarFilterSelects__filterActions">
                    <button onClick={() => handleClear()} disabled={isLoading} className="clearBtn">Limpar</button>
                    &nbsp;
                    &nbsp;
                    <button
                        onClick={() => handleDashboardData()}
                        disabled={
                            isLoading ||
                            (selectedStores[0] === "" || selectedStores[0] === undefined || selectedStores[0] === null) ||
                            (selectedCities[0] === "" || selectedCities[0] === undefined || selectedCities[0] === null)
                        }
                        className="filterBtn">
                        Filtrar
                    </button>
                </div>
            </div>

            <DateRangeModal open={dateRangeModalOpen} setOpen={setDateRangeModalOpen} context={null} />
        </>
    )
}
