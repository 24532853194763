import './pageContainer.scss'
import Header from 'components/Header'
import ResponsiveBox, {
    Row,
    Col,
    Item,
    Location
} from 'devextreme-react/responsive-box'
import UserCard from '../../components/DashboardUser/userCard'
import LoadingScreen from '../PartnerComponents/LoadingScreen/loadingScreen'

function screen(width: any): string {
    return (width < 700) ? 'sm' : 'lg';
}

export default function PageContainer({ content, sidebar }) {

    return (
        <>
            <Header />

            <div className="partnerPageContainer">
                <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
                    <Row ratio={1}></Row>
                    <Col ratio={1}></Col>
                    <Col ratio={4}></Col>

                    {/* Sidebar */}
                    <Item>
                        <Location row={0} col={0} screen="lg" />
                        <Location row={0} col={0} screen="sm" />
                        <div className="partnerPageContainer__sideBar">

                            <UserCard />

                            {sidebar}

                        </div>
                    </Item>

                    {/* Content */}
                    <Item>
                        <Location row={0} col={1} screen="lg" />
                        <Location row={1} col={0} screen="sm" />
                        <div className="partnerPageContainer__content">

                            {content}

                        </div>
                    </Item>
                </ResponsiveBox>
            </div>

            <LoadingScreen />
        </>
    )
}