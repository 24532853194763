import axiosConfig from "../config/axiosConfig";
import axios from "axios";
const axiosConfigCustom = Object.assign(axiosConfig, {
  validateStatus: (status: number) => status >= 200 && status < 300,
});

export const serviceService = {
  getAllActive: async () => {
    const req = await axios.get("/ServicoData/GetAllActive", axiosConfigCustom);
    const { data } = req;
    return data;
  }
};
