import styled from "styled-components";
import { colors } from "tokens";

export const DashboardContainer = styled.div`
  margin-right: 20px;

  button {
    width: 100%;
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  font-family: "Source Sans Pro";

  h2 {
    font-weight: 600;
    font-size: 22px;
    color: ${colors.dark};
    margin-bottom: 16px;
  }

  .tabHeader {
    display: flex;
    border-bottom: 1px solid ${colors.gray.light};
  }

  .contentBody {
    border: 1px solid ${colors.gray.light};
    border-radius: 4px;
    margin-bottom: 2rem;

    .p-card-body {
      padding-top: .5rem;

      .p-card-content {
        padding: 0;

        label:first-child {
          margin: 0;
        }
      }
    }

    .details {
      margin-top: 1.5rem;

      strong {
        color: ${colors.dark};
      }

      label:last-child {
        color: ${colors.gray.text};
      }

      .link {
        strong {
          color: ${colors.blue};
          text-decoration-line: underline;
          a {
            cursor: pointer;
          }
        }
      }
    }
  }

  .contentFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    button {
      &:disabled {
        opacity: 0.6;
        cursor: default;
      }
    }

    button + button {
      margin-left: 24px;
    }
  }
`;
