import styled from "styled-components";

export const Container = styled.div`
  div#marginScroll {
    z-index: 10;
    background: #ffffff;
    position: absolute;
    max-width: 290px;
    min-width: 290px;
    max-height: 366px;
    border: 1px solid #aec0d2;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

interface SelectProps {
  storesSelected: number;
}

export const Select = styled.div<SelectProps>`
  position: relative;
  max-width: 290px;
  height: 40px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: wrap;
  padding: 8px 16px;

  border: 1px solid #aec0d2;
  box-sizing: border-box;
  border-radius: 4px;

  background: ${props => props.storesSelected === 1 ? '#D7E2ED' : '#FFFFFF'};

  label.selectFix {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #7B8FA5;
  }

  &::placeholder {
    color: red;
  }

  span {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: ${({ storesSelected }) =>
      storesSelected === 0 ? "#7b8fa5" : "#404F61"};
  }

  img#selection {
    position: absolute;
    right: 8.6px;
  }
`;

interface CheckedProps {
  isChecked: boolean;
}

export const CheckDiv = styled.div<CheckedProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 264.44px;
  height: 34px;
  background: ${(props) => (props.isChecked ? "#D7E2ED" : "")};
  border-radius: 2px;
  margin-top: 16px;

  label {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7b8fa5;
    margin-left: 7.86px;
    margin-top: 5px;
  }

  :first-child {
    margin-top: 0;
  }
`;

export const Selected = styled.div`
  z-index: 11;
  background: #ffffff;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 7.86px;

  max-width: 274.1px;
  max-height: 334px;
  overflow-y: auto;
  overflow-x: clip;

  border-radius: 2px;

  ::-webkit-scrollbar {
    width: 4.92px;
  }

  ::-webkit-scrollbar-track {
    background: #e8edf3;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a7bed6;
    border-radius: 5px;
  }

  .selected > div {
    padding-left: 7.81px;
  }
`;
