import { createContext, useContext, ReactNode, useState } from "react";

import loadingImg from "../images/icons/loading.gif";
import { SimpleModal } from "../components/ModalGroup/SimpleModal";


interface LoaderContextData {
  handleSetIsLoader: (value: boolean) => void;
  isLoader: boolean;
}

interface LoaderProviderProps {
  children: ReactNode;
}

const LoaderContext = createContext({} as LoaderContextData);

function LoaderProvider({ children }:LoaderProviderProps) {
  const [isLoader, setIsLoader] = useState(false);
  
  function handleSetIsLoader(value: boolean) {
    setIsLoader(value);
  }

  return (
    <LoaderContext.Provider
      value={{handleSetIsLoader, isLoader}}
    >
     {isLoader && <SimpleModal
        isOpen={isLoader}
        title="Carregando informações"
        message=""
        hideButtons
        icon={loadingImg}
        onRequestClose={() => {}}
        confirm={() => {}}
        isAnimation={true}
      />}
      {children}
    </LoaderContext.Provider>
  );
}

function useLoader(): LoaderContextData {
  const context = useContext(LoaderContext);

  return context;
}

export { LoaderProvider, useLoader };
