import iconStatusConfirmed from "../../../../../images/icons/icon-confirmed.svg";
import iconStatusNext from "../../../../../images/icons/icon-next.svg";
import iconStatusCanceled from "../../../../../images/icons/icon-canceled.svg";
import iconStatusPreSale from "../../../../../images/icons/icon-pre-sale.svg";
import iconStatusExecuteService from "../../../../../images/icons/icon-execute-service.svg";
import iconStatusPayout from "../../../../../images/icons/icon-payout.svg";
import iconStatusWarning from "../../../../../images/icons/icon-status-warning.svg";

export const statusIconMap = [
    {
        status: 0,
        icon: <img src={iconStatusNext} alt="Próximo agendamento" />,
        text: "Próximo agendamento"
    },
    {
        status: 1,
        icon: <img src={iconStatusConfirmed} alt="Agendamento confirmado" />,
        text: "Agendamento confirmado"
    },
    {
        status: 2,
        icon: <img src={iconStatusCanceled} alt="Agendamento cancelado" />,
        text: "Agendamento cancelado"
    },
    {
        status: 3,
        icon: <img src={iconStatusExecuteService} alt="Agendamento utilizado" />,
        text: "Agendamento utilizado"
    },
    {
        status: 4,
        icon: <img src={iconStatusWarning} alt="Não compareceu" />,
        text: "Não compareceu"
    },
    {
        status: 5,
        icon: <img src={iconStatusPreSale} alt="Pré-venda gerada para o agendamento" />,
        text: "Pré-venda gerada para o agendamento"
    },
    {
        status: 6,
        icon: <img src={iconStatusPayout} alt="Agendamento pago" />,
        text: "Agendamento pago"
    },
    {
        status: 7,
        icon: <img src={iconStatusPayout} alt="Agendamento pago" />,
        text: "Agendamento pago"
    }
]

export default function StatusIcon({ data }) {

    const icon = () => {
        return statusIconMap.find(
            (iconMap) => iconMap.status == data.status
        )
    }

    return (
        <>
            <span
                title={icon()?.text}
                id={`status${data.status}`}>
                {icon()?.icon}
            </span>
        </>

    )
}