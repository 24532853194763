import { useEffect, useState } from "react";
import axios from "axios";

import { Grid } from "components/Grid";
import Button from "components/Button";

import { Container } from "./styles";
import { ModalBase } from "../ModalBase";

import IconCloseModal from "images/icons/icon-close-modal.png";
import IconSearch from "images/icons/icon-search.svg";
import { debounce } from "utils/debounce";

interface ModalProps {
  idParceria: number;
  isOpen: boolean;
  onRequestClose: () => void;
}

interface HistoricoData {
  id: number;
  data: string;
  colaborador: string;
  codigoColaborador: string;
}

export function HistoricEditions({
  idParceria,
  isOpen,
  onRequestClose,
}: ModalProps) {
  const [historico, setHistorico] = useState<HistoricoData[]>([]);
  const [historicoFiltrado, setHistoricoFiltrado] = useState<HistoricoData[]>(
    []
  );
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    (async () => {
      const response = await axios.get(
        `/BeneficioData/GetHistoricoEdicao/?idTabela=${idParceria}`
      );
      
      setHistorico(response.data.items);
      setHistoricoFiltrado(response.data.items);
    })();
  }, [idParceria]);

  useEffect(() => {
    debounce(() => {
      if (!filterText) {
        if (historico?.length) {
          setHistoricoFiltrado(historico);
        }
        return;
      }

      setHistoricoFiltrado(
        historico.filter(
          (item) =>
            item.data.toLowerCase().includes(filterText.toLowerCase()) ||
            item.colaborador.toLowerCase().includes(filterText.toLowerCase()) ||
            item.codigoColaborador
              .toLowerCase()
              .includes(filterText.toLowerCase())
        )
      );
    }, 500);
  }, [filterText]);

  return (
    <ModalBase
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      type="large-width"
    >
      <img
        src={IconCloseModal}
        alt="closeModal"
        onClick={onRequestClose}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          cursor: "pointer",
        }}
      />
      <Container>
        <div className="titulo">
          <strong>Histórico de edições</strong>
        </div>
        <div className="busca">
          <input
            type="text"
            placeholder="Busca"
            style={{
              width: "520px",
            }}
            value={filterText}
            onChange={(event) => setFilterText(event.target.value)}
          />
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.9624 11.4716H12.7629L17.0595 15.7316C17.4749 16.1416 17.4749 16.8116 17.0595 17.2216C16.644 17.6316 15.9651 17.6316 15.5496 17.2216L11.2429 12.9716V12.1816L10.9693 11.9016C9.55068 13.1016 7.61521 13.7216 5.55814 13.3816C2.74108 12.9116 0.491476 10.5916 0.146943 7.79157C-0.379991 3.56157 3.22748 0.00157288 7.51388 0.521573C10.3512 0.861573 12.7021 3.08157 13.1784 5.86157C13.5229 7.89157 12.8947 9.80157 11.6787 11.2016L11.9624 11.4716ZM2.12294 6.97157C2.12294 9.46157 4.15974 11.4716 6.68294 11.4716C9.20615 11.4716 11.2429 9.46157 11.2429 6.97157C11.2429 4.48157 9.20615 2.47157 6.68294 2.47157C4.15974 2.47157 2.12294 4.48157 2.12294 6.97157Z"
              fill="#7B8FA5"
            />
          </svg>
        </div>
        <div className="grid">
          <Grid
            columns={["Matrícula", "Colaborador", "Data"]}
            columnsWidth={[
              { column: "Matrícula", width: "128px" },
              { column: "Colaborador", width: "332px" },
              { column: "Data", width: "106px" },
            ]}
            omit={["id"]}
            maxHeight="400px"
            customStyle={{minHeight: "400px"}}
            data={historicoFiltrado}
          />
        </div>
        <div className="botao">
          <Button
            style={{
              background: "#FFF",
              color: "#0054A6",
              border: "1px solid #0054A6",
            }}
            width="123px"
            onClick={onRequestClose}
          >
            Fechar
          </Button>
        </div>
      </Container>
    </ModalBase>
  );
}
