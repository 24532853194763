import React, { createContext, ReactNode, useCallback, useContext, useState } from "react";
import { format, addHours, addMinutes, isAfter, isBefore, isEqual, formatDistance } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { SimpleModal } from 'components/ModalGroup/SimpleModal';
import { ModalRescheduling } from "components/ModalGroup/ModalRescheduling";
import { MotivoReagendamento } from "components/ModalGroup/MotivoReagendamento";

import iconSuccess from "images/icons/icon-sucess.svg";
import iconError from "images/icons/icon-acess-denied.svg";
import iconAlert from "images/icons/icon-alert2.svg";
import { convert, titleCase } from "utils";
import axios from "axios";
import pt from "date-fns/locale/pt";
import { useLoader } from "./loader";

interface FormData {
  data: string;
  periodo: string;
  horario: string;
}

interface AgendamentoSelecionado {
  idAgendamento: number;
  idServicos: number[];
  loja: number;
  idParceiro?: number;
}

interface MotivoReagendamentoData {
  motivoSelecionado: number;
  descricaoMotivo: string;
}

interface ReschedulingContextData {
  handleRequestReschedule: (appointmentData: any) => void;
  agendamentoSelecionado: AgendamentoSelecionado;
  setAgendamentoSelecionado: React.Dispatch<React.SetStateAction<AgendamentoSelecionado>>;
}

interface ReschedulingProviderProps {
  children: ReactNode;
}

const ReschedulingContext = createContext({} as ReschedulingContextData);

function ReschedulingProvider({ children }: ReschedulingProviderProps) {
  const { handleSetIsLoader } = useLoader();
  const [formReagendamento, setFormReagendamento] = useState<FormData>({} as FormData);
  const [motivo, setMotivo] = useState<MotivoReagendamentoData>({} as MotivoReagendamentoData);
  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState<AgendamentoSelecionado>({} as AgendamentoSelecionado);

  const [isModalReschedulingAlertOpen, setIsModalReschedulingAlertOpen] = useState(false);
  const [isModalReschedulingOpen, setIsModalReschedulingOpen] = useState(false);
  const [isModalReschedulingSuccessOpen, setIsModalReschedulingSuccessOpen] = useState(false);
  const [isModalReschedulingErrorOpen, setIsModalReschedulingErrorOpen] = useState(false);
  const [reschedulingErrorMessage, setReschedulingErrorMessage] = useState<string | ReactNode>("");
  const [modalReschedulingReasonOpen, setModalReschedulingReasonOpen] = useState(false);
  const [isModalConfirmReschedulingOpen, setIsModalConfirmReschedulingOpen] = useState(false);

  const handleRequestReschedule = useCallback((appointmentData: any) => {
    const [dia,mes,ano] = appointmentData.data.split("/");
      
    const [hora,minuto] = appointmentData.hora.split(":");
    
    const dateReschedule = new Date(`${mes}/${dia}/${ano}`);
    dateReschedule.setHours(0, 0, 0, 0);

    const newDate = new Date();
    newDate.setHours(0, 0, 0, 0);

    if(isAfter(newDate, dateReschedule)){
      openModalError("Não é possível realizar um reagendamento após a data do agendamento marcado.")
      return;
    }

    setIsModalReschedulingAlertOpen(true);
  }, [isModalReschedulingSuccessOpen]);

  async function reschedule() {
    try {
      handleSetIsLoader(true);
      const hash = localStorage.getItem("@portalAdmin:hash") || "";

      let dataAgendamento = formReagendamento.data + " " + formReagendamento.horario;
      
      console.log(agendamentoSelecionado);


      const response = await axios.post(
        "/AgendamentoData/Reagendar",
        {
          idAgendamento: agendamentoSelecionado.idAgendamento,
          dataAgendamento,
          idMotivo: motivo.motivoSelecionado,
          motivo: motivo.descricaoMotivo,
          idParceiro: agendamentoSelecionado?.idParceiro
        },
        {
          headers: {
            pm_hash: hash
          },
        }
        );

      handleSetIsLoader(false);
      if (response.data.result !== "Success") {
        console.log(response.data);
        
        openModalError(response.data.message);
        return;
      }

      setIsModalReschedulingSuccessOpen(true);
    } catch(error){
      handleSetIsLoader(false);
      openModalError("Não foi possível realizar a ação de reagendamento");
    }
  }

  function openModalError(message: string | ReactNode) {
    setIsModalReschedulingErrorOpen(true);
    setReschedulingErrorMessage(message);
  }

    function getPeriodo(date: string) {
        const _date = new Date(`${convert({ dateString: date, pattern: "yyyy-mm-dd" })} ` + formReagendamento.horario);
        console.log(date, _date.getHours());
        if (_date.getHours() >= 6 && _date.getHours() < 12)
            return 'Manhã';
        else if (_date.getHours() >=12 && _date.getHours() < 18)
            return 'Tarde';
        else if ((_date.getHours() >= 18 && _date.getHours() < 23) || (_date.getHours() >= 0 && _date.getHours() < 6))
            return 'Noite';

    }

  return (
    <ReschedulingContext.Provider
      value={{ handleRequestReschedule, agendamentoSelecionado, setAgendamentoSelecionado }}>
      {children}

      {isModalReschedulingAlertOpen && <SimpleModal
        onRequestClose={() => {
          setIsModalReschedulingAlertOpen(false);
        }}
        confirm={() => {
          setIsModalReschedulingAlertOpen(false);
          setIsModalReschedulingOpen(true);
        }}
        isOpen={isModalReschedulingAlertOpen}
        icon={iconAlert}
        title="Atenção"
        marginTitle="21px"
        message={
          <>
            <strong>O cliente foi informando do reagendamento?</strong> <br/> <br/> Caso não, pare este reagendamento e o informe<br/> antes de prosseguir.
            <br /> <br />
            Se o reagendamento é uma solicitação do cliente, basta prosseguir
          </>
        }
        showCancel
        buttonFontFamily="Source Sans Pro"
        buttonsMarginTop="32px"
        cancelButtonText="Cancelar"
        continueButtonText="Prosseguir"
      />}

      {isModalReschedulingOpen && <ModalRescheduling
        isOpen={isModalReschedulingOpen}
        confirm={(formData: FormData) => {
          setFormReagendamento(formData);
          setIsModalReschedulingOpen(false);
          setModalReschedulingReasonOpen(true);
        }}
        onRequestClose={() => {
          setIsModalReschedulingOpen(false)
        }}
        agendamentoSelecionado={agendamentoSelecionado}
      />}

      {modalReschedulingReasonOpen && <MotivoReagendamento
        isOpen={modalReschedulingReasonOpen}
        onRequestClose={() => setModalReschedulingReasonOpen(false)}
        onContinue={(motivoData: MotivoReagendamentoData) => {
          setMotivo(motivoData);
          setModalReschedulingReasonOpen(false);
          setIsModalConfirmReschedulingOpen(true);
        }}
      />}

      {isModalConfirmReschedulingOpen && <SimpleModal
        isOpen={isModalConfirmReschedulingOpen}
        onRequestClose={() => setIsModalConfirmReschedulingOpen(false)}
        confirm={() => {
          setIsModalConfirmReschedulingOpen(false);
          reschedule();
        }}
        icon={iconAlert}
        title="Confirmar reagendamento"
        marginTitle="13px"
        showCancel
        cancelButtonText="Cancelar"
        continueButtonText="Reagendar"
        buttonsMarginTop="25px"
        message={
          `Deseja realizar o reagendamento para o dia ${formReagendamento.data} 
          (${titleCase(format(new Date(`${convert({ dateString: formReagendamento.data, pattern: "yyyy-mm-dd" })} 00:00:00`), 'eeee', { locale: ptBR }))}) 
          às ${formReagendamento.horario} da ${getPeriodo(formReagendamento.data)}?`
        }
        messageFontSize="16px"
        messageFontFamily="Source Sans Pro"
        buttonFontFamily="Source Sans Pro"
      />}

      {isModalReschedulingSuccessOpen && <SimpleModal
        onRequestClose={() => {
          setIsModalReschedulingSuccessOpen(false);
        }}
        confirm={() => {
          setIsModalReschedulingSuccessOpen(false);
        }}
        isOpen={isModalReschedulingSuccessOpen}
        icon={iconSuccess}
        title={
          <>
            Reagendamento <br />
            realizado com sucesso
          </>
        }
        message={
          <>
            Pronto, o reagendamento foi realizado. <br />
            O agendamento anterior foi cancelado e um novo foi <br />
            criado, mantendo serviços já selecionados e a nova <br />
            data e horário.
          </>
        }
        messageFontSize="16px"
        messageFontFamily="Source Sans Pro"
        marginTitle="16px"
        buttonsMarginTop="25px"
        buttonFontFamily="Source Sans Pro"
        widthButtonContinue="179px"
        continueButtonText="Fechar"
      />}

      {isModalReschedulingErrorOpen && <SimpleModal
        onRequestClose={() => {
          setIsModalReschedulingErrorOpen(false);
        }}
        confirm={() => {
          setIsModalReschedulingErrorOpen(false);
        }}
        isOpen={isModalReschedulingErrorOpen}
        icon={iconError}
        title="Reagendamento não realizado"
        marginTitleBottom="10px"
        message={reschedulingErrorMessage}
        marginTitle="18px"
        buttonsMarginTop="33px"
        widthButtonContinue="110px"
        continueButtonText="Fechar"
      />}
    </ReschedulingContext.Provider>
  );
}

function useRescheduling(): ReschedulingContextData {
  const context = useContext(ReschedulingContext);

  return context;
}

export { ReschedulingProvider, useRescheduling };
