import "./scheduleControl.scss";
import { useEffect, useState } from "react";
import PageContainer from "../../components/PageContainer/pageContainer";
import { ReactComponent as DeactivateIcon } from "../../images/icons/calendar-times-outlined_1.svg";
import { ReactComponent as ActivateIcon } from "../../images/icons/calendar-check-outlined_1.svg";
import StoresSelect from "../../components/StoresSelect/storesSelect";
import WeekView from "components/PartnerComponents/WeekView/weekView";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";
import { usePartnerContext } from "../../contexts/partnerContextProvider";
import { partnerDashboardService } from "../../services/partnerDashboardService";
import { partnerScheduleControlService } from "services/partnerScheduleControlService";
import { useAuth } from "hooks/auth";

function ConfigurarAgenda() {
  const [scheduleData, setScheduleData] = useState<any>(null);
  const [actives, setActives] = useState(0);
  const { setIsLoading } = usePartnerContext();
  const { matricula, username, cargo } = useAuth();
  const userData = {
    cod: matricula,
    name: username,
    role: cargo,
  };

  useEffect(() => {
    function checkActives() {
      let actives = 0;
      scheduleData?.forEach((s) => {
        if (s.ativo) actives++;
      });

      return actives;
    }

    setActives(checkActives());
  }, [scheduleData]);

  const handleRoomStatus = async (roomData, action) => {
    const { idLojaSala, nomeSala } = roomData;

    switch (action) {
      case "DEACTIVATE": {
        Swal.fire({
          title: "Atenção!",
          html: `
                <strong style="color: orange">Ao desativar esta sala, você estará indisponibilizando agendamentos referentes a ela, inclusive agendamentos de parceiros!</strong>
                <br>
                <br>
                <p>Tem certeza que deseja prosseguir com a desativação da sala <strong>"${nomeSala}"</strong>?</p>
            `,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0054A6",
          cancelButtonColor: "#ed1d24",
          confirmButtonText: "Prosseguir",
          cancelButtonText: "Abandonar",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true);

            const reasons =
              await partnerScheduleControlService.acquireReasons();

            setIsLoading(false);

            let items = { "0": "OUTRO" };

            reasons.forEach((reason) => {
              const { value, key } = reason;
              items[`${key}`] = value;
            });

            const handleText = async () => {
              const { value: text } = await Swal.fire({
                input: "textarea",
                inputLabel: "Motivo da desativação desta sala",
                confirmButtonColor: "#0054A6",
                confirmButtonText: "Confirmar",
                inputPlaceholder: "Descreva brevemente o motivo...",
                inputAttributes: {
                  "aria-label": "Descreva brevemente o motivo...",
                },
                showCancelButton: false,
                allowOutsideClick: false,
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                    if (value && value.trim().length) {
                      resolve(null);
                    } else {
                      resolve("Por favor, descreva o motivo acima");
                    }
                  });
                },
              });

              return text;
            };

            if (reasons !== null) {
              let text = "...";

              const { value: reason } = await Swal.fire({
                title: "Motivo da desativação",
                input: "select",
                inputOptions: items,
                inputPlaceholder: "Selecione o motivo",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonColor: "#0054A6",
                cancelButtonColor: "#ed1d24",
                confirmButtonText: "Prosseguir",
                cancelButtonText: "Abandonar",
                reverseButtons: true,
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                    if (value && value.length) {
                      resolve(null);
                    } else {
                      resolve("Por favor, selecione o motivo acima");
                    }
                  });
                },
              });

              if (reason) {
                if (reason === "0") {
                  text = await handleText();
                }

                setIsLoading(true);

                const sendObj = {
                  IdLojaSala: idLojaSala,
                  Motivo: text,
                  IdDesativacaoSalaMotivo: parseInt(`${reason}`),
                };

                setIsLoading(true);

                const managStatus =
                  await partnerScheduleControlService.manageRoomStatus(
                    sendObj,
                    userData
                  );

                setIsLoading(false);

                if (managStatus) {
                  Swal.fire({
                    title: "Desativação de Sala",
                    html: `A sala <strong>"${nomeSala}"</strong> foi desativada com sucesso!`,
                    icon: "success",
                    confirmButtonColor: "#0054A6",
                    confirmButtonText: "Entendi",
                  }).then(() => {
                    setIsLoading(true);
                    window.location.reload();
                  });
                }
              }
            }
          } else {
            Swal.fire({
              title: "Atenção!",
              html: "Para desativar esta sala, primeiro entre em contato com os possíveis parceiros que também a utilizam.",
              icon: "error",
              confirmButtonColor: "#0054A6",
              confirmButtonText: "Entendi",
            });
          }
        });

        break;
      }

      case "REACTIVATE": {
        Swal.fire({
          title: "Reativar Sala",
          html: `Deseja prosseguir com a reativação da sala <strong>"${nomeSala}"</strong>?</p>
            `,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#0054A6",
          cancelButtonColor: "#ed1d24",
          confirmButtonText: "Prosseguir",
          cancelButtonText: "Abandonar",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            setIsLoading(true);

            const sendObj = {
              IdLojaSala: idLojaSala,
              action,
              Motivo: "...",
              IdDesativacaoSalaMotivo: -1,
            };

            const managStatus =
              await partnerScheduleControlService.manageRoomStatus(
                sendObj,
                userData
              );

            setIsLoading(false);

            if (managStatus) {
              Swal.fire({
                title: "Reativacão de Sala",
                html: `A sala <strong>"${nomeSala}"</strong> foi reativada com sucesso!`,
                icon: "success",
                confirmButtonColor: "#0054A6",
                confirmButtonText: "Entendi",
              }).then(() => {
                setIsLoading(true);
                window.location.reload();
              });
            }
          }
        });

        break;
      }
    }
  };

  const RoomTabs = () => {
    return (
      <>
        <Tabs style={{ padding: "8px" }} defaultIndex={0}>
          <TabList>
            {scheduleData.map((s, index) => {
              return (
                <Tab key={index}>
                  {s.ativo ? (
                    <strong>{s.nomeSala}</strong>
                  ) : (
                    <strong style={{ color: "#ccc" }}>{s.nomeSala}</strong>
                  )}
                </Tab>
              );
            })}
          </TabList>

          {scheduleData.map((schedule, index) => {
            return (
              <TabPanel key={index}>
                <div className="scheduleControlContent__header">
                  {!schedule.ativo && (
                    <div className="scheduleControlContent__header__info">
                      Sala desativada
                    </div>
                  )}
                  <div className="scheduleControlContent__header__title">
                    <h1>
                      Controle de Agenda{" "}
                      {scheduleData !== null ? `- ${schedule.nomeSala}` : ""}
                    </h1>

                    {scheduleData.length > 1 &&
                      (schedule.ativo ? (
                        actives > 1 && (
                          <button
                            className="deactivateBtn"
                            title="Desativar sala"
                            onClick={() =>
                              handleRoomStatus(schedule, "DEACTIVATE")
                            }
                          >
                            <DeactivateIcon />
                            &nbsp;Desativar esta sala
                          </button>
                        )
                      ) : (
                        <button
                          className="reactivateBtn"
                          title="Reativar sala"
                          onClick={() =>
                            handleRoomStatus(schedule, "REACTIVATE")
                          }
                        >
                          <ActivateIcon />
                          &nbsp;Reativar esta sala
                        </button>
                      ))}
                  </div>
                  <h4>
                    Ajuste os horários desejados nos dias da semana abaixo
                  </h4>
                </div>

                <WeekView scheduleData={schedule} />
              </TabPanel>
            );
          })}
        </Tabs>
      </>
    );
  };

  return (
    <PageContainer
      sidebar={<StoresSelect setScheduleData={setScheduleData} />}
      content={
        <div className="scheduleControlContent">
          <div className="scheduleControlContent__header">
            {scheduleData === null && (
              <>
                <h1>Controle de Agenda</h1>
                <h4>
                  Selecione a loja para programar os horários de atendimento
                </h4>
              </>
            )}
          </div>
          {scheduleData !== null && <RoomTabs />}
        </div>
      }
    />
  );
}

export { ConfigurarAgenda };
