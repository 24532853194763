import { useState } from "react";
import "./configLandingPageStyles.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ImageInput from "../../../components/ImageInput/imageInput";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { partnershipLandingPageService } from "services/partnershipLandingPageService";
import { useLoader } from "hooks/loader";

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],

    [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};

export default function ConfigLandingPage({ isOpen, setIsOpen, formData }) {
  const [landingPageData, setLandingPageData] = useState(formData);
  const { handleSetIsLoader } = useLoader();
  const [bannerTitle, setBannerTitle] = useState(landingPageData.bannerTitle);
  const [bannerTitleError, setBannerTitleError] = useState("");
  const [bannerDescription, setBannerDescription] = useState(
    landingPageData.bannerDescription
  );
  const [bannerDescriptionError, setBannerDescriptionError] = useState("");
  const [urlTermsConditions, setUrlTermsConditions] = useState(
    landingPageData?.urlTermsConditions ?? ""
  );

  const [urlDataUsage, setUrlDataUsage] = useState(
    landingPageData?.urlDataUsage ?? ""
  );

  const [descriptiveContent, setDescriptiveContent] = useState(
    landingPageData.descriptiveContent
  );
  const [descriptiveContentError, setDescriptiveContentError] = useState("");
  const [urlTermsConditionsError, setUrlTermsConditionsError] = useState("");
  const [urlDataUsageError, setUrlDataUsageError] = useState("");
  const [logoImage, setLogoImage] = useState([]);
  const [logoImageError, setLogoImageError] = useState("");
  const [bannerImage, setBannerImage] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  const handleCloseModal = () => {
    if (hasChanges) {
      Swal.fire({
        title: "Abandonar configuração",
        html: `Podem existir alterações não salvas, deseja abandonar a configuração da landing-page da parceria <strong>"${landingPageData.name}"</strong>?</p>
            `,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#0054A6",
        cancelButtonColor: "#ed1d24",
        confirmButtonText: "Continuar editando",
        cancelButtonText: "Abandonar",
        reverseButtons: true,
      }).then(async (result) => {
        if (!result.isConfirmed) {
          document.body.style.overflowY = "unset";
          setIsOpen(false);
        }
      });
    } else {
      document.body.style.overflowY = "unset";
      setIsOpen(false);
    }
  };

  const handleSendData = async () => {
    let containsError = false;

    if (bannerTitle.trim() === "" || bannerTitle.trim().length < 5) {
      setBannerTitleError("A descrição do banner é obrigatória.");
      containsError = true;
    } else {
      setBannerTitleError("");
    }

    if (
      bannerDescription.trim() === "" ||
      bannerDescription.trim().length < 5
    ) {
      setBannerDescriptionError("A descrição do banner é obrigatória.");
      containsError = true;
    } else {
      setBannerDescriptionError("");
    }

    if (!landingPageData.logoImage) {
      if (!logoImage.length) {
        setLogoImageError("A imagem do logo é obrigatória.");
        containsError = true;
      }
    } else {
      setLogoImageError("");
    }

    const untaggedDescriptiveContent = descriptiveContent.replace(
      /(<([^>]+)>)/gi,
      ""
    );
    if (
      untaggedDescriptiveContent.trim() === "" ||
      untaggedDescriptiveContent.trim().length < 5
    ) {
      setDescriptiveContentError(
        "O texto descritivo do corpo da página é obrigatório."
      );
      containsError = true;
    } else {
      setDescriptiveContentError("");
    }

    if (
      urlTermsConditions.trim() === "" ||
      urlTermsConditions.trim().length < 5
    ) {
      setUrlTermsConditionsError("A URL dos Termos e Condições é obrigatória");
      containsError = true;
    } else {
      setUrlTermsConditionsError("");
    }

    if (urlDataUsage.trim() === "" || urlDataUsage.trim().length < 5) {
      setUrlDataUsageError("A URL da Política de Uso de Dados é obrigatória");
      containsError = true;
    } else {
      setUrlDataUsageError("");
    }

    if (!containsError) {
      handleSetIsLoader(true);

      const sendObj = {
        id: landingPageData.id,
        landingPageData: {
          bannerTitle,
          bannerDescription,
          logoImage: logoImage[0]?.data_url || null,
          logoImageExtension: logoImage[0]?.file.name.split(".")[1] || null,
          bannerImage: bannerImage[0]?.data_url || null,
          bannerImageExtension: bannerImage[0]?.file.name.split(".")[1] || null,
          descriptiveContent,
          urlTermsConditions,
          urlDataUsage,
        },
      };

      const req = await partnershipLandingPageService.updateLandingPageData(
        sendObj
      );

      if (req !== null) {
        document.body.style.overflowY = "unset";
        setIsOpen(false);
        handleSetIsLoader(false);

        Swal.fire({
          title: "Configuração de landing page",
          html: `As informações da landing page da parceria <strong>"${landingPageData.name}"</strong> foram atualizadas com sucesso!`,
          icon: "success",
          confirmButtonColor: "#0054A6",
          confirmButtonText: "Entendi",
        });
      }

      handleSetIsLoader(false);
    }
  };

  const handleRemoveBannerImage = async () => {
    Swal.fire({
      title: "Remover banner",
      html: "Deseja remover a imagem do banner atual? Será exibido um banner padrão na landing-page.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#0054A6",
      cancelButtonColor: "#ed1d24",
      confirmButtonText: "Remover banner",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleSetIsLoader(true);

        const rem = await partnershipLandingPageService.removeBannerImage(
          landingPageData.id
        );

        if (rem) {
          document.body.style.overflowY = "unset";
          setIsOpen(false);
          handleSetIsLoader(false);

          Swal.fire({
            title: "Remover banner",
            html: `Imagem do banner da landing page da parceria <strong>"${landingPageData.name}"</strong> foi removido com sucesso!`,
            icon: "success",
            confirmButtonColor: "#0054A6",
            confirmButtonText: "Entendi",
          });
        }

        handleSetIsLoader(false);
      }
    });
  };

  return (
    <>
      {isOpen && (
        <div className="configLandingPageOverlay">
          <div className="configLandingPageModal">
            <div className="configLandingPageModal__header">
              <h1>Configurar landing page de auto-cadastro</h1>
              <h2>
                {landingPageData.type}: {landingPageData.name}
              </h2>
            </div>

            <div className="configLandingPageModal__content">
              <label htmlFor="bannerTitle">Título do banner</label>
              <InputText
                style={{ width: "50%" }}
                id="bannerTitle"
                aria-describedby="bannerTitle-help"
                className={bannerTitleError ? "p-invalid" : undefined}
                value={bannerTitle}
                onChange={(e) => {
                  setBannerTitle(e.target.value);
                  setHasChanges(true);
                }}
              />
              {bannerTitleError !== "" && (
                <small id="bannerTitle-help" className="p-error">
                  {bannerTitleError}
                </small>
              )}

              <br />

              <label htmlFor="bannerDescription">Descrição do banner</label>
              <InputText
                style={{ width: "100%" }}
                id="bannerDescription"
                aria-describedby="bannerDescription-help"
                className={bannerDescriptionError ? "p-invalid" : undefined}
                value={bannerDescription}
                onChange={(e) => {
                  setBannerDescription(e.target.value);
                  setHasChanges(true);
                }}
              />
              {bannerDescriptionError !== "" && (
                <small id="bannerDescription-help" className="p-error block">
                  {bannerDescriptionError}
                </small>
              )}

              <br />

              <div
                id="imageInputs"
                className="configLandingPageModal__content__imageInputs"
              >
                <ImageInput
                  labelText={"Imagem do logo"}
                  id={"logoImage"}
                  multiple={false}
                  maxWidth={220}
                  maxHeight={80}
                  image={logoImage}
                  setImage={setLogoImage}
                  errorMessage={logoImageError}
                  existentImage={landingPageData.logoImage}
                  setHasChanges={setHasChanges}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ImageInput
                  labelText={"Imagem do banner"}
                  id={"bannerImage"}
                  multiple={false}
                  maxWidth={1500}
                  maxHeight={600}
                  image={bannerImage}
                  setImage={setBannerImage}
                  existentImage={landingPageData.bannerImage}
                  setHasChanges={setHasChanges}
                  removableImage={handleRemoveBannerImage}
                />
              </div>

              <br />

              <label htmlFor="descriptiveContent">Texto descritivo</label>
              <ReactQuill
                id={"descriptiveContent"}
                theme="snow"
                modules={modules}
                value={descriptiveContent}
                onChange={(e) => {
                  setDescriptiveContent(e);
                  setHasChanges(true);
                }}
              />
              <br />
              <br />
              {descriptiveContentError !== "" && (
                <small style={{ marginTop: "10px" }} className="p-error block">
                  {descriptiveContentError}
                </small>
              )}
              <br />
              <br />

              <div className="configLandingPageModal__content__accept">
                <div className="configLandingPageModal__content__accept__block">
                  <label htmlFor="termoCondicoes">Termo de Condições</label>
                  <InputText
                    style={{ width: "100%" }}
                    id="termoCondicoes"
                    aria-describedby="termoCondicoes-help"
                    className={
                      urlTermsConditionsError ? "p-invalid" : undefined
                    }
                    value={urlTermsConditions}
                    required
                    onChange={(e) => {
                      setUrlTermsConditions(e.target.value);
                      setHasChanges(true);
                    }}
                  />
                  {urlTermsConditionsError !== "" && (
                    <small
                      style={{ marginTop: "10px" }}
                      className="p-error block"
                    >
                      {urlTermsConditionsError}
                    </small>
                  )}
                </div>

                <div className="configLandingPageModal__content__accept__block">
                  <label htmlFor="termoUso">Termo de Uso</label>
                  <InputText
                    style={{ width: "100%" }}
                    id="termoUso"
                    value={urlDataUsage}
                    className={urlDataUsageError ? "p-invalid" : undefined}
                    required
                    onChange={(e) => {
                      setUrlDataUsage(e.target.value);
                      setHasChanges(true);
                    }}
                  />
                  {urlDataUsageError !== "" && (
                    <small
                      style={{ marginTop: "10px" }}
                      className="p-error block"
                    >
                      {urlDataUsageError}
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="configLandingPageModal__footer">
              <Button
                onClick={() => {
                  handleCloseModal();
                }}
                label="Cancelar"
                aria-label="Cancelar"
                className="p-button-outlined"
              />
              &nbsp; &nbsp;
              <Button
                label="Salvar"
                aria-label="Salvar"
                onClick={() => handleSendData()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
