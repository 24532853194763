import { SimpleModal } from 'components/ModalGroup/SimpleModal';
import { useEffect, useState } from 'react';
import { ChangeEvent } from 'react';
import * as S from './styles';
import errorImg from "images/iconError.png";

interface ImageProps {
  src: string;
  name: string;
}

interface UploadImageProps {    
    handleUrlImg?: (url: string | undefined) => void;
    handleNomeImg?: (url: string | undefined) => void;
    url: string;
    nome: string;
}

export function UploadImage({
    url,
    nome,
    handleUrlImg = () => { },
    handleNomeImg = () => { },
}: UploadImageProps) {
  const [image, setImage] = useState<ImageProps>({} as ImageProps);

  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

    useEffect(() => {
        setImage({
            src: url,
            name: ""
        });
    }, [url]);

  function handleChooseImageFromPc(e: ChangeEvent<HTMLInputElement>) {
    if (!e || !e.target || !e.target.files) return;
    const file = e.target.files[0];

      const isInvalidType = !file.type.includes('image/');
      
      if (isInvalidType) {
          setIsModalErrorOpen(true);
          return;
      }

      const getImagePath = URL.createObjectURL(file);
      const img = new Image();
      img.onload = function () {
          // @ts-ignore
          if (this.width > 301 || this.height > 177) {
              setIsModalErrorOpen(true);
              return;
          }

          setImage({
              src: getImagePath,
              name: file.name
          });

          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
              const _data = {
                  base64String: reader.result
              };
              let url = "/ServicoData/FileUpload";
              fetch(url, {
                  method: "POST",
                  headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                  },
                  body: JSON.stringify(_data),
              })
                  .then((response) => response.json())
                  .then((data) => {
                      console.log(data);
                      if (data.result === "Success") {
                          setImage({
                              src: data.url,
                              name: file.name
                          });

                          handleUrlImg(data.url);
                          handleNomeImg(file.name);
                      } else {
                          alert("Erro back");
                      }
                  })
                  .catch((error) => {
                      alert("Erro chamada API");
                      console.error("Error:", error);
                  });

          };
          reader.onerror = function (error) {
              console.log('Error: ', error);
          };
      }

      img.src = getImagePath;

      


      //console.log(getBase64(file));


      /*
    const getImagePath = URL.createObjectURL(file);

    const img = new Image();
    img.onload = function () {
      // @ts-ignore
      if (this.width > 301 || this.height > 177) {
        setIsModalErrorOpen(true);
        return;
      }

      setImage({
        src: getImagePath,
        name: file.name
      });
    }
    
    img.src = getImagePath;*/
  }

  return (
    <S.Container>
      {image.src && (
        <div className="image-uploaded">
          <img src={image.src} alt="Imagem do serviço" />
          <span>{image.name}</span>
        </div>
      )}
      
      <span>
        Escolha foto para capa do serviço do tipo jpg ou png. <br />
        Tamanho máximo suportado 301x177 
      </span>

      <div className="file-input-container">
        <label htmlFor="file">
          Selecionar arquivo
          <input
            id="file"
            type="file"
            accept="image/*"
            onChange={handleChooseImageFromPc}
            onClick={e => (
                //@ts-ignore
                e.target.value = null)
            }
            hidden
          />
        </label>
      </div>

      {isModalErrorOpen && (
        <SimpleModal
          isOpen={isModalErrorOpen}
          title="Erro"
          message="Só é possível anexar imagens com um tamanho máximo de 301x177"
          icon={errorImg}
          onRequestClose={() => setIsModalErrorOpen(false)}
          confirm={() => setIsModalErrorOpen(false)}
        />
      )}
    </S.Container>
  )
}