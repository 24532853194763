import axios from "axios";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSaved } from "./saveConfig";

interface StoreData {
  codigo: number;
  nome: string;
}

interface StoreContextData {
  store: StoreData;
  storeList: StoreData[];
  selectedStores: StoreData[];
  setSelectedStores: React.Dispatch<React.SetStateAction<StoreData[]>>;
  handleSetStore: (store: StoreData) => void;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  getStoreList: () => void;
}

interface StoreProviderProps {
  children: ReactNode;
}

const StoreContext = createContext({} as StoreContextData);

function StoreProvider({ children }: StoreProviderProps) {
  const { isSaved } = useSaved();

  const [storeList, setStoreList] = useState<StoreData[]>([]);
  const [storeContinueWithoutSave, setStoreContinueWithoutSave] = useState(
    {} as StoreData
  );

  const [load, setLoad] = useState(true);
  const [store, setStore] = useState(() => {
    const currentStore = localStorage.getItem("@portalAdmin:store");

    if (currentStore) {
      return JSON.parse(currentStore) as StoreData;
    }

    return {} as StoreData;
  });
  const [selectedStores, setSelectedStores] = useState<StoreData[]>([]);
  const [loadCount, setLoadCount] = useState(0);

  useEffect(() => {
    if (load) {
      getStoreList();
      setLoad(false);
    }
  }, [load, loadCount]);

  useEffect(() => {
    if (isSaved && storeContinueWithoutSave.codigo) {
      setStore(storeContinueWithoutSave);
      localStorage.setItem(
        "@portalAdmin:store",
        JSON.stringify(storeContinueWithoutSave)
      );
      setStoreContinueWithoutSave({} as StoreData);
    }
  }, [isSaved]);

  async function getStoreList() {
    try {
      const storagedHash = localStorage.getItem("@portalAdmin:hash");
      if (storagedHash?.length) {
        console.log(`Buscando lojas ${loadCount}x`);

        const response = await axios.get("/lojaData/lojas", {
          headers: {
            hash: storagedHash,
          },
        });
        if (response.data.result === "Error") {
          if (response.data.code === "001" && loadCount <= 3) {
            let count = loadCount + 1;
            setLoadCount(count);
          }
        } else {
          setStoreList(response.data.lojas);
          if (!store.codigo && response.data.lojas) {
            setStore(response.data.lojas[0]);
          }

          if (response.data.lojas.length === 1)
            setSelectedStores(response.data.lojas);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  function handleSetStore(store: StoreData) {
    if (!isSaved) {
      // handleModalWarning();
      setStoreContinueWithoutSave(store);
      return;
    }

    setStore(store);
    localStorage.setItem("@portalAdmin:store", JSON.stringify(store));
  }

  return (
    <StoreContext.Provider
      value={{
        store,
        storeList,
        selectedStores,
        setSelectedStores,
        handleSetStore,
        setLoad,
        getStoreList,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

function useStore(): StoreContextData {
  const context = useContext(StoreContext);

  return context;
}

export { StoreProvider, useStore };
