import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";

interface iconsProps {
  inicio: boolean;
  fim: number;
}

export const Container = styled.div<iconsProps>`
  width: 100%;

  div.iconBack {
    z-index: 10;
    position: absolute;
    width: 33px;
    height: 33px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(61, 61, 61, 0.25);
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-top: 65px;
    margin-left: -10px;
    opacity: ${(props) => (props.inicio ? "0" : "1")};
  }

  div.iconAdvance {
    z-index: 10;
    position: absolute;
    width: 33px;
    height: 33px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(61, 61, 61, 0.25);
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-top: 65px;
    right: 15px;
    opacity: ${(props) => (props.fim !== 0 ? "1" : "0")};
  }

  img.goBack {
    height: 16px;
    width: 16px;
    color: #0054a6;
    border-radius: 0px;

    margin: 0 auto;
    cursor: ${(props) => (props.inicio ? "" : "pointer")};
    opacity: ${(props) => (props.inicio ? "0" : "1")};
  }

  img.advance {
    margin: 0 auto;
    cursor: ${(props) => (props.fim !== 0 ? "pointer" : "")};
    opacity: ${(props) => (props.fim !== 0 ? "1" : "0")};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  div.header {
    display: flex;
    flex-direction: column;
    p {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      color: #2a333e;
      margin-bottom: 8px;
    }

    span {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #404f61;
    }
  }
`;

export const TotalServices = styled.div`
  width: 221px;
  height: 41px;
  background: #0054a6;
  border: 1px solid #d7e2ed;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 48px;
  display: flex;
  align-items: center;

  span {
    align-items: center;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #fff;
    width: 133px;
    margin-left: 16px;
  }

  strong {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
  }
`;

export const ContainerScroll = styled(ScrollContainer)`
  margin-top: 18px;
  height: 140px;
  display: flex;
  align-items: center;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;

  min-width: 131px;
  min-height: 120px;
  max-width: 131px;
  max-height: 120px;
  margin-left: 18px;

  :first-child {
    margin-left: 5px;
  }

  border: 1px solid #d7e2ed;
  box-sizing: border-box;
  border-radius: 4px;

  strong {
    color: #0054a6;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-top: 12px;
  }

  p {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #2a333e;
    margin: 0;
    max-height: 42px;
    margin-top: 6px;
    display: block;
    width: 101px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  span{
    margin-top:10px;
    position: absolute;
  height: 36px;
  left: 13px;
  right: 16px;
  top: calc(50% - 36px/2 + 31.5px);

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  color: #2A333E;
  }
  img {
    position: absolute;
    margin-top: -9px;
    margin-left: -100px;
    width: 30px;
    height: 30px;
  }
`;
