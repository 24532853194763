import { ReactNode, useEffect, useState } from "react";
import axios from "axios";
import {
  isAfter,
  isBefore,
  addHours,
  addMinutes,
  formatDistance,
  differenceInDays,
  isEqual,
} from "date-fns";
import pt from "date-fns/locale/pt";

import Header from "components/Header";
import DashboardUser from "components/DashboardUser";
import Carousel from "components/Carousel";
import Main from "components/Main";
import { Footer } from "components/Footer";
import { Calendar } from "components/Calendar";
import { Grid, StatusIconMapProps } from "components/Grid";
import { GridFilter } from "components/GridFilter";
import { Button as ButtonPrime } from "primereact/button";

import iconStatusConfirmed from "images/icons/icon-confirmed.svg";
import iconStatusNext from "images/icons/icon-next.svg";
import iconStatusCanceled from "images/icons/icon-canceled.svg";
import iconStatusPreSale from "images/icons/icon-pre-sale.svg";
import iconStatusExecuteService from "images/icons/icon-execute-service.svg";
import iconStatusPayout from "images/icons/icon-payout.svg";
import iconStatusWarning from "images/icons/icon-status-warning.svg";
import IconSucess from "images/icons/icon-sucess.svg";
import IconError from "images/icons/icon-acess-denied.svg";
import IconAlert from "images/icons/icon-alert2.svg";
import IconError2 from "images/icons/icon-block.svg";
import loadingImg from "images/icons/loading-register.svg";

import { Container, CalendarContainer, GridContainer } from "./styles";
import { NoResults } from "components/NoResults";
import { SchedulingDetailsModal } from "components/ModalGroup/SchedulingDetailsModal";
import { HomeFilterModal } from "components/ModalGroup/HomeFilterModal";
import { ModalRequestAcess } from "components/ModalGroup/ModalRequestAcess";
import { SimpleModal } from "components/ModalGroup/SimpleModal";
import { MotivoCancelamento } from "components/ModalGroup/MotivoCancelamento";
import { useStore } from "hooks/store";
import { useLoader } from "hooks/loader";

import IconService1 from "../../assets/IconService1.svg";
import IconService2 from "../../assets/IconService2.svg";
import IconService3 from "../../assets/IconService3.svg";
import IconService4 from "../../assets/IconService4.svg";
import IconService5 from "../../assets/IconService5.svg";
import IconService6 from "../../assets/IconService6.svg";
import IconService7 from "../../assets/IconService7.svg";
import IconService8 from "../../assets/IconService8.svg";
import IconService9 from "../../assets/IconService9.svg";
import IconService10 from "../../assets/IconService10.svg";
import IconService11 from "../../assets/IconService11.svg";
import IconService12 from "../../assets/IconService12.svg";
import IconService13 from "../../assets/IconService13.svg";
import IconService14 from "../../assets/IconService14.svg";
import IconService15 from "../../assets/IconService15.svg";
import IconService16 from "../../assets/IconService16.svg";
import IconService17 from "../../assets/IconService17.svg";
import IconService18 from "../../assets/IconService18.svg";
import { debounce } from "utils/debounce";
import { ModalGrantAccess } from "components/ModalGroup/ModalGrantAccess";
import { ModalVerifyAccess } from "components/ModalGroup/ModalVerifyAccess";
import { useAuth } from "hooks/auth";
import { FormUserAskMaintainer } from "components/FormUserAskMaintainer";
import { useRescheduling } from "hooks/rescheduling";

interface Cliente {
  cpf: string;
  email: string;
  nome: string;
  servico: string;
  telefone: string;
}

interface AgendamentoResponse {
  id: number;
  idAgendamento: number;
  status: number;
  data: string;
  hora: string;
  codigoLoja: number;
  cliente: Cliente;
  parceiro: string;
  idParceiro?: number;
  // subTitulo: string;
  servicos: Servico[];
  lojaId: number;
}

interface AgendamentoGrid {
  id: number;
  idAgendamento: number;
  status: number;
  lojaId: number;
  data: string;
  hora: string;
  cliente: string;
  parceiro: string;
  idParceiro?: number;
  idServicos: number[];
  servicos: string;
  nomeServicos: string;
}

interface Servico {
  id: number;
  nome: string;
  nomeAbreviado: string;
  subTitulo: string;
  total: number;
  iconeId: number;
}

interface LojaServicosData {
  result: string;
  items: {
    agendamentos: AgendamentoResponse[];
    total: number;
    servicos: Servico[];
    proximosHorarios: {
      codLoja: number;
      horario: string;
      data: string;
    }[];
  };
}

interface StoreData {
  codigo: number;
  nome: string;
}

interface CancelarProps {
  data: string;
  hora: string;
  idAgendamento: number;
}

interface PreVendaProps {
  data: string;
  hora: string;
}

export function Home() {
  const { handleRequestReschedule, setAgendamentoSelecionado } =
    useRescheduling();
  const { selectedStores, storeList, store } = useStore();
  const { handleSetIsLoader } = useLoader();
  const [selectedIdAgendamento, setSelectedIdAgendamento] = useState(0);
  const [idAgendamentoCancelar, setIdAgendamentoCancelar] = useState(0);

  const [agendamentos, setAgendamentos] = useState<AgendamentoGrid[]>([]);
  const [servicos, setServicos] = useState<Servico[]>([]);

  const [isHomeFilterModalOpen, setIsHomeFilterModalOpen] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [filterDate, setFilterDate] = useState<Date[] | undefined>([]);
  const [filterText, setFilterText] = useState("");
  const [filterServico, setFilterServico] = useState(0);
  const [filterPeriodo, setFilterPeriodo] = useState("");
  const [filterHorario, setFilterHorario] = useState("");

  const [messageTitleError, setMessageTitleError] = useState("");
  const [
    errorAuthorizationExecuteService,
    setErrorAuthorizationExecuteService,
  ] = useState(false);
  const [confirmacaoExecutarServico, setConfirmacaoExecutarServico] =
    useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [messageFailed, setMessageFailed] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 7;

  const [isModalSchedulingDetailsOpen, setIsModaSchedulingDetailsOpen] =
    useState(false);
  const [isModalRequestAcess, setIsModalRequestAcess] = useState(false);
  const [cancelamentoConfirmado, setCancelamentoConfirmado] = useState(false);
  const [openMotivoCancelamento, setOpenMotivoCancelamento] = useState(false);
  const [openModalCancelamentoConcluido, setOpenModalCancelamentoConcluido] =
    useState(false);
  const [isModalLoadingOpen, setIsModalLoadingOpen] = useState(false);

  // MODAL ERROR DE CANCELAR AGENDAMENTO
  const [errorAuthorization, setErrorAuthorization] = useState(false);
  const [messageError, setMessageError] = useState<string | ReactNode>("");

  // PRE-VENDA DO AGENDAMENTO
  const [errorAuthorizationPreSale, setErrorAuthorizationPreSale] =
    useState(false);
  const [openModalPreVendaSucesso, setOpenModalPreVendaSucesso] =
    useState(false);
  const [openModalExecuteService, setOpenModalExecuteService] = useState(false);
  const [confirmacaoPreVenda, setConfirmacaoPreVenda] = useState(false);
  const [isLoadingInner, setIsLoadingInner] = useState(false);

  const [storeSelectedModalAccess, setStoreSelectedModalAccess] =
    useState<StoreData>({} as StoreData);
  const [toGiveAuthorization1, setToGiveAuthorization1] = useState(false);
  const [toGiveAuthorization2, setToGiveAuthorization2] = useState(false);

  const [loadingCount, setLoadingCount] = useState(0);

  const [matricula, setMatricula] = useState<number | undefined>();
  const [openModalUserMaintainer, setOpenModalUserMaintainer] = useState(false);

  // MODAL DE SUCESSO
  const [isSucess, setIsSucess] = useState(false);

  // MODAL DE ERRO CONCEDER ACESSO
  const [isError, setIsError] = useState(false);

  // BOTÃO FORÇAR ATUALIZAÇÃO GRID
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const { nivelAcesso } = useAuth();
  const isAllowed = nivelAcesso?.trim() !== "OPERADOR";
  const isAdmin = nivelAcesso?.trim() === "ADMIN";

  const iconsServices = [
    { id: 1, icon: IconService1 },
    { id: 2, icon: IconService2 },
    { id: 3, icon: IconService3 },
    { id: 4, icon: IconService4 },
    { id: 5, icon: IconService5 },
    { id: 6, icon: IconService6 },
    { id: 7, icon: IconService7 },
    { id: 8, icon: IconService8 },
    { id: 9, icon: IconService9 },
    { id: 10, icon: IconService10 },
    { id: 11, icon: IconService11 },
    { id: 12, icon: IconService12 },
    { id: 13, icon: IconService13 },
    { id: 14, icon: IconService14 },
    { id: 15, icon: IconService15 },
    { id: 16, icon: IconService16 },
    { id: 17, icon: IconService17 },
    { id: 18, icon: IconService18 },
  ];

  const statusIconMap: StatusIconMapProps[] = [
    {
      status: 0,
      icon: <img src={iconStatusNext} alt="Próximo agendamento" />,
    },
    {
      status: 1,
      icon: <img src={iconStatusConfirmed} alt="Agendamento confirmado" />,
    },
    {
      status: 2,
      icon: <img src={iconStatusCanceled} alt="Agendamento cancelado" />,
    },
    {
      status: 3,
      icon: <img src={iconStatusExecuteService} alt="Agendamento utilizado" />,
    },
    {
      status: 4,
      icon: <img src={iconStatusWarning} alt="Não compareceu" />,
    },
    {
      status: 5,
      icon: (
        <img
          src={iconStatusPreSale}
          alt="Pré-venda gerada para o agendamento"
        />
      ),
    },
    {
      status: 6,
      icon: <img src={iconStatusPayout} alt="Agendamento pago" />,
    },
    {
      status: 7,
      icon: <img src={iconStatusPayout} alt="Agendamento pago" />,
    },
  ];

  function handleAuthorizationModal_1() {
    setToGiveAuthorization1(false);
    setToGiveAuthorization2(true);
  }

  useEffect(() => {
    debounce(() => {
      loadAgendamentos();
    }, 500);
  }, [
    currentPage,
    selectedStores,
    filterDate,
    filterHorario,
    filterServico,
    filterPeriodo,
    filterText,
  ]);

  useEffect(() => {
    loadAgendamentos();
  }, [handleRequestReschedule]);

  function loadAgendamentos() {
    let initialDate = !filterDate?.length ? filterDate : filterDate[0];
    let finalDate = !filterDate?.length ? filterDate : filterDate[1];

    const today = new Date();

    if (!String(initialDate).length && !String(finalDate).length) {
      initialDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      );
      finalDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        23,
        59,
        59
      );
    }

    const formattedInitialDate = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(initialDate ? new Date(String(initialDate)) : new Date());

    const formattedFinalDate = new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(
      finalDate
        ? new Date(String(finalDate))
        : new Date(String(initialDate)).setHours(23, 59, 59)
    );
    getAgendamentos(
      currentPage,
      pageSize,
      selectedStores.length
        ? selectedStores.map((store) => String(store.codigo))
        : ["0"],
      formattedInitialDate,
      formattedFinalDate || formattedInitialDate,
      filterText,
      filterPeriodo,
      filterHorario,
      filterServico
    );
  }

  async function getAgendamentos(
    currentPage: number,
    pageSize: number,
    lojas: string[],
    dataInicial: string,
    dataFinal: string,
    search: string,
    periodo: string,
    horario: string,
    idServico: number
  ) {
    setSelectedIdAgendamento(0);
    handleSetIsLoader(true);

    setLoadingCount((prevState) => prevState + 1);

    let startIndex = 0;

    if (currentPage > 1) {
      startIndex = (currentPage - 1) * pageSize;
    }

    if (lojas.length === 0 || lojas[0] === "0") {
      setAgendamentos([]);
      setServicos([]);
    }
    if (lojas.length > 0 && lojas[0] !== "0") {
      if (storeList.length > 1 && selectedStores.length === storeList.length)
        lojas = [];

      await axios
        .get<LojaServicosData>(
          `/AgendamentoData/GetAgendamentos?startIndex=${startIndex}&pageSize=${pageSize}&lojas=${lojas}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&search=${search}&periodo=${periodo}&horario=${horario}&idServico=${
            !idServico ? "" : idServico
          }`
        )
        .then((response) => {
          if (response.data.result === "Success") {
            setTotalData(response.data.items.total);
            const formattedAppointments: AgendamentoGrid[] =
              response.data.items.agendamentos.map((agendamento) => {
                const isNextAppointment =
                  response.data?.items?.proximosHorarios?.find((proximo) => {
                    return (
                      proximo.horario === agendamento.hora + ":00" &&
                      agendamento.data === proximo.data
                    );
                  });

                return {
                  id: agendamento.id,
                  idAgendamento: agendamento.idAgendamento,
                  key:
                    String(agendamento.id) + String(agendamento.idAgendamento),
                  status: isNextAppointment ? 0 : agendamento.status,
                  idParceiro: agendamento.idParceiro,
                  /*data: new Intl.DateTimeFormat("pt-BR", {
                    timeZone: "UTC",
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                  }).format(new Date(agendamento.data)),*/
                  lojaId: agendamento.lojaId,
                  data: agendamento.data,
                  hora: agendamento.hora,
                  cliente: agendamento.cliente?.nome,
                  parceiro: agendamento.parceiro,
                  // subTitulo: agendamento.subTitulo,
                  idServicos: agendamento.servicos.map((servico) => servico.id),
                  servicos: agendamento.servicos
                    .map((servico, index) => {
                      return `<img key=${index} src=${
                        iconsServices?.find(
                          (icon) => icon.id === servico.iconeId
                        )?.icon
                      } alt="Serviço" style="cursor: pointer; margin-right: 10px" />`;
                    })
                    .join(""),
                  nomeServicos: agendamento.servicos
                    .map((servico) => servico.nomeAbreviado)
                    .join("<br />"),
                };
              });
            setAgendamentos(formattedAppointments);
            setServicos(response.data.items.servicos);
            setLoadingCount((prevState) => prevState - 1);
            handleSetIsLoader(false);
            return;
          }
          setLoadingCount((prevState) => prevState - 1);
          handleSetIsLoader(false);
          setHasFailed(true);
          setMessageSuccess("");
          setMessageFailed("Erro ao buscar os agendamentos. Tente novamente.");
        })
        .catch((e) => {
          handleSetIsLoader(false);
          console.error(e);
        })
        .finally(() => handleSetIsLoader(false));
    } else {
      handleSetIsLoader(false);
      setLoadingCount((prevState) => prevState - 1);
    }
  }

  function permitirCancelar({ data, hora, idAgendamento }: CancelarProps) {
    const newDate = data.split("/");
    const newHoras = hora.split(":");

    const date = new Date(`${newDate[1]}/${newDate[0]}/${newDate[2]}`);
    date.setHours(0, 0, 0, 0);

    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    if (differenceInDays(dateNow, date) > 7) {
      setMessageTitleError("Cancelamento não autorizado");
      setMessageError(
        <p style={{ lineHeight: "1.49" }}>
          Não é possível solicitar um cancelamento após 7 dias da data do
          agendamento marcado.
        </p>
      );
      setErrorAuthorization(true);
      return;
    }

    setSelectedIdAgendamento(0);
    setCancelamentoConfirmado(true);
  }

  const actionOpenModalPreSale = (data: any) => {
    setSelectedIdAgendamento(data.idAgendamento);
    inserirPreVendaCheck({
      data: data.data,
      hora: data.hora,
    });
  };

  const actionOpenModalExecuteService = (data: any) => {
    setSelectedIdAgendamento(data.idAgendamento);
    executarServicoCheck({
      data: data.data,
      hora: data.hora,
    });
  };

  const inserirPreVendaCheck = ({ data, hora }: PreVendaProps) => {
    const newHoras = hora.split(":");
    const date = convertDate(data);
    date.setHours(0, 0, 0, 0);

    let dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    if (!isEqual(dateNow, date)) {
      setMessageTitleError("Pré-venda não autorizada");
      setMessageError(
        <p style={{ lineHeight: "1.49" }}>
          Não é possível gerar uma pré-venda que não seja para um agendamento do
          dia atual.
        </p>
      );
      setErrorAuthorizationPreSale(true);
      return;
    }

    setConfirmacaoPreVenda(true);
  };

  const executarServicoCheck = ({ data, hora }: PreVendaProps) => {
    const date = convertDate(data);
    let dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);

    if (!isEqual(dateNow, date)) {
      setMessageTitleError("Execução do serviço não autorizada");
      setMessageError(
        <p style={{ lineHeight: "1.49" }}>
          Não é possível executar um agendamento que não seja para o dia atual.
        </p>
      );
      setErrorAuthorizationPreSale(true);
      return;
    }

    setConfirmacaoExecutarServico(true);
  };

  function handleRequestRescheduleAppointment(appointmentData: any) {
    handleRequestReschedule(appointmentData);
  }

  const convertDate = (data: string): Date => {
    const newDate = data.split("/");
    const date = new Date(`${newDate[1]}/${newDate[0]}/${newDate[2]}`);
    date.setHours(0, 0, 0, 0);

    return date;
  };

  function ActionButtonModalContent({ data }: { data: AgendamentoGrid }) {
    const splitDate = data?.data.split("/");
    const date = new Date(`${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`);
    date.setHours(0, 0, 0, 0);
    const dateNow = new Date();
    dateNow.setHours(0, 0, 0, 0);
    const diff = differenceInDays(dateNow, date);

    const actionOpenModal = () => {
      setSelectedIdAgendamento(data.idAgendamento);
      setIsModaSchedulingDetailsOpen(true);
    };

    const actionOpenModalCancel = () => {
      permitirCancelar({
        data: data.data,
        hora: data.hora,
        idAgendamento: data.idAgendamento,
      });
    };

    return (
      <>
        <span>
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.167 0.125H0.833333C0.44375 0.125 0.125 0.44375 0.125 0.833333V12.167C0.125 12.5562 0.44375 12.875 0.833333 12.875H12.167C12.5562 12.875 12.875 12.5562 12.875 12.167V0.833333C12.875 0.44375 12.5562 0.125 12.167 0.125ZM4.375 2.95833H2.95833V4.375H4.375V2.95833ZM9.33333 4.375H6.5C6.11042 4.375 5.79167 4.05625 5.79167 3.66667C5.79167 3.27708 6.11042 2.95833 6.5 2.95833H9.33333C9.72292 2.95833 10.0417 3.27708 10.0417 3.66667C10.0417 4.05625 9.72292 4.375 9.33333 4.375ZM9.33333 7.20833H6.5C6.11042 7.20833 5.79167 6.88958 5.79167 6.5C5.79167 6.11042 6.11042 5.79167 6.5 5.79167H9.33333C9.72292 5.79167 10.0417 6.11042 10.0417 6.5C10.0417 6.88958 9.72292 7.20833 9.33333 7.20833ZM6.5 10.0417H9.33333C9.72292 10.0417 10.0417 9.72292 10.0417 9.33333C10.0417 8.94375 9.72292 8.625 9.33333 8.625H6.5C6.11042 8.625 5.79167 8.94375 5.79167 9.33333C5.79167 9.72292 6.11042 10.0417 6.5 10.0417ZM2.95833 5.79167H4.375V7.20833H2.95833V5.79167ZM4.375 8.625H2.95833V10.0417H4.375V8.625ZM1.54167 11.4583H11.4583V1.54167H1.54167V11.4583Z"
              fill="#0054A6"
            />
          </svg>
          <button
            style={{ color: "#0054A6" }}
            type="button"
            onClick={() => {
              setIdAgendamentoCancelar(data.id);
              actionOpenModal();
            }}
          >
            Ver detalhes
          </button>
        </span>
        {/*
        {![2, 3, 6, 7].includes(data.status) && (
          <span>
            <svg 
              height="13" viewBox="0 0 512 512" width="13" 
              xmlns="http://www.w3.org/2000/svg" style={{ fill: "#0054A6" }}>
              <path d="m76 512h420v-452h-60v-60h-321.213l-98.787 98.787v353.213h60zm390-422v392h-360v-30h330v-362zm-360-38.787v38.787h-38.787zm-60 68.787h90v-90h270v392h-360z"/>
              <path d="m76 180h300v30h-300z" style={{ fill: "#0054A6" }}/>
              <path d="m76 240h300v30h-300z" style={{ fill: "#0054A6" }}/>
              <path d="m76 300h150v30h-150z" style={{ fill: "#0054A6" }}/>
              <path d="m196 120h180v30h-180z" style={{ fill: "#0054A6" }}/>
            </svg>

            <button
              type="button"
              style={{ color: "#0054A6" }}
              onClick={() => actionOpenModalPreSale(data)}
            >
              Gerar Pré-Venda
            </button>
          </span>
        )}
*/}
        {![2, 3, 4].includes(data.status) && (
          <span>
            <svg
              style={{ marginLeft: "-4px" }}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Round"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8333 3.33335H15V2.50002C15 2.04169 14.625 1.66669 14.1667 1.66669C13.7083 1.66669 13.3333 2.04169 13.3333 2.50002V3.33335H6.66667V2.50002C6.66667 2.04169 6.29167 1.66669 5.83333 1.66669C5.375 1.66669 5 2.04169 5 2.50002V3.33335H4.16667C3.24167 3.33335 2.50833 4.08335 2.50833 5.00002L2.5 16.6667C2.5 17.5834 3.24167 18.3334 4.16667 18.3334H15.8333C16.75 18.3334 17.5 17.5834 17.5 16.6667V5.00002C17.5 4.08335 16.75 3.33335 15.8333 3.33335ZM15.8333 15.8334C15.8333 16.2917 15.4583 16.6667 15 16.6667H5C4.54167 16.6667 4.16667 16.2917 4.16667 15.8334V7.50002H15.8333V15.8334ZM7.5 10.8334V9.16669H5.83333V10.8334H7.5ZM9.16667 9.16669H10.8333V10.8334H9.16667V9.16669ZM14.1667 9.16669H12.5V10.8334H14.1667V9.16669Z"
                style={{ fill: "#F5AA07" }}
              />
            </svg>
            <button
              type="button"
              style={{ color: "#F5AA07" }}
              onClick={() => {
                var dataPrincipal = agendamentos?.find(
                  (proximo) => proximo.idAgendamento === data.idAgendamento
                );
                handleRequestRescheduleAppointment(dataPrincipal);
                setAgendamentoSelecionado({
                  idAgendamento: data.idAgendamento,
                  idServicos: data.idServicos,
                  loja: data.lojaId,
                  idParceiro: data.idParceiro,
                });
              }}
            >
              Reagendar
            </button>
          </span>
        )}

        {![2, 3].includes(data.status) && (
          <span>
            <svg
              style={{ marginLeft: "-2px" }}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00008 0.666668C4.39175 0.666668 0.666748 4.39167 0.666748 9C0.666748 13.6083 4.39175 17.3333 9.00008 17.3333C13.6084 17.3333 17.3334 13.6083 17.3334 9C17.3334 4.39167 13.6084 0.666668 9.00008 0.666668ZM10.5751 6.25001L9.00008 7.82501L7.42508 6.25001C7.26939 6.09397 7.05801 6.00627 6.83758 6.00627C6.61715 6.00627 6.40577 6.09397 6.25008 6.25001C5.92508 6.57501 5.92508 7.10001 6.25008 7.42501L7.82508 9.00001L6.25008 10.575C5.92508 10.9 5.92508 11.425 6.25008 11.75C6.57508 12.075 7.10008 12.075 7.42508 11.75L9.00008 10.175L10.5751 11.75C10.9001 12.075 11.4251 12.075 11.7501 11.75C12.0751 11.425 12.0751 10.9 11.7501 10.575L10.1751 9.00001L11.7501 7.42501C12.0751 7.10001 12.0751 6.57501 11.7501 6.25001C11.4251 5.93334 10.8917 5.93334 10.5751 6.25001ZM2.33341 9C2.33341 12.675 5.32508 15.6667 9.00008 15.6667C12.6751 15.6667 15.6667 12.675 15.6667 9C15.6667 5.325 12.6751 2.33333 9.00008 2.33333C5.32508 2.33333 2.33341 5.325 2.33341 9Z"
                style={{ fill: "#F14A50" }}
              />
            </svg>

            <button
              className="cancelScheduleBtn"
              disabled={!isAdmin && diff > 7}
              type="button"
              style={{ color: "#F14A50" }}
              onClick={() => {
                setIdAgendamentoCancelar(data.idAgendamento);
                actionOpenModalCancel();
              }}
            >
              Cancelar agendamento
            </button>
          </span>
        )}

        {isAllowed && [6, 7].includes(data.status) && (
          <span>
            <svg
              height="13"
              viewBox="0 0 1000 1000"
              width="13"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style={{ fill: "#0054A6" }}
                d="M484 458.3h256.4c-121.60000000000002 160.8-242 319.99999999999994-362.4 479.2l-1.8000000000000114-1c43.5-119.39999999999998 87.10000000000002-238.70000000000005 131.40000000000003-360.5-82.90000000000003 3.7000000000000455-163.90000000000003 7.2999999999999545-248.10000000000002 11 123.39999999999998-176.3 245.39999999999998-350.4 367.4-524.5l2.300000000000068 1c-48.30000000000007 131-96.5 262.1-145.20000000000005 394.8z"
              />
            </svg>

            <button
              type="button"
              style={{ color: "#0054A6" }}
              onClick={() => actionOpenModalExecuteService(data)}
            >
              Executar Serviço
            </button>
          </span>
        )}
      </>
    );
  }

  const renderDateFiltered = () => {
    if (!filterDate) {
      return `${new Intl.DateTimeFormat("pt-BR").format(new Date())}`;
    }

    if (!filterDate[1]) {
      return `${new Intl.DateTimeFormat("pt-BR").format(filterDate[0])}`;
    }

    if (filterDate[0].getTime() === filterDate[1].getTime()) {
      return `${new Intl.DateTimeFormat("pt-BR").format(filterDate[0])}`;
    }

    return `${new Intl.DateTimeFormat("pt-BR").format(
      filterDate[0]
    )} ao dia ${new Intl.DateTimeFormat("pt-BR").format(filterDate[1])}`;
  };

  async function cancelarAgendamento(
    idAgendamento: number,
    idMotivo: number,
    motivo: string
  ) {
    try {
      handleSetIsLoader(true);
      const hash = localStorage.getItem("@portalAdmin:hash") || "";

      const response = await axios.put(
        "/AgendamentoData/Cancelar",
        {},
        {
          headers: {
            pm_hash: hash,
          },
          params: {
            idAgendamento,
            idMotivo,
            motivo,
          },
        }
      );

      if (response.data.result === "Success") {
        setOpenModalCancelamentoConcluido(true);
        handleSetIsLoader(false);
        setMessageSuccess("Cancelamento foi realizado com sucesso.");
        loadAgendamentos();
      } else {
        handleSetIsLoader(false);
        setHasFailed(true);
        setMessageSuccess("");
        setMessageFailed("Erro ao cancelar o agendamento.");
      }
    } catch (error) {
      console.error(error);
      handleSetIsLoader(false);
    }
  }

  const inserirPreVenda = async (idAgendamento: number) => {
    await axios
      .post(
        `/PreVendasData/${idAgendamento}`,
        {},
        {
          headers: {
            matricula,
          },
        }
      )
      .then((response) => {
        if (response.data.id) {
          setOpenModalPreVendaSucesso(true);
          setMessageSuccess("Pré-Venda foi cadastrada com sucesso.");
          setOpenModalUserMaintainer(false);
          setIsModaSchedulingDetailsOpen(false);
        }
      })
      .catch((error) => {
        setHasFailed(true);
        setMessageSuccess("");
        setMessageFailed(error.response.data["message"]);
        setOpenModalUserMaintainer(false);
        console.error(error);
      })
      .finally(() => setIsLoadingInner(false));
  };

  const confirmarExecucaoServico = async (idAgendamento: number) => {
    await axios
      .put(
        `/AgendamentoData/${idAgendamento}/ExecutarServico?retornarDadosAtualizados=false`,
        {},
        {
          headers: {
            matricula,
          },
        }
      )
      .then((_) => {
        setOpenModalExecuteService(true);
        setMessageSuccess("Agendamento atualizado com sucesso.");
        loadAgendamentos();
        setConfirmacaoExecutarServico(false);
        setIsModaSchedulingDetailsOpen(false);
      })
      .catch((error) => {
        setHasFailed(true);
        setMessageSuccess("");
        setMessageFailed(error.response.data["message"]);
        setConfirmacaoExecutarServico(false);
        console.error(error);
      })
      .finally(() => setIsLoadingInner(false));
  };

  function handleCheckIfAccessWasGranted(requestStore: StoreData) {
    setIsModalLoadingOpen(true);

    setTimeout(async () => {
      const hash = localStorage.getItem("@portalAdmin:hash");

      if (!hash) {
        setIsError(true);
        setIsModalLoadingOpen(false);
        return;
      }

      const response = await axios.post(`/colaborador/EstaLogado`, {
        hash,
      });

      const { result, colaborador } = response.data;

      if (result === "Error") {
        setIsError(true);
        setIsModalLoadingOpen(false);
        return;
      }

      //if (colaborador.lojas[0] === requestStore.codigo) {
      setIsModalLoadingOpen(false);
      setToGiveAuthorization2(false);
      setIsSucess(true);
      //}
    }, 1000);
  }

  const forcarAtualizacaoAgendamento = async () => {
    if (!agendamentos || agendamentos.length < 1) {
      console.log("Não é possível atualizar.");
      return;
    }

    setIsLoadingUpdate(true);
    const lojas = agendamentos.map((item) => item.lojaId.toString());
    var lojasdistintas = Array.from(new Set(lojas));

    await axios
      .put(
        `/AgendamentoData/AtualizarStatusAgendamentosPreVendaPago`,
        {},
        {
          headers: {
            lojasIds: lojasdistintas,
          },
        }
      )
      .then((_) => {
        setOpenModalPreVendaSucesso(true);
        setMessageSuccess(
          "Atualização do(s) agendamento(s) realizada com sucesso."
        );
        loadAgendamentos();
        setConfirmacaoPreVenda(false);
        setIsModaSchedulingDetailsOpen(false);
      })
      .catch((error) => {
        setHasFailed(true);
        setMessageSuccess("");
        setMessageFailed(error.response.data["message"]);
        setConfirmacaoPreVenda(false);
        console.error(error);
      })
      .finally(() => {
        setOpenModalUserMaintainer(false);
        setIsLoadingInner(false);
        setIsLoadingUpdate(false);
      });
  };

  return (
    <>
      <Header />
      <Main>
        <Container>
          <div>
            <DashboardUser
              displayStore={true}
              requestAcess={true}
              onClickRequestAcess={() => setIsModalRequestAcess(true)}
            />

            <CalendarContainer>
              <h2>Data do agendamento</h2>
              <p>Selecione a data para filtrar</p>

              <Calendar
                isHome={true}
                defaultDateSelected={filterDate}
                handleClickDay={(date: any) => {
                  setCurrentPage(1);

                  if (!date) {
                    return;
                  }

                  if (!filterDate || filterDate.length === 2) {
                    setFilterDate([...date]);
                    return;
                  }

                  setFilterDate(
                    [...(filterDate as Date[]), ...date]
                      .sort((a, b) => a.getDate() - b.getDate())
                      .sort((a, b) => a.getMonth() - b.getMonth())
                  );
                }}
              />
            </CalendarContainer>
          </div>
          <div>
            <Carousel
              services={servicos}
              onSetRenderDateFiltered={renderDateFiltered}
              loading={!!loadingCount}
            />
            <GridContainer className="session-grid">
              <div className="gridHeader">
                <div>
                  <h2>Resultado dos serviços</h2>
                  <p>Resumo dos serviços do dia {renderDateFiltered()}</p>
                </div>
                <GridFilter
                  onClick={() => setIsHomeFilterModalOpen(true)}
                  showInput
                  onChange={(event) => {
                    setCurrentPage(1);
                    setFilterText(event.target.value);
                  }}
                />
              </div>
              {!!loadingCount && (
                <div className="gridLoadingContainer">
                  {handleSetIsLoader(true)}
                  <NoResults title="" message="Carregando..." />
                </div>
              )}
              {!!agendamentos.length && (
                <>
                  <div className={"force-update-grid"}>
                    <ButtonPrime
                      icon={
                        "pi " +
                        (isLoadingUpdate ? "pi-spin pi-spinner" : "pi-refresh")
                      }
                      onClick={() => forcarAtualizacaoAgendamento()}
                      label={
                        isLoadingUpdate ? "Carregando" : "Buscar Pagamentos"
                      }
                      disabled={
                        isLoadingUpdate ||
                        !agendamentos ||
                        agendamentos.length < 1
                      }
                    />
                  </div>
                  <Grid
                    columns={[
                      "Status",
                      "ID Loja",
                      "Data",
                      "Horas",
                      "Cliente",
                      "Parceiro",
                      "Serviço",
                    ]}
                    columnsAlignCenter={[
                      {
                        column: "status",
                        align: true,
                      },
                      {
                        column: "lojaId",
                        align: true,
                      },
                    ]}
                    columnsWidth={[
                      {
                        column: "Status",
                        width: "62px",
                      },
                      {
                        column: "ID Loja",
                        width: "72px",
                      },
                      {
                        column: "Data",
                        width: "75px",
                      },
                      {
                        column: "Horas",
                        width: "67px",
                      },
                      {
                        column: "Cliente",
                        width: "256px",
                      },
                      {
                        column: "Parceiro",
                        width: "140px",
                      },
                      {
                        column: "Serviço",
                        width: "141px",
                      },
                      {
                        column: "Action",
                        width: "116px",
                      },
                    ]}
                    data={!loadingCount ? agendamentos : []}
                    omit={[
                      "id",
                      "idAgendamento",
                      "idParceiro",
                      "key",
                      "autoAtendimento",
                      "idServicos",
                      "nomeServicos",
                    ]}
                    statusIconMap={statusIconMap}
                    actionButtonModalContent={
                      <ActionButtonModalContent data={{} as AgendamentoGrid} />
                    }
                    disableColumnSortFor={["Status", "ID Loja"]}
                    enableActionButton
                    enableColumnSort
                    enablePagination
                    onChangePage={(page) => setCurrentPage(page)}
                    totalData={totalData}
                    itemsPerPage={7}
                    maxHeight="405px"
                    objectKey="key"
                  />
                </>
              )}
              {!agendamentos.length && !loadingCount && (
                <>
                  {handleSetIsLoader(false)}
                  <NoResults
                    title="Atenção!"
                    message="Nenhum agendamento realizado."
                  />
                </>
              )}
              {!!agendamentos.length && !loadingCount && (
                <>
                  <br />
                  <div className="gridSubtitle">
                    <strong>Legenda:</strong>
                    <span>
                      {statusIconMap[1].icon}
                      <p>Confirmado</p>
                    </span>
                    <span>
                      {statusIconMap[0].icon}
                      <p>Próximo</p>
                    </span>
                    <span>
                      {statusIconMap[2].icon}
                      <p>Cancelado</p>
                    </span>
                    <span>
                      {statusIconMap[3].icon}
                      <p>Agendamento utilizado</p>
                    </span>
                    <span>
                      {statusIconMap[4].icon}
                      <p>Não compareceu</p>
                    </span>
                    <span>
                      {statusIconMap[5].icon}
                      <p>Pré-venda gerada</p>
                    </span>
                    <span>
                      {statusIconMap[6].icon}
                      <p>Agendamento pago virtualmente</p>
                    </span>
                    <span>
                      {statusIconMap[7].icon}
                      <p>Agendamento pago em loja</p>
                    </span>
                  </div>
                </>
              )}
            </GridContainer>
          </div>
        </Container>
      </Main>
      <Footer />
      <HomeFilterModal
        isOpen={isHomeFilterModalOpen}
        onRequestClose={() => setIsHomeFilterModalOpen(false)}
        defaultData={{
          filterHorario,
          filterPeriodo,
          filterServico,
        }}
        onContinue={(periodo: string, horario: string, servico: number) => {
          setCurrentPage(1);
          setIsHomeFilterModalOpen(false);

          setFilterHorario(horario.length === 5 ? horario : "");
          setFilterPeriodo(periodo);
          setFilterServico(servico);
        }}
      />

      <SchedulingDetailsModal
        isOpen={isModalSchedulingDetailsOpen}
        onRequestClose={() => setIsModaSchedulingDetailsOpen(false)}
        idAgendamento={selectedIdAgendamento}
        onClickCancelScheduling={({ data, hora, idAgendamento }) => {
          setIdAgendamentoCancelar(idAgendamento);
          setIsModaSchedulingDetailsOpen(false);
          permitirCancelar({ data, hora, idAgendamento });
        }}
        onClickPreSaleScheduling={({ data, hora, idAgendamento }) => {
          let requestData = { idAgendamento, data, hora };
          actionOpenModalPreSale(requestData);
        }}
        onClickExecuteServiceScheduling={({ data, hora, idAgendamento }) => {
          let requestData = { idAgendamento, data, hora };
          actionOpenModalExecuteService(requestData);
        }}
      />

      <ModalRequestAcess
        isOpen={isModalRequestAcess}
        onRequestClose={() => setIsModalRequestAcess(false)}
        handleSetStore={(data) => {
          setStoreSelectedModalAccess(data);
          setIsModalRequestAcess(false);
          setToGiveAuthorization1(true);
        }}
      />

      <ModalGrantAccess
        isOpen={toGiveAuthorization1}
        onRequestClose={() => {
          setToGiveAuthorization1(false);
        }}
        confirm={() => {
          handleAuthorizationModal_1();
        }}
        lojaSelecionada={storeSelectedModalAccess}
      />

      <ModalVerifyAccess
        isOpen={toGiveAuthorization2}
        onRequestClose={() => {
          setToGiveAuthorization2(false);
        }}
        confirm={() => {
          handleCheckIfAccessWasGranted(storeSelectedModalAccess);
        }}
        lojaSelecionada={storeSelectedModalAccess}
      />

      <SimpleModal
        isOpen={isSucess}
        onRequestClose={() => setIsSucess(false)}
        confirm={() => {
          setIsSucess(false);
          window.location.reload();
        }}
        icon={IconSucess}
        title="Acesso concedido"
        message="Seu acesso foi liberado."
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="27px"
      />

      <SimpleModal
        isOpen={isError}
        onRequestClose={() => setIsError(false)}
        confirm={() => setIsError(false)}
        icon={IconError}
        title="Acesso negado"
        marginTitle="16px"
        message="Seu acesso a esta loja não foi liberado."
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="32px"
      />

      <SimpleModal
        isOpen={cancelamentoConfirmado}
        onRequestClose={() => setCancelamentoConfirmado(false)}
        confirm={() => {
          setOpenMotivoCancelamento(true);
          setCancelamentoConfirmado(false);
        }}
        icon={IconAlert}
        title="Atenção"
        message="Existe um agendamento ativo para o horário selecionado, o cliente foi informando do cancelamento antes de realização desta ação?"
        showCancel
        onCancel={() => {
          setCancelamentoConfirmado(false);
          setMessageError(
            "Para cancelar este agendamento, primeiro entre em contato com o cliente e informe o motivo do cancelamento dos serviços contratados."
          );
          setErrorAuthorization(true);
        }}
        marginTitle="16px"
        marginTitleBottom="7px"
        cancelButtonText="Não"
        continueButtonText="Sim"
        widthButtonClose="90px"
        widthButtonContinue="90px"
        marginButton="32px"
      />

      <SimpleModal
        isOpen={isModalLoadingOpen}
        title="Conferindo autorização..."
        message=""
        hideButtons
        icon={loadingImg}
        onRequestClose={() => {}}
        confirm={() => {}}
        imgStyle={{
          width: "100px",
          height: "100px",
        }}
        containerStyle={{
          height: "214px",
          display: "flex",
          justifyContent: "flex-end",
        }}
        marginTitle="8px"
      />

      <MotivoCancelamento
        isOpen={openMotivoCancelamento}
        onRequestClose={() => {
          setIdAgendamentoCancelar(0);
          setOpenMotivoCancelamento(false);
        }}
        onContinue={(event) => {
          cancelarAgendamento(
            idAgendamentoCancelar,
            event.motivoSelecionado,
            event.descricaoMotivo
          );
          setIdAgendamentoCancelar(0);
          setOpenMotivoCancelamento(false);
        }}
      />

      <SimpleModal
        isOpen={
          errorAuthorization ||
          errorAuthorizationPreSale ||
          errorAuthorizationExecuteService
        }
        onRequestClose={() => {
          setErrorAuthorization(false);
          setErrorAuthorizationPreSale(false);
          setErrorAuthorizationExecuteService(false);
        }}
        confirm={() => {
          setErrorAuthorization(false);
          setErrorAuthorizationPreSale(false);
          setErrorAuthorizationExecuteService(false);
        }}
        icon={IconError2}
        title={messageTitleError}
        marginTitle="15px"
        message={messageError}
        widthButtonContinue="110px"
        continueButtonText="Fechar"
        buttonsMarginTop="40.35px"
      />

      <SimpleModal
        isOpen={
          openModalCancelamentoConcluido ||
          openModalPreVendaSucesso ||
          openModalExecuteService
        }
        onRequestClose={() => {
          setOpenModalCancelamentoConcluido(false);
          setOpenModalPreVendaSucesso(false);
          setOpenModalExecuteService(false);
        }}
        confirm={() => {
          if (openModalPreVendaSucesso) loadAgendamentos();

          setOpenModalCancelamentoConcluido(false);
          setOpenModalPreVendaSucesso(false);
          setOpenModalExecuteService(false);
        }}
        icon={IconSucess}
        title="Sucesso"
        marginTitle="18px"
        message={messageSuccess}
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="33px"
      />

      <SimpleModal
        isOpen={hasFailed}
        onRequestClose={() => {
          setHasFailed(false);
          setMessageFailed("");
        }}
        confirm={() => {
          setHasFailed(false);
          setMessageFailed("");
        }}
        icon={IconError}
        title="Erro"
        marginTitle="16px"
        message={messageFailed}
        continueButtonText="Fechar"
        widthButtonContinue="110px"
        buttonsMarginTop="32px"
      />

      <SimpleModal
        isOpen={confirmacaoPreVenda || confirmacaoExecutarServico}
        onRequestClose={() => {}}
        loading={isLoadingInner}
        confirm={() => {
          if (confirmacaoPreVenda) {
            setOpenModalUserMaintainer(true);
            setConfirmacaoPreVenda(false);
          } else {
            setIsLoadingInner(true);
            confirmarExecucaoServico(selectedIdAgendamento);
          }
        }}
        icon={IconAlert}
        title="Atenção"
        message={
          "Tem certeza que deseja " +
          (confirmacaoPreVenda
            ? "realizar a pré-venda deste agendamento?"
            : "confirmar a execução do serviço?")
        }
        showCancel
        onCancel={() => {
          setConfirmacaoPreVenda(false);
          setConfirmacaoExecutarServico(false);
          setIsLoadingInner(false);
        }}
        marginTitle="16px"
        marginTitleBottom="7px"
        cancelButtonText="Não"
        continueButtonText="Sim"
        widthButtonClose="90px"
        widthButtonContinue="90px"
        marginButton="32px"
      />

      <FormUserAskMaintainer
        onChange={(e) => {
          setMatricula(e.value);
        }}
        title="Informação necessária"
        isOpen={openModalUserMaintainer}
        value={matricula}
        loading={isLoadingInner}
        showCancel={true}
        onRequestClose={() => {
          setMatricula(undefined);
          setIsLoadingInner(false);
          setOpenModalUserMaintainer(false);
        }}
        confirm={() => {
          setIsLoadingInner(true);
          inserirPreVenda(selectedIdAgendamento);
        }}
      />
    </>
  );
}
