import styled from "styled-components";

interface ContainerProps {
  colorTitle: string;
  colorMessage: string;
  sizeTitle: string;
  sizeMessage: string;
}

export const Container = styled.div<ContainerProps>`
  height: 55%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: ${({ sizeTitle }) => sizeTitle};
    color: ${({ colorTitle }) => colorTitle};
  }

  p {
    width: 201px;

    font-family: "Source Sans Pro", sans-serif;
    font-size: ${({ sizeMessage }) => sizeMessage};
    color: ${({ colorMessage }) => colorMessage};

    text-align: center;
  }
`;
