import { ChangeEventHandler } from "react";
import { Container, P, Button } from "./styles";
import { SiMicrosoftexcel } from "react-icons/si";

interface GridFilterProps {
  onClick: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClickAtive?: () => void;
  onClickExportCsv?: () => void;
  showInput?: boolean;
  placeholder?: string;
  activeOnly?: boolean;
  activeOnlyVisible?: boolean;
  exportVisible?: boolean;
  parceria?: boolean;
}

export function GridFilter({
  onClick,
  onChange,
  onClickAtive,
  activeOnlyVisible,  
  onClickExportCsv,
  exportVisible,
  showInput = false,
  placeholder = 'Busca',
  activeOnly,
  parceria,
}: GridFilterProps) {

  function handleClickOnlyActive() {    
    if(onClickAtive)
      onClickAtive();
  }

  function handleExportCsv() {  
    if(onClickExportCsv)  
      onClickExportCsv();
  }

  return (
    <>
      <Container>
        <span>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1ZM1 12H5C5.55 12 6 11.55 6 11C6 10.45 5.55 10 5 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM11 7H1C0.45 7 0 6.55 0 6C0 5.45 0.45 5 1 5H11C11.55 5 12 5.45 12 6C12 6.55 11.55 7 11 7Z"
              fill="#7B8FA5"
            />
          </svg>
          <p>Filtro</p>
        </span>

        {showInput && (
          <div>
            <input type="text" placeholder={placeholder} onChange={onChange} />
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9624 11.4716H12.7629L17.0595 15.7316C17.4749 16.1416 17.4749 16.8116 17.0595 17.2216C16.644 17.6316 15.9651 17.6316 15.5496 17.2216L11.2429 12.9716V12.1816L10.9693 11.9016C9.55068 13.1016 7.61521 13.7216 5.55814 13.3816C2.74108 12.9116 0.491476 10.5916 0.146943 7.79157C-0.379991 3.56157 3.22748 0.00157288 7.51388 0.521573C10.3512 0.861573 12.7021 3.08157 13.1784 5.86157C13.5229 7.89157 12.8947 9.80157 11.6787 11.2016L11.9624 11.4716ZM2.12294 6.97157C2.12294 9.46157 4.15974 11.4716 6.68294 11.4716C9.20615 11.4716 11.2429 9.46157 11.2429 6.97157C11.2429 4.48157 9.20615 2.47157 6.68294 2.47157C4.15974 2.47157 2.12294 4.48157 2.12294 6.97157Z"
                fill="#7B8FA5"
              />
            </svg>
          </div>
        )}
        <P 
          style={activeOnlyVisible ? { visibility: "visible" } : { visibility: "hidden" }}
          >Exibir somente ativas
        </P> 
        <input 
          type="checkbox" 
          onClick={() => handleClickOnlyActive()}
          key={"somenteAtivass"}
          name="somenteAtivass"
          checked={activeOnly || false}
          style={activeOnlyVisible ? { visibility: "visible" } : { visibility: "hidden" }}
        />
        <br/>
        <Button
          style={exportVisible ? { visibility: "visible" } : { visibility: "hidden" }}
          onClick={() =>{
            handleExportCsv();
          }}
          ><SiMicrosoftexcel />{' '} Exportar para Excel</Button>
      </Container>
    </>
  );
}
