import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  ContainerImg,
  ButtonMenu,
  ContainerMenu,
  ContainerDropdown,
} from "./styles";
import logoImg from "../../../images/logo-paguemenos.png";
import { usePartnerAuth } from "../../../hooks/partnerAuth";
import { partnerLoginService } from "services/partnerLoginService";
import { usePartnerContext } from "../../../contexts/partnerContextProvider";

export default function PartnerHeader() {
  const ref = useRef<HTMLDivElement>(null);
  const { partnerEmail, handlePartnerSignout } = usePartnerAuth();
  const { wipeOutPartnerData } = usePartnerContext();
  const [isMenuModalOpen, setIsMenuModalOpen] = useState(false);
  const [partnerId, setPartnerId] = useState(0);
  useOnClickOutside(ref, () => setIsMenuModalOpen(false));

  useEffect(() => {
    async function getId() {
      if (partnerEmail) {
        const id = await partnerLoginService.acquirePartnerId(partnerEmail);
        if (id == null || id < 0) {
          wipeOutPartnerData();
          handlePartnerSignout();
        }
        setPartnerId(id);
      } else {
        wipeOutPartnerData();
        handlePartnerSignout();
      }
    }

    getId();
  }, []);

  function useOnClickOutside(ref: any, handler: any) {
    useEffect(() => {
      const listener = (event: MouseEvent) => {
        if (!ref.current || ref.current.contains(event.target)) return;

        handler(event);
      };
      document.addEventListener("mousedown", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }, [ref, handler]);
  }

  // useEffect(() => {
  //     window.removeEventListener("click", detectClickOusideMenu);
  //     window.addEventListener("click", detectClickOusideMenu);

  //     return () => window.removeEventListener("click", detectClickOusideMenu);
  // }, [isMenuModalOpen]);

  // function detectClickOusideMenu(e: MouseEvent) {
  //     const menuModal = document.getElementById("menuModal");

  //     // @ts-ignore
  //     if (!menuModal?.contains(e.target)) {
  //         if (!menuModal) {
  //             return;
  //         }

  //         if (isMenuModalOpen) {
  //             setIsMenuModalOpen(false);
  //         }
  //     }
  // }

  // useEffect(() => {
  //     window.removeEventListener("click", detectClickOusideNotification);
  //     window.addEventListener("click", detectClickOusideNotification);

  //     return () => window.removeEventListener("click", detectClickOusideNotification);
  // }, [openNotification]);

  // function detectClickOusideNotification(e: MouseEvent) {
  //     const myNotification = document.getElementById("notification");

  //     // @ts-ignore
  //     if (!myNotification?.contains(e.target)) {
  //         if (!myNotification) {
  //             return;
  //         }

  //         if (openNotification) {
  //             setOpenNotification(false);
  //         }
  //     }
  // }

  // function handleOpenNotification() {
  //     setOpenNotification(prevState => !prevState);
  // }

  return (
    <Container>
      <header>
        <ContainerImg>
          <Link to="/portaladmin/partner/home">
            <img src={logoImg} alt="Logo PagueMenos" />
          </Link>
        </ContainerImg>

        <nav>
          <ContainerMenu>
            <ButtonMenu onClick={() => setIsMenuModalOpen(!isMenuModalOpen)}>
              <svg
                width="17"
                height="20"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5625 2.89062H15.1875C15.4688 2.89062 15.75 2.64453 15.75 2.32812V0.921875C15.75 0.640625 15.4688 0.359375 15.1875 0.359375H0.5625C0.246094 0.359375 0 0.640625 0 0.921875V2.32812C0 2.64453 0.246094 2.89062 0.5625 2.89062ZM0.5625 8.51562H15.1875C15.4688 8.51562 15.75 8.26953 15.75 7.95312V6.54688C15.75 6.26562 15.4688 5.98438 15.1875 5.98438H0.5625C0.246094 5.98438 0 6.26562 0 6.54688V7.95312C0 8.26953 0.246094 8.51562 0.5625 8.51562ZM0.5625 14.1406H15.1875C15.4688 14.1406 15.75 13.8945 15.75 13.5781V12.1719C15.75 11.8906 15.4688 11.6094 15.1875 11.6094H0.5625C0.246094 11.6094 0 11.8906 0 12.1719V13.5781C0 13.8945 0.246094 14.1406 0.5625 14.1406Z"
                  fill="white"
                />
              </svg>
            </ButtonMenu>
            {isMenuModalOpen && (
              <div ref={ref}>
                <ContainerDropdown id="menuModal">
                  <div>
                    <p>
                      <Link
                        to={`/portaladmin/partner/home`}
                        onClick={() => setIsMenuModalOpen(false)}
                      >
                        INÍCIO
                      </Link>
                    </p>

                    <p>
                      <Link
                        to={`/portaladmin/partner/scheduleControl?pl=${partnerId}|${0}`}
                        onClick={() => setIsMenuModalOpen(false)}
                      >
                        CONFIGURAR AGENDA
                      </Link>
                    </p>

                    <p>
                      <Link
                        to={`/portaladmin/partner/scheduleLockRelease`}
                        onClick={() => setIsMenuModalOpen(false)}
                      >
                        BLOQUEIO E LIBERAÇÃO DE AGENDA
                      </Link>
                    </p>

                    <p>
                      <Link
                        to={`/portaladmin/partner/manage-services?pl=${partnerId}|${0}`}
                        onClick={() => setIsMenuModalOpen(false)}
                      >
                        GERENCIAR SERVIÇOS
                      </Link>
                    </p>

                    <p>
                      <a
                        href="#"
                        onClick={() => {
                          wipeOutPartnerData();
                          handlePartnerSignout();
                        }}
                      >
                        SAIR
                      </a>
                    </p>
                  </div>
                </ContainerDropdown>
              </div>
            )}
          </ContainerMenu>
        </nav>
      </header>
    </Container>
  );
}
