import "./imageInputStyles.scss";
import { Button } from "primereact/button";
import ImageUploading from "react-images-uploading";
import { useState } from "react";

export default function ImageInput({
  multiple = false,
  maxFileSize = 1000000,
  maxFiles = 1,
  maxWidth,
  maxHeight,
  acceptedFormats = ["jpg", "png"],
  labelText,
  image,
  setImage,
  errorMessage = "",
  existentImage,
  setHasChanges,
  removableImage = null,
  readOnly = false,
  isEditing = false,
}) {
  const [changedContent, setChangedContent] = useState(false);

  const onChange = (imageList) => {
    setImage(imageList);
    setHasChanges(true);
    setChangedContent(true);
  };

  return (
    <div className="wrapper">
      <label><strong>{labelText}</strong></label>
      <ImageUploading
        multiple={multiple}
        value={image}
        onChange={onChange}
        maxNumber={maxFiles}
        dataURLKey="data_url"
        acceptType={acceptedFormats}
        maxFileSize={maxFileSize}
        resolutionType={"less"}
        resolutionWidth={maxWidth}
        resolutionHeight={maxHeight}
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <>
            <div {...dragProps} className="imageInput">
              {!imageList.length && !existentImage && (
                <>
                  <Button
                    type="button"
                    className="imageInput__uploadButton p-button-outlined"
                    aria-label="Selcionar arquivo..."
                    style={isDragging ? { fontWeight: "bold" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Selecionar arquivo...&nbsp;&nbsp;
                    <svg
                      style={{ width: "24px", height: "24px" }}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M19.35,10.04C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.04C2.34,8.36 0,10.91 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.04M19,18H6A4,4 0 0,1 2,14C2,11.95 3.53,10.24 5.56,10.03L6.63,9.92L7.13,8.97C8.08,7.14 9.94,6 12,6C14.62,6 16.88,7.86 17.39,10.43L17.69,11.93L19.22,12.04C20.78,12.14 22,13.45 22,15A3,3 0 0,1 19,18M8,13H10.55V16H13.45V13H16L12,9L8,13Z"
                      />
                    </svg>
                  </Button>

                  <span className="imageInput__uploadText">
                    {isDragging
                      ? "Solte a imagem aqui"
                      : "Clique ou arraste a imagem aqui"}
                  </span>

                  <span className="imageInput__sizesInfoText">
                    (Tamanho recomendado: {maxWidth}x{maxHeight}px extensões
                    PNG ou JPG de até 1MB)
                  </span>
                </>
              )}

              <div className="imageInput__selectedImagePreview">
                {existentImage && !changedContent && (
                  <div className="imageInput__selectedImagePreview__item">
                    <img
                      src={existentImage}
                      alt="Imagem selecionada"
                      width="120"
                    />
                    <div className="imageInput__selectedImagePreview__item__buttons">
                      <span onClick={onImageUpload} className="changeBtn">
                        {!readOnly && (
                          <>
                            <svg viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M13.09 20C13.21 20.72 13.46 21.39 13.81 22H6C4.89 22 4 21.11 4 20V4C4 2.9 4.89 2 6 2H14L20 8V13.09C19.67 13.04 19.34 13 19 13C18.66 13 18.33 13.04 18 13.09V9H13V4H6V20H13.09M23 17L20 14.5V16H16V18H20V19.5L23 17M18 18.5L15 21L18 23.5V22H22V20H18V18.5Z"
                              />
                            </svg>
                            &nbsp;&nbsp; Trocar
                          </>
                        )}
                      </span>
                      {removableImage !== null || isEditing && (
                        <span
                          onClick={() => {
                            onImageRemove();
                            setChangedContent(true);
                          }}
                          className="removeBtn"
                        >
                          <svg viewBox="0 0 24 24">
                            <path fill="currentColor"
                              d="M7 19L12 14L13.88 15.88C13.33 16.79 13 17.86 13 19H7M10 10.5C10 9.67 9.33 9 8.5 9S7 9.67 7 10.5 7.67 12 8.5 12 10 11.33 10 10.5M13.09 20H6V4H13V9H18V13.09C18.33 13.04 18.66 13 19 13C19.34 13 19.67 13.04 20 13.09V8L14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H13.81C13.46 21.39 13.21 20.72 13.09 20M21.12 15.46L19 17.59L16.88 15.47L15.47 16.88L17.59 19L15.47 21.12L16.88 22.54L19 20.41L21.12 22.54L22.54 21.12L20.41 19L22.54 16.88L21.12 15.46Z" />
                          </svg>
                          &nbsp;&nbsp; Remover
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {imageList.map((image, index) => (
                  <div
                    key={index}
                    className="imageInput__selectedImagePreview__item"
                  >
                    <img
                      src={image["data_url"] || existentImage}
                      alt="Imagem selecionada"
                      width="120"
                    />
                    <div className="imageInput__selectedImagePreview__item__buttons">
                      <span
                        onClick={() => onImageUpdate(index)}
                        className="changeBtn"
                      >
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13.09 20C13.21 20.72 13.46 21.39 13.81 22H6C4.89 22 4 21.11 4 20V4C4 2.9 4.89 2 6 2H14L20 8V13.09C19.67 13.04 19.34 13 19 13C18.66 13 18.33 13.04 18 13.09V9H13V4H6V20H13.09M23 17L20 14.5V16H16V18H20V19.5L23 17M18 18.5L15 21L18 23.5V22H22V20H18V18.5Z"
                          />
                        </svg>
                        &nbsp;&nbsp; Trocar
                      </span>

                      <span
                        onClick={() => {
                          onImageRemove(index);
                          setChangedContent(false);
                        }}
                        className="removeBtn"
                      >
                        <svg viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M7 19L12 14L13.88 15.88C13.33 16.79 13 17.86 13 19H7M10 10.5C10 9.67 9.33 9 8.5 9S7 9.67 7 10.5 7.67 12 8.5 12 10 11.33 10 10.5M13.09 20H6V4H13V9H18V13.09C18.33 13.04 18.66 13 19 13C19.34 13 19.67 13.04 20 13.09V8L14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H13.81C13.46 21.39 13.21 20.72 13.09 20M21.12 15.46L19 17.59L16.88 15.47L15.47 16.88L17.59 19L15.47 21.12L16.88 22.54L19 20.41L21.12 22.54L22.54 21.12L20.41 19L22.54 16.88L21.12 15.46Z"
                          />
                        </svg>
                        &nbsp;&nbsp; Remover
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {(errors || errorMessage !== "") && (
              <div className="errorsWrapper">
                {errors && errors.maxNumber && (
                  <small className="p-error block">
                    Quantidade de imagens permitida é 1.
                  </small>
                )}
                {errors && errors.acceptType && (
                  <small className="p-error block">
                    Formato de arquivo inválido.
                  </small>
                )}
                {errors && errors.maxFileSize && (
                  <small className="p-error block">
                    Tamanho de arquivo não suportado.
                  </small>
                )}
                {errors && errors.resolution && (
                  <small className="p-error block">
                    Dimensões de imagem não suportadas.
                  </small>
                )}
                {errorMessage !== "" && !existentImage && (
                  <small className="p-error block">{errorMessage}</small>
                )}
              </div>
            )}
          </>
        )}
      </ImageUploading>
    </div>
  );
}
